import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../Navbar';
import Footer from '../Footer';
import Alaves from '../Team-Kits-Images/Alaves.png'
import Athleti from '../Team-Kits-Images/Athleti.png'
import Athletic from '../Team-Kits-Images/Athletic.png'
import Barcelona from '../Team-Kits-Images/Barcelona.png'
import Betis from '../Team-Kits-Images/Betis.png'
import CeltaVigo from '../Team-Kits-Images/CeltaVigo.png'
import Espanyol from '../Team-Kits-Images/Espanyol.png'
import Getafe from '../Team-Kits-Images/Getafe.png'
import Girona from '../Team-Kits-Images/Girona.png'
import LasPalmas from '../Team-Kits-Images/LasPalmas.png'
import Leganes from '../Team-Kits-Images/Leganes.png'
import Madrid from '../Team-Kits-Images/Madrid.png'
import Mallorca from '../Team-Kits-Images/Mallorca.png'
import Osasuna from '../Team-Kits-Images/Osasuna.png'
import Rayo from '../Team-Kits-Images/Rayo.png'
import Sevilla from '../Team-Kits-Images/Sevilla.png'
import Sociedad from '../Team-Kits-Images/Sociedad.png'
import Valencia from '../Team-Kits-Images/Valencia.png'
import Valladolid from '../Team-Kits-Images/Valladolid.png'
import Villareal from '../Team-Kits-Images/Villareal.png'
import { Spinner } from 'react-bootstrap';
import HomeTeamFixtures from './Home.Team.Fixtures';
import { useTranslation } from 'react-i18next';


function OsasunaStats() {
  const [teamStats, setTeamStats] = useState(null);
  const [nextFixture, setNextFixture] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate()
  const { t } = useTranslation();

  useEffect(() => {
    const fetchTeamStats = async () => {
      try {
        const response = await fetch('https://iga-sport.azurewebsites.net/api/league-teams-stats/140/season/2024', {
          headers: {
            'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch team stats');
        }
        const data = await response.json();
        const Data = data.find(team => team.id === 727);
        if (Data) {
          setTeamStats(Data);
        } else {
          console.log(' data not found');
        }
      } catch (error) {
        console.error('Error fetching team stats:', error);
      }
    };

    const fetchNextFixture = async () => {
      try {
        const response = await fetch('https://iga-sport.azurewebsites.net/api/league/140/fixtures/season/2024', {
            headers: {
                'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
              }
            })
        if (!response.ok) {
          throw new Error('Failed to fetch fixtures');
        }
        const fixturesData = await response.json();
        console.log('All fixtures:', fixturesData);

        const Fixtures = fixturesData.filter(fixture => {
          return fixture.teams.home.id === 727 || fixture.teams.away.id === 727;
        });
        console.log('Fixtures:', Fixtures);

        // Sort the fixtures by date in ascending order
        Fixtures.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));

        // Find the first upcoming fixture
        const upcomingFixture = Fixtures.find(fixture => {
          const fixtureDate = new Date(fixture.fixture.date); // Convert fixture date string to Date object
          const currentDate = new Date(); // Get current date
          return fixtureDate > currentDate; // Return true if fixture date is in the future
        });

        setNextFixture(upcomingFixture);
      } catch (error) {
        console.error('Error fetching fixtures:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTeamStats();
    fetchNextFixture();
  }, []);

  const convertToFormattedDateTime = (dateString) => {
    const options = { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', hour12: true };
    const date = new Date(dateString);
    return date.toLocaleString('en-US', options);
  };

  const form = teamStats?.form || '';

  // Convert the form string into an array of characters
  const formArray = form.split('');

  // Get the last 5 matches from the form array
  const lastFiveMatches = formArray.slice(-5);

  const getClassForMatch = (result) => {
    switch (result) {
      case 'W':
        return 'success'; // Corrected class name to Bootstrap's 'success'
      case 'D':
        return 'warning'; // Corrected class name to Bootstrap's 'warning'
      case 'L':
        return 'danger'; // Corrected class name to Bootstrap's 'danger'
      default:
        return '';
    }
  };

const handleGoBack = () => {
    navigate(-1); // Navigate back one step in the history stack
  };
  const teamLogos = {
    'Getafe': Getafe,
    'Athletic Club': Athletic,
    'Real Betis': Betis,
    'Girona': Girona,
    'Mallorca': Mallorca,
    'Real Madrid': Madrid,
    'Las Palmas': LasPalmas,
    'Sevilla': Sevilla,
    'Villarreal': Villareal,
    'Atletico Madrid': Athleti,
    'Valencia': Valencia,
    'Barcelona': Barcelona,
    'Real Sociedad': Sociedad,
    'Rayo Vallecano': Rayo,
    'Celta Vigo': CeltaVigo,
    'Alaves': Alaves,
    'Osasuna': Osasuna,
    'Leganes': Leganes,
    'Espanyol': Espanyol,
    'Valladolid': Valladolid,
    // Add any other teams here...
};

const teamInitials = {
   'Getafe': 'GET',
   'Athletic Club': 'ATH',
   'Real Betis': 'BET',
   'Girona': 'GIR',
   'Mallorca': 'MAL',
   'Real Madrid': 'RM',
   'Las Palmas': 'LAS',
   'Sevilla': 'SEV',
   'Villarreal': 'VIL',
   'Atletico Madrid': 'ATM',
   'Valencia': 'VAL',
   'Barcelona': 'BAR',
   'Real Sociedad': 'SOC',
   'Rayo Vallecano': 'RAY',
   'Celta Vigo': 'CEL',
   'Alaves': 'ALA',
   'Osasuna': 'OSA',
   'Leganes': 'LEG',
   'Espanyol': 'ESP',
   'Valladolid': 'VAL',
   // Add any other teams here...
};

  
  return (
    <div>
    <div className='App-headerV8'>
    <Navbar />
  {teamStats ? (
      <section className="bg-transparent py-3 py-md-5 py-xl-8">
      <div className="container">
          <div className="row justify-content-md-center">
              <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
                  <h2 className="mb-4 display-5 text-center  text-light">{teamStats.name}  {t('team_stats')}</h2>
                  <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
              </div>
          </div>
      </div>

      <div className="container ">
          <div className="row gy-4 gy-lg-0 ">
              <div className="col-12 col-lg-4 col-xl-3 ">
                  <div className="row gy-4 ">
                      <div className="col-12 bg-transparent">
                          <div className="card widget-card border-light shadow-sm bg-transparent">
                              <h6 className="card-header text-bg-danger text-center">{teamStats.name} {t('home_kit')}</h6>
                              <div className="card-body">
                                  <div className="text-center mb-3">
                                      <img src={Osasuna} style={{ maxWidth: '100px', maxHeight: '100px' }} />
                                  </div>
                           
                              </div>
                          </div>
                      </div>
                      <div className="col-12 ">
                          <div className="card widget-card border-light shadow-sm bg-transparent text-light">
                              <h6 className="card-header text-bg-danger text-center">{t('club_info')}</h6>
                              <div className="card-body ">
                              <h5 className="text-center mb-1">{t('stadium')}</h5>
                                  <p className="text-center text- mb-4">El Sadar Stadium</p>
                                 
                                  <h5 className="text-center mb-1">{t('year_founded')}</h5>
                                  <p className="text-center text- mb-4">1920</p>

                              </div>
                          </div>
                      </div>
                      <div className="col-12">
                  <div className="card widget-card border-light shadow-sm bg-transparent text-light">
                    <h6 className="card-header text-bg-danger text-center">{teamStats.name} {t('next_la_liga_fixture')}</h6>
                    <div className="card-body">
                      {nextFixture ? (
                        <div>
                        <div className='d-flex text-center'>
                          <b className="text-center mb-1 col-5">
                          {nextFixture.teams.home.name in teamLogos ? (
                                  <>
                                    <img src={teamLogos[nextFixture.teams.home.name]} alt={nextFixture.teams.home.name} style={{ width: '75px', height: 'auto' }} />
                                    <br /> <span>{teamInitials[nextFixture.teams.home.name]}</span>
                                  </>
                                ) : (
                                  <span>{nextFixture.teams.home.name}</span>
                                )}
                            </b> 
                          <h5 className="text-center mb-1 col-2 padtopstats">VS</h5>
                          <b className="text-center mb-1 col-5">
                          {nextFixture.teams.away.name in teamLogos ? (
                                  <>
                                    <img src={teamLogos[nextFixture.teams.away.name]} alt={nextFixture.teams.away.name} style={{ width: '75px', height: 'auto' }} />
                                    <br /> <span>{teamInitials[nextFixture.teams.away.name]}</span>
                                  </>
                                ) : (
                                  <span>{nextFixture.teams.away.name}</span>
                                )}
                            </b>
                        </div>
                                                      <p className='text-center text- mb-4'>{convertToFormattedDateTime(nextFixture.fixture.date)}</p>

                 
                                                      </div>

                      ) : (
                        <p className='text-center text-'>{t('no_upcoming_fixture')}</p>
                        
                      )}
                     <h5 className="text-center mb-1">{t('last_5_la_liga')}</h5>
<p className="text-center text- mb-1">
{lastFiveMatches.map((result, index) => (
<span key={index}>
  <span className={`badge bg-${getClassForMatch(result)} me-1`}>{result}</span>
</span>
))}
</p>
                    </div>
                  </div>
                </div>
                  </div>
              </div>
              {/* gap */}
              <div className="col-12 col-lg-8 col-xl-9">
                  <div className="card widget-card border-light shadow-sm bg-transparent text-light">
                      <div className="card-body p-4 ">
                          <ul className="nav nav-tabs " id="profileTab" role="tablist">
                              <li className="nav-item " role="presentation">
                                  <button className="nav-link active " id="overview-tab" data-bs-toggle="tab" data-bs-target="#overview-tab-pane" type="button" role="tab" aria-controls="overview-tab-pane" aria-selected="true">{t('overview')}</button>
                              </li>
                              <li className="nav-item text-danger" role="presentation">
                                  <button className="nav-link " id="profile-tab " data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">{t('scoring_stats')}</button>
                              </li>
                              <li className="nav-item" role="presentation">
                                  <button className="nav-link " id="fun-tab" data-bs-toggle="tab" data-bs-target="#fun-tab-pane" type="button" role="tab" aria-controls="fun-tab-pane" aria-selected="false">{t('fun_stats')}</button>
                              </li>
                          </ul>
                          <div className="tab-content pt-4 " id="profileTabContent">
                              <div className="tab-pane fade show active" id="overview-tab-pane" role="tabpanel" aria-labelledby="overview-tab" tabIndex="0">
                                  {/* Content for Overview tab */}
                                  <h5 className="mb-3">{t('la_liga_games')}</h5>
                                  <div className="row g-0 ">
                                  <div className="col-6 col-md-4 bg-light border-bottom border-white border-3 bg-transparent">
                                          <div className="p-2">{t('total_games')}</div>
                                          </div>
                                          <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3 bg-transparent">
                                          <div className="p-2">{teamStats.fixtures && teamStats.fixtures.played && teamStats.fixtures.played.total}</div>
                                          </div> 
                                          </div>
                                          <div className="row g-0">
                                  <div className="col-6 col-md-4 bg-light border-bottom border-white border-3 bg-transparent">
                                          <div className="p-2">{t('games_won')}</div>
                                          </div>
                                          <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3 bg-transparent">
                                          <div className="p-2">{teamStats.fixtures && teamStats.fixtures.wins && teamStats.fixtures.wins.total}</div>
                                          </div> 
                                          </div>
                                          <div className="row g-0">
                                  <div className="col-6 col-md-4 bg-light border-bottom border-white border-3 bg-transparent">
                                          <div className="p-2">{t('games_drawn')}</div>
                                          </div>
                                          <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3 bg-transparent">
                                          <div className="p-2">{teamStats.fixtures && teamStats.fixtures.draws && teamStats.fixtures.draws.total}</div>
                                          </div> 
                                          </div>
                                          <div className="row g-0">
                                  <div className="col-6 col-md-4 bg-light border-bottom border-white border-3 bg-transparent">
                                          <div className="p-2">{t('games_lost')}</div>
                                          </div>
                                          <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3 bg-transparent">
                                          <div className="p-2">{teamStats.fixtures && teamStats.fixtures.loses && teamStats.fixtures.loses.total}</div>
                                          </div> 
                                          </div>
                                          <div className="row g-0">
                                  <div className="col-6 col-md-4 bg-light border-bottom border-white border-3 bg-transparent">
                                          <div className="p-2">{t('total_away_wins')}</div>
                                          </div>
                                          <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3 bg-transparent">
                                          <div className="p-2">{teamStats.fixtures && teamStats.fixtures.wins && teamStats.fixtures.wins.away} </div>
                                          </div> 
                                  </div>
                                  <div className="row g-0">
                                  <div className="col-6 col-md-4 bg-light border-bottom border-white border-3 bg-transparent">
                                          <div className="p-2">{t('total_home_wins')}</div>
                                          </div>
                                          <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3 bg-transparent">
                                          <div className="p-2">{teamStats.fixtures && teamStats.fixtures.wins && teamStats.fixtures.wins.home} </div>
                                          </div> 
                                  </div>
                                  <h5 className="mb-3">{t('notable_results')}</h5>
                                  <div className="row g-0">
                                  <div className="col-6 col-md-4 bg-light border-bottom border-white border-3 bg-transparent">
                                          <div className="p-2">{t('biggest_home_win')}</div>
                                          </div>
                                          <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3 bg-transparent">
                                          <div className="p-2">{teamStats.biggest && teamStats.biggest.wins && teamStats.biggest.wins.home}</div>
                                          </div> 
                                  </div>
                                  <div className="row g-0">
                                  <div className="col-6 col-md-4 bg-light border-bottom border-white border-3 bg-transparent">
                                          <div className="p-2">{t('biggest_away_win')}</div>
                                          </div>
                                          <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3 bg-transparent">
                                          <div className="p-2">{teamStats.biggest && teamStats.biggest.wins && teamStats.biggest.wins.away}</div>
                                          </div> 
                                  </div>
                                  <div className="row g-0">
                                  <div className="col-6 col-md-4 bg-light border-bottom border-white border-3 bg-transparent">
                                          <div className="p-2">{t('biggest_home_loss')}</div>
                                          </div>
                                          <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3 bg-transparent">
                                          <div className="p-2">{teamStats.biggest && teamStats.biggest.loses && teamStats.biggest.loses.home}</div>
                                          </div> 
                                  </div>
                                  <div className="row g-0">
                                  <div className="col-6 col-md-4 bg-light border-bottom border-white border-3 bg-transparent">
                                          <div className="p-2">{t('biggest_away_loss')}</div>
                                          </div>
                                          <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3 bg-transparent">
                                          <div className="p-2">{teamStats.biggest && teamStats.biggest.loses && teamStats.biggest.loses.away}</div>
                                          </div> 
                                  </div>
             
  
                              </div>
                              <div className="tab-pane fade" id="fun-tab-pane" role="tabpanel" aria-labelledby="fun-tab" tabIndex="2">
{/* Content for Other Stats tab */}
<h5 className="mb-3">{t('interesting_statistics')}</h5>

<div className="row g-0 bg-transparent">
<div className="col-6 col-md-4 bg-light border-bottom border-white border-3 bg-transparent">
  <div className="p-2">{t('total_yellow_cards')}</div>
</div>
<div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3 bg-transparent">
  <div className="p-2 bg-transparent">
    {teamStats.cards && teamStats.cards.yellow && Object.values(teamStats.cards.yellow).reduce((acc, cur) => acc + cur.total, 0)}
  </div>
</div> 
</div>
<div className="row g-0 bg-transparent">
<div className="col-6 col-md-4 bg-light border-bottom border-white border-3 bg-transparent">
  <div className="p-2">{t('total_red_cards')}</div>
</div>
<div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3 bg-transparent">
  <div className="p-2">
    {teamStats.cards && teamStats.cards.red && Object.values(teamStats.cards.red).reduce((acc, cur) => acc + cur.total, 0)}
  </div>
</div> 
</div>
<div className="row g-0"> 
<div className="col-6 col-md-4 bg-light border-bottom border-white border-3 bg-transparent">
  <div className="p-2">{t('total_penalties_scored')}</div>
</div>
<div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3 bg-transparent">
  <div className="p-2">
  {teamStats.penalty && teamStats.penalty.scored && teamStats.penalty.scored.total}
  </div>
</div> 
</div>
<div className="row g-0">
<div className="col-6 col-md-4 bg-light border-bottom border-white border-3 bg-transparent">
  <div className="p-2">{t('total_penalties_missed')}</div>
</div>
<div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3 bg-transparent">
  <div className="p-2">
  {teamStats.penalty && teamStats.penalty.missed && teamStats.penalty.missed.total}

  </div>
</div> 
</div>
<div className="row g-0">
<div className="col-6 col-md-4 bg-light border-bottom border-white border-3 bg-transparent">
  <div className="p-2">{t('penalty_goal_rate')}</div>
</div>
<div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3 bg-transparent">
  <div className="p-2">
  {teamStats.penalty && teamStats.penalty.scored && teamStats.penalty.scored.percentage}
  </div>
</div> 
</div>
</div>



                              <div className="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="1">
                                  {/* Content for Other Stats tab */}
                                  <h5 className="mb-3">{t('scoring_statistics')}</h5>
                                  <div className="row g-0 bg-transparent">
                                  <div className="col-6 col-md-4 bg-light border-bottom border-white border-3 bg-transparent">
                                          <div className="p-2">{t('total_goals_scored')}</div>
                                          </div>
                                          <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3 bg-transparent">
                                          <div className="p-2">{teamStats.goals && teamStats.goals.for && teamStats.goals.for.total.total}</div>
                                          </div> 
                                  </div>
                                  <div className="row g-0">
                                  <div className="col-6 col-md-4 bg-light border-bottom border-white border-3 bg-transparent">
                                          <div className="p-2">{t('total_goals_scored_at_home')}</div>
                                          </div>
                                          <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3 bg-transparent">
                                          <div className="p-2">{teamStats.goals && teamStats.goals.for && teamStats.goals.for.total.home}</div>
                                          </div> 
                                  </div>
                                  <div className="row g-0 bg-transparent">
                                  <div className="col-6 col-md-4 bg-light border-bottom border-white border-3 bg-transparent">
                                          <div className="p-2">{t('total_goals_scored_away')}</div>
                                          </div>
                                          <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3 bg-transparent">
                                          <div className="p-2">{teamStats.goals && teamStats.goals.for && teamStats.goals.for.total.away}</div>
                                          </div> 
                                  </div>
             
                                  
                                  <div className="row g-0">
                                  <div className="col-6 col-md-4 bg-light border-bottom border-white border-3 bg-transparent">
                                          <div className="p-2">{t('average_goals_scored_per_game')}</div>
                                          </div>
                                          <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3 bg-transparent">
                                          <div className="p-2">{teamStats.goals && teamStats.goals.for && teamStats.goals.for.average.total}</div>
                                          </div> 
                                  </div>
                                  <div className="row g-0">
                                  <div className="col-6 col-md-4 bg-light border-bottom border-white border-3 bg-transparent">
                                          <div className="p-2">{t('average_goals_conceded_per_game')}</div>
                                          </div>
                                          <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3 bg-transparent">
                                          <div className="p-2">{teamStats.goals && teamStats.goals.against && teamStats.goals.against.average.total}</div>
                                          </div> 
                                  </div>
                                  <div className="row g-0">
                                  <div className="col-6 col-md-4 bg-light border-bottom border-white border-3 bg-transparent">
                                          <div className="p-2">{t('total_clean_sheets')}</div>
                                          </div>
                                          <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3 bg-transparent">
                                          <div className="p-2">{teamStats.clean_sheet && teamStats.clean_sheet.total}</div>
                                          </div> 
                                  </div>
                                  <div className="row g-0">
                                  <div className="col-6 col-md-4 bg-light border-bottom border-white border-3 bg-transparent">
                                          <div className="p-2">{t('total_games_without_scoring')}</div>
                                          </div>
                                          <div className="col-6 col-md-8 bg-light border-start border-bottom border-white border-3 bg-transparent">
                                          <div className="p-2">{teamStats.failed_to_score && teamStats.failed_to_score.total}</div>
                                          </div> 
                                  </div>
                                
                              </div>
                              <i>{t('note_la_liga_statistics')}</i>

                          </div>
                      </div>
                  </div>
              </div>
              {/*End of gap */}
          </div>
      </div>
  </section>
  ) : (
    <div>
    <h2 className='text-center text-light'>{t('loading_stats')} </h2>

    <h2 className='text-center'><Spinner /></h2>
    </div>
  )}
  <Footer />
  </div>
</div>
  
  );
}
// {teamStats.fixtures && teamStats.fixtures.wins && teamStats.fixtures.wins.total}
export default OsasunaStats;
