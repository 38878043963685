import React, { useState } from 'react';
import { auth } from './../firebase'; // Ensure this is the path to your Firebase configuration
import { signInWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Footer from '../Footer';
import Navbar from '../Navbar';
import Image123 from '../LogoWhite3.png';
import Image1234 from '../LogoWhite4.png';

import '../App.css';
import { useTranslation } from 'react-i18next';
import { getDatabase, ref, get, update } from 'firebase/database';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

// Function to sanitize the email for Firebase Realtime Database paths
const sanitizeEmail = (email) => {
  return email.replace(/[.#$[\]]/g, '_'); // Replace problematic characters with underscores
};

const LogIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [resend, setResend] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const checkIfUserBlocked = async (email) => {
    try {
      const db = getDatabase();
      const sanitizedEmail = sanitizeEmail(email); // Use the same sanitization as in blockUser
      const blockedEmailRef = ref(db, `blockedEmails/${sanitizedEmail}`);
      const snapshot = await get(blockedEmailRef);
  
      if (snapshot.exists() && snapshot.val().blocked) {
        // Email is blocked
        return true;
      } else {
        // Email is not blocked
        return false;
      }
    } catch (error) {
      console.error("Error checking block status:", error);
      return false;
    }
  };

  const loginUser = async (email, password) => {
    try {
      // Check if user is blocked
      const isBlocked = await checkIfUserBlocked(email);
      if (isBlocked) {
        console.log("User is blocked and cannot log in.");
        setError('Your account is currently blocked. Please try again later.');
        return;
      }
  
      // Proceed with authentication if not blocked
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      if (!user.emailVerified) {
        setError('Please verify your email before logging in.');
        setResend('Resend verification email');
        return;
      }
  
      const db = getDatabase();
      const usersRef = ref(db, 'users');
      const snapshot = await get(usersRef);
  
      if (snapshot.exists()) {
        const usersData = snapshot.val();
        const currentUser = usersData[user.uid];
  
        if (currentUser && currentUser.blockedUntil && Date.now() < currentUser.blockedUntil) {
          setError('Your account is currently blocked. Please try again later.');
          return;
        }
      }
  
      // Navigate to the appropriate route on successful login
      const from = location.state?.from?.pathname || '/';
      navigate(from, { replace: true });
    } catch (error) {
      console.error("Error logging in:", error);
      setError(t('invalid_login_credentials'));
    }
  };
  const signIn = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
  
    try {
      // Check if the user is blocked before attempting to sign in
      const isBlocked = await checkIfUserBlocked(email);
      if (isBlocked) {
        setError('Your account is currently blocked. Please try again later.');
        setLoading(false);
        return;
      }
  
      // Proceed with authentication if not blocked
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      // Check if the email is verified
      if (!user.emailVerified) {
        setError(t('verify_email_before_login'));
        setResend('Resend verification email');
        setLoading(false);
        return;
      }
  
      // Update lastLoginDate and userStatus to 'active' in Firebase
      const db = getDatabase();
      const userRef = ref(db, `users/${user.uid}`);
      await update(userRef, {
        lastLoginDate: new Date().toISOString(),
        userStatus: 'active',  // Set the status to 'active' after successful login
      });
  
      // Redirect to the appropriate route on successful login
      const from = location.state?.from?.pathname || '/';
      navigate(from, { replace: true });
    } catch (error) {
      setError(t('invalid_login_credentials'));
      console.error('Error signing in:', error);
    } finally {
      setLoading(false);
    }
  };
  

  const requestVerificationEmail = async () => {
    try {
      await sendEmailVerification(auth.currentUser);
      setError(t('verification_email_sent_message'));
    } catch (error) {
      setError('Failed to send verification email.');
      console.error('Error sending verification email:', error);
    }
  };

  return (
    <div>
      <div className='App-headerV8'>
      <Navbar />
      <div >
        <section className="py-3 py-md-5 py-xl-8 " style={{ minHeight: '100vh' }}>
          <div className="container" >
            <div className="row gy-4 align-items-center" >
              <div className="col-12 col-md-6 col-xl-7">
                <div className="d-flex justify-content-center text-light">
                  <div className="col-12 col-xl-9">
                    <img className="img-fluid rounded mb-4" loading="lazy" src={Image1234} width="285" height="80" alt="login Logo" />
                    <hr className="border-primary-subtle mb-4" />
                    <h1 className="h1 mb-4"style={{ fontFamily: 'Lato, sans-serif' }}>{t('welcome_message')}
                    </h1>
                    <p className="lead mb-5"style={{ fontFamily: 'Lato, sans-serif' }}>{t('test_your_sports_knowledge')}
                    </p>
                    <div className="text-endx">
                      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" className="bi bi-grip-horizontal" viewBox="0 0 16 16">
                        <path d="M2 8a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm3 3a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm0-3a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-5 bg-transparent border-0" style={{ border: '1px solid rgba(255, 255, 255, 0.5)', }}>
    <div className="card border-0 rounded-4 text-light" style={{ backgroundColor: 'transparent', color: 'white', border: '1px solid rgba(255, 255, 255, 0.5)' }}>
        <div className="card-body p-3 p-md-4 p-xl-5 ">
            <div className="row">
                <div className="col-12">
                    <div className="mb-4">
                        <h2 className="h3" style={{ color: 'white' }}>{t('login_title')}</h2>
                        <h3 className="fs-6 fw-normal  m-0" style={{ color: 'white' }}>
                            {t('login_description')}
                        </h3>
                    </div>
                </div>
            </div>
            <form onSubmit={signIn}>
                <div className="row gy-3 overflow-hidden" >
                <div className="col-12">
  <div className="form-floating mb-3">
    <input
      type="email"
      className="form-control text- border-"
      name="email"
      id="email"
      placeholder="Email"
      value={email}
      onChange={(e) => setEmail(e.target.value)}
      required
      style={{
        backgroundColor: 'transparent', 
        color: 'white', 
        border: '1px solid rgba(255, 255, 255, 0.5)',
      }}
      onFocus={(e) => {
        // Change the border to red when focused
        e.target.style.border = '1px solid red';
      }}
      onBlur={(e) => {
        // Revert the border back to the initial style when focus is lost
        if (e.target.value.trim() === "") {
          e.target.style.border = '1px solid rgba(255, 255, 255, 0.5)';
        }
      }}
    />
    <label htmlFor="email" style={{ color: 'grey' }}>
      Email <span className="text-danger">*</span>
    </label>
  </div>
</div>
<div className="col-12">
  <div className="input-group mb-3">
    <div className="form-floating">
      <input
        type={showPassword ? 'text' : 'password'}
        className="form-control"
        name="password"
        id="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
        style={{
          backgroundColor: 'transparent',
          color: 'white',
          border: '1px solid rgba(255, 255, 255, 0.5)',
        }}
        onFocus={(e) => {
          // Change the border to red when focused
          e.target.style.border = '1px solid red';
        }}
        onBlur={(e) => {
          // Revert the border back to the initial style when focus is lost
          if (e.target.value.trim() === "") {
            e.target.style.border = '1px solid rgba(255, 255, 255, 0.5)';
          }
        }}
      />
      <label htmlFor="password" style={{ color: 'grey' }}>
        Password <span className="text-danger">*</span>
      </label>
    </div>
    <button
      type="button"
      className="btn btn-outline-secondary"
      onClick={() => setShowPassword(!showPassword)}
      style={{ color: 'white', borderColor: 'rgba(255, 255, 255, 0.5)' }}
    >
      {showPassword ? <FaEyeSlash /> : <FaEye />}
    </button>
  </div>
</div>

                    {error && (
                        <div className="alert alert-danger text-center" role="alert">
                            {error}
                            <br />
                            {resend && (
                                <button className="link-primary text-decoration-underline" onClick={requestVerificationEmail}>
                                    {resend}
                                </button>
                            )}
                        </div>
                    )}
                    <div className="col-12">
                        <div className="d-grid">
                            <button className="btn btn-purple btn-lg" type="submit" disabled={loading}>
                                {loading ? 'Logging in...' : t('login_button')}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
            <div className="d-flex gap-2 gap-md-4 flex-column flex-md-row justify-content-center mt-3">
                <p className="m-0 text-light text-center" style={{ color: 'white' }}>
                    <Link to='/passreset' className="link-primary text-decoration-none" style={{ color: 'white' }}>
                        {t('password_reset')}
                    </Link>
                </p>
            </div>
            <div className="col-12">
                <div className="d-flex gap-2 gap-md-4 flex-column flex-md-row justify-content-center mt-3 mb-3">
                    <p className="m-0 text-light text-center" style={{ color: 'white' }}>
                        <Link to='/signup' className="link-primary text-decoration-none" style={{ color: 'white' }}>
                            {t('create_account')}
                        </Link>
                  
                    </p>
                </div>
            </div>
            <div className="text-center">
                <Link to='/signup'>
                    <span className="btn btn-outline-success text-light snake-border-btn-2">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <b> {t('create_account_login')}</b>
              </span>
                </Link>
            </div>
        </div>
    </div>
</div>


            </div>
          </div>
        </section>
        </div>
      <Footer />
      </div>
    </div>
  );
};

export default LogIn;
