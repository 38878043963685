import React from 'react'
import Footer from './Footer'
import Navbar from './Navbar'
import Arsenal from '././Team-Kits-Images/Arsenal.png'
import ManCity from '././Team-Kits-Images/Man.City.png'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import Madrid from '././Team-Kits-Images/Madrid.png'


function UCLLearnMore() {

  const { t } = useTranslation();


  return (
    <div>
        <div className='App-headerV8'>
        <Navbar />


        <div class="container padtopinput">
    <div class="row justify-content-md-center">
      <div class="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
        <h2 class="mb-4 display-5 text-center text-light">{t('the_ucl_2024_predictor')}</h2>
        <hr class="w-50 mx-auto mb-2 mb-xl-9 border-dark-subtle"/>

      </div>
      <div className='container'>
      <p className="text-secondary mb-5 text-center lead fs-4">
  <div className='text-light'>
  {t('experience_the_thrill_ucl_2024')}
      {t('join_predictify')}
  </div>
  <br />
</p>

       </div>
    </div>
  </div>

  <h2 class="mb-4 display-5 text-center text-light">{t('how_to_play')}</h2>
        {/* <p class="text-secondary mb-5 text-center lead fs-4">We stand out as the epitome of customer-centricity, offering the best benefits like free shipping and 24/7 support.</p> */}
        <hr class="w-25 mx-auto mb-5 mb-xl-8 border-dark-subtle"/>
       <div className='container'>
       <p class="text-secondary mb-5 text-center lead fs-4 text-light"> {t('to_play_along_ucl_2024')}.
</p>
       </div>

  <div className='container'>
            <div  className=' my-3 card border-light text-light bg-transparent'>
              <div className='card-body text-center mb-1 '>
                <h5 className='card-title mb-3'>Real Madrid vs Man City</h5>
                <div className="row align-items-center">
                  <div className="col text-center d-flex flex-column align-items-center">
              {/* Display kit of home team */}
              <div className='Arsenal' ><img src={Madrid} alt="Arsenal" style={{ width: '75px', height: 'auto' }} /></div>  
                                        {/* Display kit of home team */}
                   <h5>
                      MAD 
                    </h5>
                                        {/* Display home team Initials */}

                  </div>
                  <div className="col text-center padtopinput  border-secondary">
                    <div className='form-group border-secondary'>
                      <input
                        type="number"
                    value='2'

                        // value={inputsData[fixture.fixture.id]?.home || ''}
                        // onChange={(e) => handleInputChange(fixture.fixture.id, 'home', e.target.value)}
                        className='form-control text-center  border-secondary'
                      />
                    </div>
                  </div>
                  <div className="col text-center padtopinput">
                    <div className='form-group'>
                      <input
                        type="number"
                        value='2'
                        // onChange={(e) => handleInputChange(fixture.fixture.id, 'away', e.target.value)}
                        className='form-control text-center  border-secondary'
                      />
                    </div>
                  </div>
                  <div className="col text-center d-flex flex-column align-items-center">
                    {/* Display kit of away team */}
              <div className='Arsenal' ><img src={ManCity} alt="Arsenal" style={{ width: '75px', height: 'auto' }} /></div>  
                                        {/* Display kit of away team */}

                    {/* Display away team Initials */}
                    <h5>
MCI                    </h5>
                                        {/* Display away team Initials */}

                  </div>
                </div>
                <div><b>{t('result_tbd')}</b></div>
                <p className="text-center"><b>{t('match_date')}</b></p>
              </div>
            </div>
          
      
  <>
  
  </>

        </div>
        <div className='container text-center'>
       <Link to='/uclpicks' > <button class="btn  btn-purple">{t('play_now')}</button> </Link>

        </div>


        <section class="bg-transparent py-3 py-md-5 py-xl-8">
  <div class="container">
    <div class="row justify-content-md-center">
      <div class="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
      <h2 className="mb-4 display-5 text-center text-light">{t('scoring_system')}</h2>
        {/* <p class="text-secondary mb-5 text-center lead fs-4">We stand out as the epitome of customer-centricity, offering the best benefits like free shipping and 24/7 support.</p> */}
        <hr class="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle"/>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="container-fluid">
          <div class="row gy-3 gy-md-4">
            <div class="col-12 col-lg-4">
              <div class="card border-light text-light bg-transparent">
                <div class="card-body p-3 p-md-4 p-xxl-5 d-flex justify-content-center align-items-center">
                  <div class="me-3 text-success">
                  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg>
                  </div>
                  <div>
                  <h4 className="mb-1 ">{t('three_points')}</h4>
<p className="m-0 text-light">{t('accurately_predict_outcome')}</p>

                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="card border-light text-light bg-transparent">
                <div class="card-body p-3 p-md-4 p-xxl-5 d-flex justify-content-center align-items-center">
                  <div class="me-3 text-warning">
                  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="bi bi-1-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M9.283 4.002H7.971L6.072 5.385v1.271l1.834-1.318h.065V12h1.312z"/>
</svg>
                  </div>
                  <div>
                  <h4 className="mb-1">{t('one_point')}</h4>
<p className="m-0 text-light">{t('accurately_predict_winning_team')}</p>

                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4">
              <div class="card border-light text-light bg-transparent">
                <div class="card-body p-3 p-md-4 p-xxl-5 d-flex justify-content-center align-items-center">
                  <div class="me-3 text-danger">
                  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
</svg>
                  </div>
                  <div>
                  <h4 className="mb-1">{t('zero_points')}</h4>
<p className="m-0 text-light">{t('incorrectly_predict_outcome')}</p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="bg-transparent py-3 py-md-5 py-xl-8">
  <div class="container">
    <div class="row justify-content-md-center">
      <div class="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
      <h2 className="mb-4 display-5 text-center text-light">{t('about_ucl_2024_predictor')}</h2>
        {/* <p class="text-secondary mb-5 text-center lead fs-4">We stand out as the epitome of customer-centricity, offering the best benefits like free shipping and 24/7 support.</p> */}
        <hr class="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle"/>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="container-fluid">
          <div class="row gy-3 gy-md-4">
          <div class="col-12 col-lg-4 ">
                <div class="card border-0 border-bottom border-secondary shadow-sm mt-lg-6 mt-xxl-8 mb-4 purple-bg">
                  <div class="card-body text-center p-4 p-xxl-5">
                  <h3 className="display-2 fw-bold mb-3 text-light">{t('free_to_play')}</h3>
<p className="fs-5 mb-0 text-light">{t('in_all_countries_regions')}</p>

                  </div>
                </div>
            
              </div>
            <div class="col-12 col-lg-4 ">
                <div class="card border-0 border-bottom border-secondary shadow-sm mt-lg-6 mt-xxl-8 mb-4 ">
                  <div class="card-body text-center p-4 p-xxl-5">
                  <h3 className="display-5 fw-bold mb-3 ">{t('automated_points_system')}</h3>
<p className="fs-5 mb-0 ">{t('trusted_and_reliable')}</p>

                  </div>
                </div>
               
              </div>
            <div class="col-12 col-lg-4 ">
                <div class="card border-0 border-bottom border-secondary shadow-sm mt-lg-6 mt-xxl-8 mb-4 purple-bg">
                  <div class="card-body text-center p-4 p-xxl-5">
                  <h3 className="display-2 fw-bold mb-3 text-light">{t('public_huddles')}</h3>
<p className="fs-5 mb-0 text-light">{t('private_huddles_available')}</p>

                  </div>
                </div>
             
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

        {/* <section class="bg-transparent py-3 py-md-5">
  <div class="container">
    <div class="row justify-content-md-center">
      <div class="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
        <h2 class="mb-4 display-5 text-center">About The EURO 2024 Predictor
</h2>
        <hr class="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle"/>
      </div>
    </div>
  </div>



  

  <div class="container">
    <div class="row gy-4 gy-lg-0 align-items-lg-center">
  
      <div class="col-12 col-lg-12">
        <div class="row justify-content-xl-end">
          <div class="col-12 col-xl-11">
            <div class="row gy-4 gy-sm-0 overflow-hidden">
              <div class="col-12 col-sm-6">
                <div class="card border-0 border-bottom border-secondary shadow-sm mb-4">
                  <div class="card-body text-center p-4 p-xxl-5">
                    <h3 class="display-2 fw-bold mb-2">Free To Play</h3>
                    <p class="fs-5 mb-0 text-secondary">In all Countries & Regions</p>
                  </div>
                </div>
                <div class="card border-0 border-bottom border-secondary shadow-sm purple-bg">
                  <div class="card-body text-center p-4 p-xxl-5">
                    <h3 class="display-2 fw-bold mb-2 text-light">Automated Points System</h3>
                    <p class="fs-5 mb-0 text-secondary text-light">Trusted And Reliable</p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-sm-6 ">
                <div class="card border-0 border-bottom border-secondary shadow-sm mt-lg-6 mt-xxl-8 mb-4 purple-bg">
                  <div class="card-body text-center p-4 p-xxl-5">
                    <h3 class="display-2 fw-bold mb-2 text-light">Easy To Use</h3>
                    <p class="fs-5 mb-0 text-light">Fun to Play </p>
                  </div>
                </div>
                <div class="card border-0 border-bottom border-secondary shadow-sm ">
                  <div class="card-body text-center p-4 p-xxl-5">
                    <h3 class="display-2 fw-bold mb-2">Play Publicly Or Privately</h3>
                    <p class="fs-5 mb-0 text-secondary">With anyone around the globe</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> */}



        <Footer />
        </div>
        </div>
  )
}

export default UCLLearnMore