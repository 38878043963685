import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import AdComponent from './AdComponent';
import { BiFootball } from "react-icons/bi";

function Social() {
  const [selectedCompetition, setSelectedCompetition] = useState('EPL');
  const [loadedFeeds, setLoadedFeeds] = useState({});
  const { t } = useTranslation();

  // Define which feeds to show for each competition
  const competitionFeeds = {
    'EPL': ['premierleague', 'BBCSport', 'SkySportsNews', 'FabrizioRomano'],
    'Championship': ['SkyBetChamp', 'EFL', 'SkyBetLeagueOne', 'itvfootball'],
    'SPFL': ['spfl', 'ScottishFA', 'BBCSportScot', 'falkirkfcf'],
    'La Liga': ['LaLiga', 'LaLigaTV', 'PremSportsTV', 'ESPNFC'],
    'Bundesliga': ['Bundesliga', 'FCBayernEN', 'DFL_Official', 'Transfermarkt'],
    'Champions League': ['ChampionsLeague', 'UEFA', 'UEFA_Foundation', 'SportIndustry'],
    'Serie A': ['SerieA', 'Gazzetta_it', 'CorSport', 'cmdotcom'],
    'SA Soccer': ['PSL', 'Soccer_Laduma', 'KickOffMagazine', 'iDiskiTimes']
  };

  // Twitter handles mapping
  const twitterHandles = {
    // EPL
    'premierleague': 'premierleague',
    'BBCSport': 'BBCSport',
    'SkySportsNews': 'SkySportsNews',
    'FabrizioRomano': 'FabrizioRomano',
    
    // Championship
    'SkyBetChamp': 'SkyBetChamp',
    'EFL': 'EFL',
    'SkyBetLeagueOne': 'SkyBetLeagueOne',
    'itvfootball': 'itvfootball',
    
    // SPFL
    'spfl': 'spfl',
    'ScottishFA': 'ScottishFA',
    'BBCSportScot': 'BBCSportScot',
    'falkirkfcf': 'falkirkfcf',
    
    // La Liga
    'LaLiga': 'LaLiga',
    'LaLigaTV': 'LaLigaTV',
    'PremSportsTV': 'PremSportsTV',
    'ESPNFC': 'ESPNFC',
    
    // Bundesliga
    'Bundesliga': 'Bundesliga_EN',
    'FCBayernEN': 'FCBayernEN',
    'DFL_Official': 'DFL_Official',
    'Transfermarkt': 'Transfermarkt',
    
    // Champions League
    'ChampionsLeague': 'ChampionsLeague',
    'UEFA': 'UEFA',
    'UEFA_Foundation': 'UEFA_Foundation',
    'SportIndustry': 'SportIndustry',
    
    // Serie A
    'SerieA': 'SerieA_EN',
    'Gazzetta_it': 'Gazzetta_it',
    'CorSport': 'CorSport',
    'cmdotcom': 'cmdotcom',
    
    // PSL
    'PSL': 'OfficialPSL',
    'Soccer_Laduma': 'Soccer_Laduma',
    'KickOffMagazine': 'KickOffMagazine',
    'iDiskiTimes': 'iDiskiTimes'
  };

  // Display names mapping
  const displayNames = {
    // EPL
    'premierleague': 'Premier League',
    'BBCSport': 'BBC Sport',
    'SkySportsNews': 'Sky Sports',
    'FabrizioRomano': 'Fabrizio Romano',
    
    // Championship
    'SkyBetChamp': 'Sky Bet Championship',
    'EFL': 'English Football League',
    'SkyBetLeagueOne': 'League One',
    'itvfootball': 'ITV Football',
    
    // SPFL
    'spfl': 'Scottish Premier League',
    'ScottishFA': 'Scottish FA',
    'BBCSportScot': 'BBC Sport Scotland',
    'falkirkfcf': 'Falkirk FC',
    
    // La Liga
    'LaLiga': 'La Liga',
    'LaLigaTV': 'La Liga TV',
    'PremSportsTV': 'Premier Sports TV',
    'ESPNFC': 'ESPN FC',
    
    // Bundesliga
    'Bundesliga': 'Bundesliga',
    'FCBayernEN': 'Bayern Munich',
    'DFL_Official': 'DFL',
    'Transfermarkt': 'Transfermarkt',
    
    // Champions League
    'ChampionsLeague': 'Champions League',
    'UEFA': 'UEFA',
    'UEFA_Foundation': 'UEFA Foundation',
    'SportIndustry': 'Sport Industry Group',
    
    // Serie A
    'SerieA': 'Serie A',
    'Gazzetta_it': 'La Gazzetta',
    'CorSport': 'Corriere dello Sport',
    'cmdotcom': 'Calciomercato',
    
    // PSL
    'PSL': 'Premier Soccer League',
    'Soccer_Laduma': 'Soccer Laduma',
    'KickOffMagazine': 'Kick Off',
    'iDiskiTimes': 'iDisko Times'
  };

  const loadTwitterScript = () => {
    return new Promise((resolve) => {
      // Check if Twitter script is already loaded
      if (window.twttr) {
        resolve();
        return;
      }

      const script = document.createElement('script');
      script.src = 'https://platform.twitter.com/widgets.js';
      script.async = true;
      script.charset = 'utf-8';
      script.onload = () => resolve();
      document.body.appendChild(script);
    });
  };

  const cleanupTwitterWidgets = () => {
    // Remove all existing Twitter timeline iframes
    const iframes = document.querySelectorAll('iframe[id^="twitter-widget"]');
    iframes.forEach(iframe => iframe.remove());
  };

  const initializeTwitterWidgets = async () => {
    if (window.twttr && window.twttr.widgets) {
      await window.twttr.widgets.load();
    }
  };

  useEffect(() => {
    loadTwitterScript();
  }, []);

  useEffect(() => {
    const observeTwitterWidgetLoad = (feed) => {
      const containerSelector = `.${feed}-container`;
      const interval = setInterval(() => {
        const container = document.querySelector(containerSelector);
        if (container && container.querySelector('iframe')) {
          setLoadedFeeds(prev => ({ ...prev, [feed]: true }));
          clearInterval(interval);
        }
      }, 100);

      return interval;
    };

    const loadFeeds = async () => {
      // Reset loaded states
      setLoadedFeeds({});
      
      // Clean up existing widgets
      cleanupTwitterWidgets();

      // Wait a brief moment for cleanup
      await new Promise(resolve => setTimeout(resolve, 100));

      // Initialize new widgets
      await initializeTwitterWidgets();

      // Set up observers for new feeds
      const intervals = competitionFeeds[selectedCompetition].map(feed => 
        observeTwitterWidgetLoad(feed)
      );

      // Cleanup function
      return () => {
        intervals.forEach(interval => clearInterval(interval));
      };
    };

    loadFeeds();
  }, [selectedCompetition]);

  const handleCompetitionChange = (competition) => {
    setSelectedCompetition(competition);
  };

  const renderTwitterFeed = (feed) => {
    const handle = twitterHandles[feed];
    const key = `${feed}-${selectedCompetition}`; // Add competition to key to force re-render

    return (
      <div className="col-12 col-md-10 col-xl-6 mb-4" key={key}>
        <h3 className="card-title text-center" style={{ color: 'white' }}>
          {displayNames[feed]} {t('latest_news')}
        </h3>
        <hr className="w-50 mx-auto mb-5 mb-xl-9 border-light-subtle" />
        <div className={`card border-0 bg-transparent ${feed}-container`}>
          <div className="card-body text-center p-4 p-xxl-5">
            {!loadedFeeds[feed] && (
              <Spinner animation="border" variant="primary" role="status">
                <span className="visually-hidden text-light">Loading...</span>
              </Spinner>
            )}
            <a 
              className="twitter-timeline" 
              data-height="650" 
              data-width="100%" 
              href={`https://twitter.com/${handle}?ref_src=twsrc%5Etfw`}
            ></a>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className='App-headerV8'>
        <Navbar />
        <div className="container">
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-12 col-md-10 col-lg-8 col-xl-7">
                <AdComponent />
                <h2 className="display-5 mb-1 text-center text-light padtop" style={{ fontFamily: 'Lato, sans-serif' }}>
                  {t('social_hub')}
                </h2>
                <hr className="w-50 mx-auto mb-5 mb-xl-9 border-light-subtle" />
                <div className='text-center'>
                  <div className="btn-group">
                    <button
                      type="button"
                      className="btn btn-purple-leaderboard text-light dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <BiFootball /> {selectedCompetition}
                    </button>
                    <ul className="dropdown-menu">
                      {Object.keys(competitionFeeds).map(competition => (
                        <li key={competition}>
                          <button 
                            className="dropdown-item" 
                            onClick={() => handleCompetitionChange(competition)}
                          >
                            {competition}
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className="py-5 py-xl-8">
            <div className="container overflow-hidden">
              <div className="row gy-4 gy-xl-0 justify-content-center">
                {competitionFeeds[selectedCompetition].map(feed => renderTwitterFeed(feed))}
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Social;