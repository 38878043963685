import React, { useState, useEffect, useMemo } from 'react';
import { createUserWithEmailAndPassword, sendEmailVerification, updateProfile } from 'firebase/auth';
import { ref, push, getDatabase, set } from 'firebase/database';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import Navbar from '../Navbar';
import '../App.css'
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import countryList from 'react-select-country-list'
import Image123 from '../LogoWhite4.png'
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { useTranslation } from 'react-i18next';

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [verificationSent, setVerificationSent] = useState(false);
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [firstname, setFirstName] = useState('');
  const [lastname, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [country, setCountry] = useState('');
  const [tokens, setTokens] = useState('');
  const [username, setUsername] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedGender, setSelectedGender] = useState('');
  const [selectedTokens, setSelectedToken] = useState('');
  const [selectedSport, setSelectedSport] = useState('');
  const [selectedTeam, setSelectedTeam] = useState('');
  const [promoemail, setPromoEmail] = useState(false);
  const [iAgree, setIAgree] = useState(false);
  const [ageVerified, setAgeVerified] = useState(false);
  const navigate = useNavigate();
  const prohibited = [/* your list of prohibited words */];
  const { t } = useTranslation();
  const options = useMemo(() => countryList().getData().map(country => ({ value: country.label, label: country.label })), []);
  const EPL_TEAMS = [
    'Arsenal', 'Aston Villa', 'Bournemouth', 'Brentford', 'Brighton', 'Chelsea',
    'Crystal Palace', 'Everton', 'Fulham', 'Liverpool', 'Luton Town', 'Manchester City',
    'Manchester United', 'Newcastle United', 'Nottingham Forest', 'Sheffield United',
    'Tottenham Hotspur', 'West Ham United', 'Wolverhampton Wanderers', 'Neutral'
  ];

  const RUGBY_TEAMS = [
    'All Blacks', 'Wallabies', 'Springboks', 'England', 'Ireland', 'Wales',
    'Scotland', 'France', 'Italy', 'Argentina', 'Japan', 'Fiji',
    'Samoa', 'Tonga', 'Georgia', 'Romania', 'USA', 'Canada', 'Uruguay', 'Spain' , 'Neutral'
  ];
  
  const AMERICAN_FOOTBALL_TEAMS = [
    'New England Patriots', 'Los Angeles Chargers', 'Buffalo Bills', 'Kansas City Chiefs',
    'Baltimore Ravens', 'Cincinnati Bengals', 'Pittsburgh Steelers', 'Cleveland Browns',
    'Indianapolis Colts', 'Tennessee Titans', 'Jacksonville Jaguars', 'Houston Texans',
    'Miami Dolphins', 'New York Jets', 'New York Giants', 'Dallas Cowboys',
    'Philadelphia Eagles', 'Washington Commanders', 'San Francisco 49ers', 'Seattle Seahawks',
    'Arizona Cardinals', 'Los Angeles Rams', 'Chicago Bears', 'Minnesota Vikings',
    'Detroit Lions', 'Atlanta Falcons', 'New Orleans Saints', 'Carolina Panthers' , 'Neutral'
  ]


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        await user.reload(); // Ensure latest user info is loaded
        if (user.emailVerified) {
          navigate('/');
        }
      }
    });
  
    return () => unsubscribe();
  }, [navigate]);
  

  useEffect(() => {
    if (auth.currentUser) {
      auth.currentUser.reload().then(() => {
        if (auth.currentUser.emailVerified) {
          alert(t('email_verified_message'));
        }
      });
    }
  }, [auth]);
  

  const signup = async () => {
    try {
      const authInstance = getAuth();
      const usernameLowerCase = username.toLowerCase();
  
      for (const word of prohibited) {
        if (usernameLowerCase.includes(word.toLowerCase())) {
          setError('Please do not use prohibited words');
          return;
        }
      }
  
      const userCredential = await createUserWithEmailAndPassword(authInstance, email, password);
      const user = userCredential.user;
  
      await sendEmailVerification(user);
      setVerificationSent(true);
      alert(t('verification_email_sent_2'));
  
      await updateProfile(user, {
        displayName: `${firstname} (${username}) (${lastname}) (${country}) (${selectedSport}) (${selectedTeam}) `,
        emailVerified: false,
      });
  
      const phoneNumberAsNumber = parseInt(phoneNumber.replace(/\D/g, ''), 10);
  
      // User data with walletAddress and userStatus
      const userData = {
        uid: user.uid,
        firstname,
        lastname,
        username,
        email,
        country,
        selectedGender,
        selectedSport,
        selectedTeam,
        tokens: 0, // Ensure tokens start as null
        phoneNumber: phoneNumberAsNumber,
        promoemail,
        role: 'user', // Default role
        walletAddress: '', // Wallet address initialized as null
        userStatus: 'active', // User status set to active upon signup
        timestamp: new Date().toISOString(),
        lastLoginDate: new Date().toISOString(),
      };
  
      // Store user data in Firebase under the user's uid
      const db = getDatabase();
      const userRef = ref(db, `users/${user.uid}`); // Use the uid as the key
      await set(userRef, userData);
  
    } catch (error) {
      setError(error.message);
      setTimeout(() => setError(''), 5000);
    }
  };
  

const isFormValid = () => {
  const hasValidUsername = !prohibited.some(word => username.toLowerCase().includes(word.toLowerCase()));

  const valid = (
    firstname &&
    lastname &&
    country &&
    selectedGender &&
    username &&
    email &&
    password &&
    phoneNumber &&
    // tokens &&
    iAgree &&
    ageVerified &&
    hasValidUsername &&
    !error
  );

  console.log({
    firstname,
    lastname,
    country,
    selectedGender,
    username,
    email,
    password,
    phoneNumber,
    tokens,
    iAgree,
    ageVerified,
    error,
    hasValidUsername,
    valid
  });

  return valid;
};




return (
  <div>
    <div className='App-headerV8'>
    <Navbar />
      <section className=" py-3 py-md-5 bg-transparent border-0" style={{ minHeight: '100vh' }}>
        <div className="container bg-transparent border-0">
          <div className="row justify-content-center bg-transparent border-0">
            <div className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-6 col-xxl-6 bg-transparent">
              <div className="card border border-0 rounded-3  bg-transparent">
                <div className="card-body p-3 p-md-7 p-xl-7 ">
                  <div className="text-center mb-3">
                    <a href="#!">
                      <img src={Image123} alt="BootstrapBrain Logo" width="160" height="50" />
                    </a>
                  </div>
                  <h2 className="fs-6 fw-normal text-center text-light mb-4">{t('register_description')}</h2>
                  <div>
                    <div className="row gy-2 overflow-hidden ">
                      {/* Input fields and selects */}
                      <div className="col-lg-6" style={{ backgroundColor: 'transparent' }}>
    <div 
        className="form-floating mb-3 text-secondary" 
        style={{ backgroundColor: 'transparent' }}
    >
        <input
            type="text"
            className="form-control text-light"
            name="firstName"
            id="firstName"
            placeholder={t('first_name')}
            maxLength={20}
            required
            value={firstname}
            onChange={(e) => setFirstName(e.target.value)}
            style={{
                backgroundColor: 'transparent', // Make input transparent
                color: 'white', // White text color
                border: '1px solid rgba(255, 255, 255, 0.5)', // Transparent border for the input
            }}
            onFocus={(e) => {
                // Change label color to grey when input is focused
                e.target.closest('.form-floating').querySelector('label').style.color = 'grey';
                // On focus, change the border color of the label container
                e.target.closest('.form-floating').style.border = '1px solid red';
            }}
            onBlur={(e) => {
                // Check if the input has value and set label color accordingly
                const label = e.target.closest('.form-floating').querySelector('label');
                if (e.target.value.trim() !== "") {
                    // If there's text, keep the label grey
                    label.style.color = 'grey';
                } else {
                    // If there's no text, revert the label color to white
                    label.style.color = 'white';
                }
                // On blur, revert the border color of the label container
                e.target.closest('.form-floating').style.border = '1px solid rgba(255, 255, 255, 0.5)';
            }}
        />
        <label
            htmlFor={t('first_name')}
            className="form-label bg-transparent text-secondary"
            style={{
                color: 'white', // Initial white text for the label
            }}
        >
            {t('first_name')}
        </label>
    </div>
</div>



<div className="col-lg-6" style={{ backgroundColor: 'transparent' }}>
    <div 
        className="form-floating mb-3"
        style={{ backgroundColor: 'transparent' }}
    >
        <input
            type="text"
            className="form-control text-light"
            name="lastName"
            id="lastName"
            placeholder={t('last_name')}
            maxLength={20}
            required
            value={lastname}
            onChange={(e) => setLastName(e.target.value)}
            style={{
                backgroundColor: 'transparent', // Make input transparent
                color: 'white', // White text color
                border: '1px solid rgba(255, 255, 255, 0.5)', // Transparent border for the input
            }}
            onFocus={(e) => {
                // Change label color to grey when input is focused
                e.target.closest('.form-floating').querySelector('label').style.color = 'grey';
                // On focus, change the border color of the label container
                e.target.closest('.form-floating').style.border = '1px solid red';
            }}
            onBlur={(e) => {
                // Check if the input has value and set label color accordingly
                const label = e.target.closest('.form-floating').querySelector('label');
                if (e.target.value.trim() !== "") {
                    // If there's text, keep the label grey
                    label.style.color = 'grey';
                } else {
                    // If there's no text, revert the label color to white
                    label.style.color = 'white';
                }
                // On blur, revert the border color of the label container
                e.target.closest('.form-floating').style.border = '1px solid rgba(255, 255, 255, 0.5)';
            }}
        />
        <label
            htmlFor={t('last_name')}
            className="form-label bg-transparent text-secondary"
            style={{
                color: 'white', // Initial white text for the label
            }}
        >
            {t('last_name')}
        </label>
    </div>
</div>

<div className="col-lg-6" style={{ backgroundColor: 'transparent' }}>
    <div className="form-floating mb-3" style={{ backgroundColor: 'transparent' }}>
        <input
            type="text"
            className="form-control text-light"
            name="country"
            id="country"
            placeholder={t('country')}
            maxLength={20}
            required
            value={country}
            onChange={(e) => setCountry(e.target.value)}
            style={{
                backgroundColor: 'transparent', // Make input transparent
                color: 'white', // White text color
                border: '1px solid rgba(255, 255, 255, 0.5)', // Transparent border for the input
            }}
            onFocus={(e) => {
                // Change label color to grey when input is focused
                e.target.closest('.form-floating').querySelector('label').style.color = 'grey';
                // On focus, change the border color of the label container
                e.target.closest('.form-floating').style.border = '1px solid red';
            }}
            onBlur={(e) => {
                // Check if the input has value and set label color accordingly
                const label = e.target.closest('.form-floating').querySelector('label');
                if (e.target.value.trim() !== "") {
                    // If there's text, keep the label grey
                    label.style.color = 'grey';
                } else {
                    // If there's no text, revert the label color to white
                    label.style.color = 'white';
                }
                // On blur, revert the border color of the label container
                e.target.closest('.form-floating').style.border = '1px solid rgba(255, 255, 255, 0.5)';
            }}
        />
        <label
            htmlFor={t('country')}
            className="form-label bg-transparent text-secondary"
            style={{
                color: 'white', // Initial white text for the label
            }}
        >
            {t('country')}
        </label>
    </div>
</div>



<div className="col-lg-6" style={{ backgroundColor: 'transparent' }}>
    <div className="form-floating mb-3" style={{ backgroundColor: 'transparent' }}>
        <input
            type="text"
            className="form-control text-light"
            name="gender"
            id="gender"
            placeholder={t('gender')}
            maxLength={20}
            required
            value={selectedGender}
            onChange={(e) => setSelectedGender(e.target.value)}
            style={{
                backgroundColor: 'transparent', // Make input transparent
                color: 'white', // White text color
                border: '1px solid rgba(255, 255, 255, 0.5)', // Transparent border for the input
            }}
            onFocus={(e) => {
                // Change label color to grey when input is focused
                e.target.closest('.form-floating').querySelector('label').style.color = 'grey';
                // On focus, change the border color of the label container
                e.target.closest('.form-floating').style.border = '1px solid red';
            }}
            onBlur={(e) => {
                // Check if the input has value and set label color accordingly
                const label = e.target.closest('.form-floating').querySelector('label');
                if (e.target.value.trim() !== "") {
                    // If there's text, keep the label grey
                    label.style.color = 'grey';
                } else {
                    // If there's no text, revert the label color to white
                    label.style.color = 'white';
                }
                // On blur, revert the border color of the label container
                e.target.closest('.form-floating').style.border = '1px solid rgba(255, 255, 255, 0.5)';
            }}
        />
        <label
            htmlFor={t('gender')}
            className="form-label bg-transparent text-secondary"
            style={{
                color: 'white', // Initial white text for the label
            }}
        >
            {t('gender')}
        </label>
    </div>
</div>



                      <div className="col-lg-6" style={{ backgroundColor: 'transparent' }}>
    <div 
        className="form-floating mb-3"
        style={{ backgroundColor: 'transparent' }}
    >
        <input
            type="text"
            className="form-control text-light"
            name="username"
            id="username"
            placeholder={t('username')}
            maxLength={20}
            required
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            style={{
                backgroundColor: 'transparent', // Make input transparent
                color: 'white', // White text color
                border: '1px solid rgba(255, 255, 255, 0.5)', // Transparent border for the input
            }}
            onFocus={(e) => {
                // Change label color to grey when input is focused
                e.target.closest('.form-floating').querySelector('label').style.color = 'grey';
                // On focus, change the border color of the label container
                e.target.closest('.form-floating').style.border = '1px solid red';
            }}
            onBlur={(e) => {
                // Check if the input has value and set label color accordingly
                const label = e.target.closest('.form-floating').querySelector('label');
                if (e.target.value.trim() !== "") {
                    // If there's text, keep the label grey
                    label.style.color = 'grey';
                } else {
                    // If there's no text, revert the label color to white
                    label.style.color = 'white';
                }
                // On blur, revert the border color of the label container
                e.target.closest('.form-floating').style.border = '1px solid rgba(255, 255, 255, 0.5)';
            }}
        />
        <label
            htmlFor={t('username')}
            className="form-label bg-transparent text-secondary"
            style={{
                color: 'white', // Initial white text for the label
            }}
        >
            {t('username')}
        </label>
    </div>
</div>

<div className="col-lg-6" style={{ backgroundColor: 'transparent' }}>
  <div className="form-floating mb-3" style={{ backgroundColor: 'transparent' }}>
    <input
      type="email"
      className="form-control text-light"
      name="email"
      id="email"
      placeholder="Email"
      required
      value={email}
      onChange={(e) => setEmail(e.target.value)}
      style={{
        backgroundColor: 'transparent', // Make input transparent
        color: 'white', // White text color
        border: '1px solid rgba(255, 255, 255, 0.5)', // Transparent border for the input
      }}
      onFocus={(e) => {
        // Change label color to grey when input is focused
        e.target.closest('.form-floating').querySelector('label').style.color = 'grey';
        // On focus, change the border color of the label container
        e.target.closest('.form-floating').style.border = '1px solid red';
      }}
      onBlur={(e) => {
        // Check if the input has value and set label color accordingly
        const label = e.target.closest('.form-floating').querySelector('label');
        if (e.target.value.trim() !== "") {
          // If there's text, keep the label grey
          label.style.color = 'grey';
        } else {
          // If there's no text, revert the label color to white
          label.style.color = 'white';
        }
        // On blur, revert the border color of the label container
        e.target.closest('.form-floating').style.border = '1px solid rgba(255, 255, 255, 0.5)';
      }}
    />
    <label
      htmlFor="email"
      className="form-label bg-transparent text-secondary"
      style={{
        color: 'white', // Initial white text for the label
      }}
    >
      Email
    </label>
  </div>
</div>


                      <div className="col-12 bg-transparent  text-secondary border-secondary">
                          <div className="input-group mb-3 bg-transparent  text- border-secondary">
                            <div className="form-floating  bg-transparent  text- border-secondary">
                              <input
                                type={showPassword ? 'text' : 'password'}
                                className="form-control  bg-transparent text-secondary border-secondary"
                                name="password"
                                id="password"
                                placeholder={t('password')}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                              />
                              <label htmlFor={t('password')}>{t('password')} <span className="text-light border-secondary"></span></label>
                            </div>
                            <button
                              type="button"
                              className="btn btn-outline-light border-secondary"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? 'Hide' : 'Show'}
                            </button>
                          </div>
                        </div>
                        <div className="col-lg-6 bg-transparent text-light"> {/* Center the content horizontally */}
  <select
    className="form-control form-control-sm border-outline-secondary mb-2 bg-transparent text-light text-center"
    aria-label="Select Favourite Sport"
    value={selectedSport}
    onChange={(e) => setSelectedSport(e.target.value)}
    required
  >
    <option className='text-dark' value="" disabled>{t('select_favourite_sport')}</option>
    <option className='text-dark' value="football">{t('football')}</option>
    <option className='text-dark' value="rugby">{t('rugby')}</option>
    <option className='text-dark' value="american_football">{t('american_football')}</option>
  </select>
</div>

<div className='col-lg-6 mb-2 text-light'>
  <input
    type="text"
    className="form-control form-control-sm border-outline-secondary mb-2 bg-transparent text-light text-center"
    placeholder={t('enter_favourite_team')}
    value={selectedTeam}
    onChange={(e) => setSelectedTeam(e.target.value)}
    required
    style={{
      color: 'white', // Set text color to white
    }}
  />
</div>
<div className="row justify-content-center mb-2 bg-transparent mx-auto justify-content-center text-center">
  {/* Left side - Checkboxes */}
    <div className="col-lg-6">
    <div className="row justify-content-center mb-3  text-light">
  <div className="col-12">
    <PhoneInput
      country="mt"
      value={phoneNumber}
      onChange={setPhoneNumber}
      inputClassName="mx-auto bg-transparent text-light w-100"
      required
      containerClass="bg-transparent w-100"
      inputStyle={{ background: 'transparent', border: '1px solid white', width: '100%' }}
      buttonStyle={{ background: 'transparent', border: 'none' }}
      dropdownStyle={{ background: 'transparent' }}
      containerStyle={{ background: 'transparent', width: '100%' }}
      specialLabel=""
      buttonClass="bg-transparent"
    />
  </div>
</div>
    {/* Button below the Phone Input */}
    <div className='col-lg-12 mx-auto justify-content-center text-center mb-3' >
      <Link to="/login">
        <button
          className='btn btn-outline-success btn-sm form-control text-light mx-auto justify-content-center text-center' 
          style={{
            width: '100%',  // Make the button the same width as other form inputs
            backgroundColor: 'transparent',  // Make background transparent to match other inputs
            marginLeft: '3rem'
          }}
        >
         {t('already_have_an_account')}
        </button>
      </Link>
    </div>
  </div>
  {/* Right side - Phone input and Button */}

  <div className="col-lg-6 mx-auto justify-content-center text-center" >
    <div className="row mx-auto justify-content-center text-center">
      {/* Terms and Conditions */}
      <div className="col-12 form-check text-light bg-transparent">
        <input
          type="checkbox"
          className="form-check-input bg-transparent "
          id="iAgree"
          checked={iAgree}
          onChange={() => setIAgree(!iAgree)}
          required
        />
        
        <label className="form-check-label text-light " htmlFor="iAgree">
        {t('agree_to_terms')} <a href="#!">{t('terms_and_conditions')}</a>
        </label>
      </div>
      
      {/* Age Verification */}
      <div className="col-12 form-check text-light bg-transparent">
        <input
          type="checkbox"
          className="form-check-input bg-transparent"
          id="ageVerified"
          checked={ageVerified}
          onChange={() => setAgeVerified(!ageVerified)}
          required
        />
        <label className="form-check-label text-light" htmlFor="ageVerified">
        {t('i_am_at_least_18')}
        </label>
      </div>
      
      {/* Promotional Emails */}
      <div className="col-12 form-check text-light">
        <input
          type="checkbox"
          className="form-check-input bg-transparent"
          id="promoemail"
          checked={promoemail}
          onChange={() => setPromoEmail(!promoemail)}
          required
        />
        <label className="form-check-label text-light" htmlFor="promoemail">
        {t('i_agree_to_receive_promotional_emails')}
        </label>
      </div>
    </div>
  </div>

</div>
                      <div className="col-12">
                        <button
                          className="btn btn-purple w-100 border-0 "
                          onClick={signup}
                          disabled={!isFormValid()}
                        >
                          <div className='text-light'>{t('sign_up')}</div>
                        </button>
                        {error && <p className="text-danger">{error}</p>}
                        {verificationSent && (
                        <div>
                          <div className="alert alert-success text-center" role="alert">
                            <p>{t('verification_email_sent')}</p>
                          </div>
                          <div className="alert alert-warning text-center" role="alert">
                            <p>{t('refresh_page')}</p>
                          </div>
                        </div>
                      )}
                      </div>
                    </div>
              
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    <Footer />
    </div>
  </div>
);
};

export default SignUp;