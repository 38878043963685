import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


function Privacy() {

  const { t } = useTranslation();

  return (
    <div>
      <div className='App-headerV9'>

      <Navbar />
      </div>
      <div className=''>
        <div className='container'>
        <div className='mb-6'></div>

        <b>{t('privacy_policy_title')}</b>
<ul />
<b>{t('privacy_policy_intro_title')}</b>
<ul />
<p>{t('privacy_policy_intro_text')}</p>
<b>{t('privacy_policy_info_title')}</b>
<ul />
<p>{t('privacy_policy_info_text_1')}</p>
<p>{t('privacy_policy_info_text_2')}</p>
<b>{t('privacy_policy_usage_title')}</b>

  <ul />
  <p>{t('privacy_policy_usage_1')}</p>
<p>{t('privacy_policy_usage_2')}</p>
<p>{t('privacy_policy_usage_3')}</p>
<p>{t('privacy_policy_usage_4')}</p>

<b>{t('privacy_policy_sharing_title')}</b>
<ul />
<p>{t('privacy_policy_sharing_text')}</p>
<p>{t('privacy_policy_sharing_1')}</p>
<p>{t('privacy_policy_sharing_2')}</p>
<p>{t('privacy_policy_sharing_3')}</p>

<b>{t('privacy_policy_security_title')}</b>

  <ul />
  <p>{t('privacy_policy_security_text')}</p>

<b>{t('privacy_policy_rights_title')}</b>
<ul />
<p>{t('privacy_policy_rights_text')}</p>

<b>{t('privacy_policy_contact_title')}</b>
<ul />
<p>{t('privacy_policy_contact_text')}</p>
<p>{t('privacy_policy_contact_email')}</p>

<b>{t('privacy_policy_changes_title')}</b>
<ul />
<p>{t('privacy_policy_changes_text')}</p>

  <p>{t('last_updated_text')}</p>
  <ul />
  </div>
  <ul />
</div>
<br />

<div className='App-headerV9'>

    <Footer />
</div>
    </div>

  )
}

export default Privacy