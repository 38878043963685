import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


function Terms() {

  const { t } = useTranslation();

  return (
    <div>
<div className='App-headerV9'>

<Navbar />
</div>      <div className='mb-6'></div>
<div className='container '>

<b className='pad'>{t('terms_conditions_title')}</b>
<ul />
<b className='mb-3 padtop' style={{ marginTop: '10px' }}>{t('introduction_title')}</b>
<ul />

<p>{t('introduction_text')}</p>

<b>{t('eligibility_title')}</b>
<ul />

<p>{t('eligibility_text_1')}</p>
<p>{t('eligibility_text_2')}</p>


<b>{t('participation_title')}</b>
    <p>{t('participation_text_1')}</p>
    <p>{t('participation_text_2')}</p>
    <p>{t('participation_text_3')}</p>

<b>{t('game_rules_title')}</b>
    <p>{t('game_rules_text_1')}</p>
    <p>{t('game_rules_text_2')}</p>
    <p>{t('game_rules_text_3')}</p>

<b>{t('privacy_title')}</b>
    <p>{t('privacy_text_1')}</p>
    <p>{t('privacy_text_2')}</p>
    <b>{t('conduct_title')}</b>

<ul />
<p>{t('conduct_text_1')}</p>
<p>{t('conduct_text_2')}</p>


<b>{t('liability_title')}</b><b />
<ul />
<p>{t('liability_text_1')}</p>
<p>{t('liability_text_2')}</p>

<b>{t('changes_to_terms_title')}</b>
<ul />
<p>{t('changes_to_terms_text_1')}</p>
<p>{t('changes_to_terms_text_2')}</p>

<b className='mb-3'>{t('contact_title')}</b>
<ul />
<p>{t('contact_text_1')}</p>
<p>{t('contact_text_2')}</p>

<p className='mb-3' style={{marginBottom: '5px'}}>{t('last_updated_text')}</p>
<ul />
<br />
</div>
<div className='App-headerV9'>

    <Footer />
</div>    </div>

  )
}

export default Terms