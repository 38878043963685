import React, { useEffect, useState } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function UclStandingsPicks() {
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [season, setSeason] = useState('2024'); // Default season
  const [teamFixtures, setTeamFixtures] = useState({ finished: [], notStarted: [] }); // Initialize with empty arrays
  const navigate = useNavigate();
  const { t } = useTranslation();

  const uclTeams = [
    'Bayern München', 'Aston Villa', 'Liverpool', 'Juventus', 'Atletico Madrid', 'Real Madrid', 'Sporting CP',
    'Arsenal', 'Atalanta', 'Barcelona', 'Bayer Leverkusen', 'Benfica', 'Bologna', 'Borussia Dortmund', 
    'Stade Brestois 29', 'Celtic', 'Club Brugge KV', 'Feyenoord', 'Girona', 'Inter', 'Manchester City', 
    'Monaco', 'Paris Saint Germain', 'RB Leipzig', 'FK Crvena Zvezda', 'Red Bull Salzburg', 'Shakhtar Donetsk',
    'Slovan Bratislava', 'Sparta Praha', 'Sturm Graz', 'PSV Eindhoven', 'VfB Stuttgart', 'AC Milan', 'Lille',
    'BSC Young Boys', 'Dinamo Zagreb'
  ];

  const fetchStandings = async (season) => {
    setLoading(true);
    const response = await fetch(`https://iga-sport.azurewebsites.net/api/league/2/fixtures/season/${season}`, {
      headers: {
        'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
      }
    });

    const data = await response.json();
    const updatedStandings = calculateStandings(data);

    // Fetch and display fixtures for Aston Villa
    const astonVillaFixtures = data.filter(fixture => 
      (fixture.teams.home.name === 'Aston Villa' || fixture.teams.away.name === 'Aston Villa')
    );

    // Separate finished and not started fixtures
    const finishedFixtures = astonVillaFixtures.filter(fixture => fixture.fixture.status.short === 'FT');
    const notStartedFixtures = astonVillaFixtures.filter(fixture => fixture.fixture.status.short === 'NS');

    // Filter fixtures for those played after September 16, 2024
    const cutoffDate = new Date('2024-09-16');
    const relevantFinishedFixtures = finishedFixtures.filter(fixture => new Date(fixture.fixture.date) > cutoffDate);

    setTeamFixtures({
      finished: relevantFinishedFixtures,
      notStarted: notStartedFixtures
    });

    setStandings(updatedStandings);
    setLoading(false);
  };

  useEffect(() => {
    fetchStandings(season);
  }, [season]);

  const calculateStandings = (fixtures) => {
    const standingsMap = {};
    const cutoffDate = new Date('2024-09-16'); // Cutoff date

    // Initialize standingsMap with UCL teams and 0 points
    uclTeams.forEach((team) => {
      standingsMap[team] = { points: 0, wins: 0, draws: 0, losses: 0, matchesPlayed: 0 };
    });

    // Calculate points and results for each concluded fixture after the cutoff date
    fixtures.forEach((fixture) => {
      const fixtureDate = new Date(fixture.fixture.date);
      if (fixtureDate <= cutoffDate || fixture.fixture.status.short !== 'FT') {
        return; // Skip fixtures before or on the cutoff date and non-concluded fixtures
      }

      const homeTeam = fixture.teams.home.name;
      const awayTeam = fixture.teams.away.name;
      const homeGoals = fixture.goals.home;
      const awayGoals = fixture.goals.away;

      // Skip teams that are not in the UCL
      if (!uclTeams.includes(homeTeam) && !uclTeams.includes(awayTeam)) {
        return;
      }

      // Determine match result
      let homePoints = 0;
      let awayPoints = 0;

      if (homeGoals > awayGoals) {
        homePoints = 3;
        if (uclTeams.includes(homeTeam)) standingsMap[homeTeam].wins += 1;
        if (uclTeams.includes(awayTeam)) standingsMap[awayTeam].losses += 1;
      } else if (homeGoals < awayGoals) {
        awayPoints = 3;
        if (uclTeams.includes(homeTeam)) standingsMap[homeTeam].losses += 1;
        if (uclTeams.includes(awayTeam)) standingsMap[awayTeam].wins += 1;
      } else {
        homePoints = 1;
        awayPoints = 1;
        if (uclTeams.includes(homeTeam)) standingsMap[homeTeam].draws += 1;
        if (uclTeams.includes(awayTeam)) standingsMap[awayTeam].draws += 1;
      }

      // Update standings for UCL teams only
      if (uclTeams.includes(homeTeam)) {
        standingsMap[homeTeam].points += homePoints;
        standingsMap[homeTeam].matchesPlayed += 1;
      }
      if (uclTeams.includes(awayTeam)) {
        standingsMap[awayTeam].points += awayPoints;
        standingsMap[awayTeam].matchesPlayed += 1;
      }
    });

    // Convert standings map to array and sort by points
    const standingsArray = Object.entries(standingsMap)
      .map(([team, stats]) => ({ team, ...stats }))
      .sort((a, b) => b.points - a.points); // Sort by points in descending order

    return standingsArray;
  };

  const handleGoBack = () => {
    navigate(-1); // Navigate back one step in the history stack
  };

  return (
    <div className="container">
    <div className="">
      <h5 className="text-light">{t('standings')}</h5>
      <hr className="w-25 text-light" />
    </div>
    <div className="table-responsive" style={{ backgroundColor: 'transparent' }}>
      {loading ? (
        <h2 className="text-center text-dark"><Spinner /></h2>
      ) : (
        <table className="table transparent-table" style={{ backgroundColor: 'transparent' }}>
          <thead>
            <tr>
            <th className='text-light'>{t('position')}</th>
<th className='text-light'>{t('team')}</th>
<th className='text-light'>{t('played')}</th>
<th className='text-light'>{t('won')}</th>
<th className='text-light'>{t('drawn')}</th>
<th className='text-light'>{t('lost')}</th>
<th className='text-light'>{t('points_standings')}</th>
                  </tr>
                </thead>
                <tbody>
                  {standings.map((team, index) => (
                    <tr key={index}>
                      <th scope="row" className={
            index < 8 ? 'text-primary' : // Positions 1-8: Blue
            index >= 8 && index < 24 ? 'text-warning' : // Positions 9-24: Yellow
            'text-danger' // Positions 25 and below: Red
          }>
                        {index + 1}
                      </th>
                <td>
                      {team.team === 'Stade Brestois 29' ? (
  <Link to="/" style={{ textDecoration: 'none', color: 'white' }}> Brestois</Link>
) : team.team === 'Borussia Dortmund' ? (
  <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>Dortmund</Link>
) : team.team === 'Paris Saint Germain' ? (
  <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>PSG</Link>
) : team.team === 'Bayern München' ? (
  <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>Bayern</Link>
) : team.team === 'Aston Villa' ? (
  <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>A. Villa</Link>
) : team.team === 'Liverpool' ? (
  <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>Liverpool</Link>
) : team.team === 'Juventus' ? (
  <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>Juventus</Link>
) : team.team === 'Atletico Madrid' ? (
  <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>Atletico</Link>
) : team.team === 'Real Madrid' ? (
  <Link to="/" style={{ textDecoration: 'none', color: 'white' }}> Madrid</Link>
) : team.team === 'Sporting CP' ? (
  <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>Sporting</Link>
) : team.team === 'Arsenal' ? (
  <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>Arsenal</Link>
) : team.team === 'Atalanta' ? (
  <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>Atalanta</Link>
) : team.team === 'Barcelona' ? (
  <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>Barcelona</Link>
) : team.team === 'Bayer Leverkusen' ? (
  <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>Leverkusen</Link>
) : team.team === 'Benfica' ? (
  <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>Benfica</Link>
) : team.team === 'Bologna' ? (
  <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>Bologna</Link>
) : team.team === 'Celtic' ? (
  <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>Celtic</Link>
) : team.team === 'Club Brugge KV' ? (
  <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>Brugge</Link>
) : team.team === 'Feyenoord' ? (
  <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>Feyenoord</Link>
) : team.team === 'Girona' ? (
  <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>Girona</Link>
) : team.team === 'Inter' ? (
  <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>Inter</Link>
) : team.team === 'Manchester City' ? (
  <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>Man City</Link>
) : team.team === 'Monaco' ? (
  <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>Monaco</Link>
) : team.team === 'RB Leipzig' ? (
  <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>RB Leipzig</Link>
) : team.team === 'FK Crvena Zvezda' ? (
  <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>C. Zvezda</Link>
) : team.team === 'Red Bull Salzburg' ? (
  <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>Salzburg</Link>
) : team.team === 'Shakhtar Donetsk' ? (
  <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>Shakhtar</Link>
) : team.team === 'Slovan Bratislava' ? (
  <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>Slovan</Link>
) : team.team === 'Sparta Praha' ? (
  <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>S. Praha</Link>
) : team.team === 'Sturm Graz' ? (
  <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>Sturm Graz</Link>
) : team.team === 'PSV Eindhoven' ? (
  <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>PSV</Link>
) : team.team === 'VfB Stuttgart' ? (
  <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>Stuttgart</Link>
) : team.team === 'AC Milan' ? (
  <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>AC Milan</Link>
) : team.team === 'Lille' ? (
  <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>Lille</Link>
) : team.team === 'BSC Young Boys' ? (
  <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>Young Boys</Link>
) : team.team === 'Dinamo Zagreb' ? (
  <Link to="/" style={{ textDecoration: 'none', color: 'white' }}>Dinamo Z.</Link>
) : (
  team.team
)}

                      </td>
                <td className='text-light'>{team.matchesPlayed}</td>
                <td className='text-light'>{team.wins}</td>
                <td className='text-light'>{team.draws}</td>
                <td className='text-light'>{team.losses}</td>
                <td className='text-light'>{team.points}</td>
              </tr>
            ))}

          </tbody>
        </table>
      )}
    </div>
    <div className="container mt-3">
      <div className="d-flex flex-column align-items-center">
        <div className="d-flex align-items-center mb-2" style={{ width: '100%', maxWidth: '400px' }}>
          <div className="badge text-primary me-3" style={{ width: '20px', textAlign: 'center' }}>|</div>
          <div className="text-light text-start flex-grow-1">{t('position_1_8')}
          </div>
        </div>
        <div className="d-flex align-items-center mb-2" style={{ width: '100%', maxWidth: '400px' }}>
          <div className="badge text-warning me-3" style={{ width: '20px', textAlign: 'center' }}>|</div>
          <div className="text-light text-start flex-grow-1">{t('position_9_24')}
          </div>
        </div>
       
        <div className="d-flex align-items-center" style={{ width: '100%', maxWidth: '400px' }}>
          <div className="badge text-danger me-3" style={{ width: '20px', textAlign: 'center' }}>|</div>
          <div className="text-light text-start flex-grow-1">
          {t('position_25_36')}
</div>
        </div>
      </div>
    </div>
  </div>
  );
}

export default UclStandingsPicks;
