import React, { useEffect, useState, useRef } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Torino from '../Team-Kits-Images/Torino.png'
import Napoli from '../Team-Kits-Images/Napoli.png'
import Udinese from '../Team-Kits-Images/Udinese.png'
import Juventus from '../Team-Kits-Images/Juventus.png'
import Empoli from '../Team-Kits-Images/Empoli.png'
import Inter from '../Team-Kits-Images/Inter.png'
import Milan from '../Team-Kits-Images/Milan.png'
import Lazio from '../Team-Kits-Images/Lazio.png'
import Roma from '../Team-Kits-Images/Roma.png'
import Verona from '../Team-Kits-Images/Verona.png'
import Fiorentina from '../Team-Kits-Images/Fiorentina.png'
import Atalanta from '../Team-Kits-Images/Atalanta.png'
import Bologna from '../Team-Kits-Images/Bologna.png'
import Parma from '../Team-Kits-Images/Parma.png'
import Como from '../Team-Kits-Images/Com.png'
import Genoa from '../Team-Kits-Images/Genoa.png'
import Lecce from '../Team-Kits-Images/Lecce.png'
import Venezia from '../Team-Kits-Images/Venezia.png'
import Monza from '../Team-Kits-Images/Monza.png'
import Cagliari from '../Team-Kits-Images/Cagliari.png'
import { useTranslation } from 'react-i18next';
import { ChevronLeft, ChevronRight } from 'lucide-react';

import ArsenalLogo from '../Team-Kits-Images/ArsenalLogo.png'

function SerieAStandings() {
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [season, setSeason] = useState('2024'); // Default season
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false); // New state for modal visibility
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [teamStats, setTeamStats] = useState(null);
  const [formArray, setFormArray] = useState([]); // Initialize formArray
  const [nextFixture, setNextFixture] = useState(null);
  const [nextFixtures, setNextFixtures] = useState({});
  const { t } = useTranslation();
  const [recentFixtures, setRecentFixtures] = useState({});
  const scrollContainerRef = useRef(null);
  const [cardWidth, setCardWidth] = useState(0);
  const [visibleCards, setVisibleCards] = useState(3);
  const [isPaused, setIsPaused] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const scrollWidth = cardWidth + 16;

  const fetchStandings = async (season) => {
    setLoading(true);
    const response = await fetch(`https://iga-sport.azurewebsites.net/api/league/135/fixtures/season/${season}`, {
      headers: {
        'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
      }
    });

    const data = await response.json();
    const updatedStandings = calculateStandings(data);
    const latestFixtures = getRecentFixtures(data);
    setRecentFixtures(latestFixtures);
    setStandings(updatedStandings);
    setLoading(false);
  };

  useEffect(() => {
    fetchStandings(season);
  }, [season]);

  const calculateStandings = (fixtures) => {
    const standingsMap = {};
  
    // Initialize standingsMap with teams and 0 points
    fixtures.forEach((fixture) => {
      standingsMap[fixture.teams.home.name] = {
        points: 0, wins: 0, draws: 0, losses: 0, matchesPlayed: 0, lastFive: []
      };
      standingsMap[fixture.teams.away.name] = {
        points: 0, wins: 0, draws: 0, losses: 0, matchesPlayed: 0, lastFive: []
      };
    });
  
    // Calculate points and results for each played fixture
    fixtures.forEach((fixture) => {
      const status = fixture.fixture.status.short;
      if (status !== "FT") {
        return; // Skip fixtures that have not been played yet
      }
  
      const homeTeam = fixture.teams.home.name;
      const awayTeam = fixture.teams.away.name;
      const homeGoals = fixture.goals.home;
      const awayGoals = fixture.goals.away;
  
      let homeResult = "";
      let awayResult = "";
  
      if (homeGoals > awayGoals) {
        homeResult = "W";
        awayResult = "L";
        standingsMap[homeTeam].wins += 1;
        standingsMap[awayTeam].losses += 1;
      } else if (homeGoals < awayGoals) {
        homeResult = "L";
        awayResult = "W";
        standingsMap[homeTeam].losses += 1;
        standingsMap[awayTeam].wins += 1;
      } else {
        homeResult = "D";
        awayResult = "D";
        standingsMap[homeTeam].draws += 1;
        standingsMap[awayTeam].draws += 1;
      }
  
      standingsMap[homeTeam].points += homeResult === "W" ? 3 : homeResult === "D" ? 1 : 0;
      standingsMap[awayTeam].points += awayResult === "W" ? 3 : awayResult === "D" ? 1 : 0;
      standingsMap[homeTeam].matchesPlayed += 1;
      standingsMap[awayTeam].matchesPlayed += 1;
  
      // Update lastFive results (most recent on the right)
      standingsMap[homeTeam].lastFive = [...standingsMap[homeTeam].lastFive, homeResult].slice(-5);
      standingsMap[awayTeam].lastFive = [...standingsMap[awayTeam].lastFive, awayResult].slice(-5);
    });
  
    // Convert standings map to array
    return Object.entries(standingsMap)
      .map(([team, stats]) => ({ team, ...stats }))
      .sort((a, b) => b.points - a.points); // Sort by points in descending order
  };
  
  

  const handleGoBack = () => {
    navigate(-1); // Navigate back one step in the history stack
  };


  const getTeamKit = (team) => {
    switch (team) {
      case 'Torino':
        return Torino;
      case 'Napoli':
        return Napoli;
      case 'Udinese':
        return Udinese;
      case 'Juventus':
        return Juventus;
      case 'Empoli':
        return Empoli;
      case 'Inter':
        return Inter;
      case 'AC Milan':
        return Milan;
      case 'Lazio':
        return Lazio;
      case 'AS Roma':
        return Roma;
      case 'Verona':
        return Verona;
      case 'Fiorentina':
        return Fiorentina;
      case 'Atalanta':
        return Atalanta;
      case 'Bologna':
        return Bologna;
      case 'Parma':
        return Parma;
      case 'Como':
        return Como;
      case 'Genoa':
        return Genoa;
      case 'Lecce':
        return Lecce;
      case 'Venezia':
        return Venezia;
      case 'Monza':
        return Monza;
      case 'Cagliari':
        return Cagliari;
      default:
        return null;
    }
  };

  // Modal 

  useEffect(() => {
    const fetchTeamStats = async () => {
      try {
        const response = await fetch('https://iga-sport.azurewebsites.net/api/league-teams-stats/135/season/2024', {
          headers: {
            'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch team stats');
        }
        const data = await response.json();
        const Data = data.find(team => team.id === 42);
        if (Data) {
          setTeamStats(Data);
        } else {
          console.log(' data not found');
        }
      } catch (error) {
        console.error('Error fetching team stats:', error);
      }
    };

    const fetchNextFixture = async () => {
      try {
        const response = await fetch('https://iga-sport.azurewebsites.net/api/league/135/fixtures/season/2024', {
            headers: {
                'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
              }
            })
        if (!response.ok) {
          throw new Error('Failed to fetch fixtures');
        }
        const fixturesData = await response.json();
        console.log('All fixtures:', fixturesData);

        const Fixtures = fixturesData.filter(fixture => {
          return fixture.teams.home.id === 42 || fixture.teams.away.id === 42;
        });
        console.log('Fixtures:', Fixtures);

        // Sort the fixtures by date in ascending order
        Fixtures.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));

        // Find the first upcoming fixture
        const upcomingFixture = Fixtures.find(fixture => {
          const fixtureDate = new Date(fixture.fixture.date); // Convert fixture date string to Date object
          const currentDate = new Date(); // Get current date
          return fixtureDate > currentDate; // Return true if fixture date is in the future
        });

        setNextFixture(upcomingFixture);
      } catch (error) {
        console.error('Error fetching fixtures:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTeamStats();
    fetchNextFixture();
  }, []);
  
  const form = teamStats?.form || '';

  const lastFiveMatches = formArray.slice(-5);
  
  const openModal = async (teamName) => {
    if (!standings || standings.length === 0) {
      console.warn("Standings data is not available yet.");
      return; // Prevent opening modal if standings are not loaded
    }
  
    setSelectedTeam(teamName);
  
    try {
      setLoading(true);
  
      const response = await fetch('https://iga-sport.azurewebsites.net/api/league/135/fixtures/season/2024', {
        headers: {
          'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg==',
        },
      });
  
      const data = await response.json();
  
      const teamFixtures = data.filter(
        (fixture) =>
          (fixture.teams.home.name === teamName || fixture.teams.away.name === teamName) &&
          fixture.fixture.status.short === 'FT'
      );
  
      teamFixtures.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));
  
      const lastFiveGames = teamFixtures.slice(-5);
  
      const formArray = lastFiveGames.map((game) => {
        const isHome = game.teams.home.name === teamName;
        const goalsFor = isHome ? game.goals.home : game.goals.away;
        const goalsAgainst = isHome ? game.goals.away : game.goals.home;
  
        if (goalsFor > goalsAgainst) return 'W';
        if (goalsFor === goalsAgainst) return 'D';
        return 'L';
      });
  
      setFormArray(formArray);
  
      // Safely retrieve team stats
      const selectedTeamStats = standings.find((team) => team.team === teamName);
  
      if (selectedTeamStats) {
        setTeamStats(selectedTeamStats);
      } else {
        console.warn("Team stats not found in standings.");
        setTeamStats(null); // Fallback
      }
  
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error fetching last 5 games:', error);
    } finally {
      setLoading(false);
    }
  };
  
  
  
  const closeModal = () => {
    setIsModalOpen(false); // Hide modal
  };

  const teamKits = {
    'Torino': Torino,
    'Napoli': Napoli,
    'Udinese': Udinese,
    'Juventus': Juventus,
    'Empoli': Empoli,
    'Inter': Inter,
    'AC Milan': Milan,
    'Lazio': Lazio,
    'AS Roma': Roma,
    'Verona': Verona,
    'Fiorentina': Fiorentina,
    'Atalanta': Atalanta,
    'Bologna': Bologna,
    'Parma': Parma,
    'Como': Como,
    'Genoa': Genoa,
    'Lecce': Lecce,
    'Venezia': Venezia,
    'Monza': Monza,
    'Cagliari': Cagliari
  };

  const getColorForMatch = (result) => {
    switch (result) {
      case 'W':
        return 'green';  // Green for Win
      case 'D':
        return '#f49f05'; // Yellow for Draw
      case 'L':
        return 'red';    // Red for Loss
      default:
        return 'gray';   // Fallback color
    }
  };


  useEffect(() => {
    const fetchTeamStats = async () => {
      try {
        const response = await fetch('https://iga-sport.azurewebsites.net/api/league-teams-stats/135/season/2024', {
          headers: {
            'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch team stats');
        }
        const data = await response.json();
        const Data = data.find(team => team.id === 42);
        if (Data) {
          setTeamStats(Data);
        } else {
          console.log(' data not found');
        }
      } catch (error) {
        console.error('Error fetching team stats:', error);
      }
    };

    const fetchNextFixture = async () => {
      try {
        const response = await fetch('https://iga-sport.azurewebsites.net/api/league/135/fixtures/season/2024', {
            headers: {
                'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
              }
            })
        if (!response.ok) {
          throw new Error('Failed to fetch fixtures');
        }
        const fixturesData = await response.json();
        console.log('All fixtures:', fixturesData);

        const Fixtures = fixturesData.filter(fixture => {
          return fixture.teams.home.id === 42 || fixture.teams.away.id === 42;
        });
        console.log('Fixtures:', Fixtures);

        // Sort the fixtures by date in ascending order
        Fixtures.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));

        // Find the first upcoming fixture
        const upcomingFixture = Fixtures.find(fixture => {
          const fixtureDate = new Date(fixture.fixture.date); // Convert fixture date string to Date object
          const currentDate = new Date(); // Get current date
          return fixtureDate > currentDate; // Return true if fixture date is in the future
        });

        setNextFixture(upcomingFixture);
      } catch (error) {
        console.error('Error fetching fixtures:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTeamStats();
    fetchNextFixture();
  }, []);
  

  const convertToFormattedDateTime = (dateString) => {
    const options = { day: 'numeric', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true };
    const date = new Date(dateString);
    return date.toLocaleString('en-US', options);
  };

  // Modal end

  const teamInitials = {
'Torino': 'TOR',
    'Napoli': 'NAP',
    'Udinese': 'UDI',
    'Juventus': 'JUV',
    'Empoli': 'EMP',
    'Inter': 'INT',
    'AC Milan': 'ACM',
    'Lazio': 'LAZ',
    'AS Roma': 'ROM',
    'Verona': 'VER',
    'Fiorentina': 'FIO',
    'Atalanta': 'ATA',
    'Bologna': 'BOL',
    'Parma': 'PAR',
    'Como': 'COM',
    'Genoa': 'GEN',
    'Lecce': 'LEC',
    'Venezia': 'VEN',
    'Monza': 'MON',
    'Cagliari': 'CAG'
  };
  
  
  const renderTeamInitials = (teamName) => {
    return teamInitials[teamName] || teamName;
  };
  
  
    useEffect(() => {
      const updateLayout = () => {
        if (!scrollContainerRef.current) return;
      
        if (window.innerWidth >= 1200) {
          setVisibleCards(3);
        } else if (window.innerWidth >= 768) {
          setVisibleCards(2);
        } else {
          setVisibleCards(1);
        }
      
        const containerWidth = scrollContainerRef.current.offsetWidth - 32; // Adjust for padding
        const gap = 16;
        const newCardWidth = Math.floor((containerWidth - (gap * (visibleCards - 1))) / visibleCards);
        setCardWidth(newCardWidth);
      };
  
      updateLayout();
      window.addEventListener('resize', updateLayout);
      return () => window.removeEventListener('resize', updateLayout);
    }, [visibleCards]);
  
    // Auto-scroll functionality
    useEffect(() => {
      if (!scrollContainerRef.current || isPaused) return;
  
      const totalFixtures = Object.keys(recentFixtures).length;
      const scrollInterval = setInterval(() => {
        setCurrentIndex((prevIndex) => {
          const nextIndex = (prevIndex + 1) % totalFixtures;
          const scrollAmount = (cardWidth + 16) * nextIndex;
          
          scrollContainerRef.current?.scrollTo({
            left: scrollAmount,
            behavior: 'smooth'
          });
          
          return nextIndex;
        });
      }, 3000); // Scroll every 3 seconds
  
      return () => clearInterval(scrollInterval);
    }, [cardWidth, isPaused, recentFixtures]);
  
  
  
  const getRecentFixtures = (fixtures) => {
    const teams = {};
    const displayedFixtures = new Set(); // Track which fixtures we've already displayed
    
    // Sort fixtures by date in descending order
    const sortedFixtures = [...fixtures].sort((a, b) => 
      new Date(b.fixture.date) - new Date(a.fixture.date)
    );
  
    // Get the most recent fixture for each team
    sortedFixtures.forEach(fixture => {
      if (fixture.fixture.status.short === "FT") {
        const homeTeam = fixture.teams.home.name;
        const awayTeam = fixture.teams.away.name;
        const fixtureId = fixture.fixture.id;
  
        // Only process this fixture if we haven't displayed it yet
        if (!displayedFixtures.has(fixtureId)) {
          // If neither team has a fixture displayed yet, use this one
          if (!teams[homeTeam] && !teams[awayTeam]) {
            teams[homeTeam] = fixture;
            teams[awayTeam] = fixture;
            displayedFixtures.add(fixtureId);
          }
          // If only one team needs a fixture, assign it
          else if (!teams[homeTeam]) {
            teams[homeTeam] = fixture;
            displayedFixtures.add(fixtureId);
          }
          else if (!teams[awayTeam]) {
            teams[awayTeam] = fixture;
            displayedFixtures.add(fixtureId);
          }
        }
      }
    });
  
    // Filter out duplicate fixtures when returning
    const uniqueFixtures = {};
    const processedFixtures = new Set();
  
    Object.entries(teams).forEach(([team, fixture]) => {
      if (!processedFixtures.has(fixture.fixture.id)) {
        uniqueFixtures[team] = fixture;
        processedFixtures.add(fixture.fixture.id);
      }
    });
  
    return uniqueFixtures;
  };
  
  useEffect(() => {
    const updateLayout = () => {
      if (!scrollContainerRef.current) return;
    
      // Update visible cards based on screen width
      if (window.innerWidth >= 1200) {
        setVisibleCards(3);
      } else if (window.innerWidth >= 768) {
        setVisibleCards(2);
      } else {
        setVisibleCards(1);
      }
    
      // Calculate card width based on container and visible cards
      const containerWidth = scrollContainerRef.current.offsetWidth;
      const gap = 16; // 1rem gap
      const totalGaps = visibleCards - 1;
      const availableWidth = containerWidth - (gap * totalGaps);
      const newCardWidth = Math.floor(availableWidth / visibleCards);
      setCardWidth(newCardWidth);
    };
  
    updateLayout();
    window.addEventListener('resize', updateLayout);
    return () => window.removeEventListener('resize', updateLayout);
  }, [visibleCards]);
  
  // Modify scroll function to handle boundaries
  const scroll = (direction) => {
    const container = scrollContainerRef.current;
    if (!container) return;
  
    const totalFixtures = Object.keys(recentFixtures).length;
    const maxIndex = Math.ceil(totalFixtures / visibleCards) - 1;
    
    let newIndex;
    if (direction === 'left') {
      newIndex = Math.max(0, currentIndex - 1);
    } else {
      newIndex = Math.min(maxIndex, currentIndex + 1);
    }
    
    setCurrentIndex(newIndex);
    
    const scrollAmount = (cardWidth + 16) * (newIndex * visibleCards);
    container.scrollTo({
      left: scrollAmount,
      behavior: 'smooth'
    });
  };
  


  return (
<div className=''>
  <div className='App-headerV8'>
    <Navbar />
    <h2 className='text-center text-light padtop mb-1' style={{ fontFamily: 'Lato, sans-serif' }}>Serie A {t('standings')}</h2>
    <div className='container text-center mb-2'>
      <i className='text-center text-light' style={{ fontFamily: 'Lato, sans-serif' }}>*{t('click_on_team_name')} </i>
    </div>

    <div className='d-flex justify-content-center'>
  <div className='col-10 col-md-6 col-lg-4'>
    <select
      className='form-select mb-3 text-center'
      style={{
        backgroundColor: 'purple',
        color: 'white',
        textAlign: 'center', // Center the text
        textAlignLast: 'center', // Center the selected option
      }}
      value={season}
      onChange={(e) => setSeason(e.target.value)}
    >
      <option className='text-center' value="2024" style={{ backgroundColor: 'white', color: 'black' }}>
        Serie A 24/25
      </option>
      <option className='text-center' value="2023" style={{ backgroundColor: 'white', color: 'black' }}>
        Serie A 23/24
      </option>
      <option className='text-center' value="2022" style={{ backgroundColor: 'white', color: 'black' }}>
        Serie A 22/23
      </option>
      <option className='text-center' value="2021" style={{ backgroundColor: 'white', color: 'black' }}>
        Serie A 21/22
      </option>
      <option className='text-center' value="2020" style={{ backgroundColor: 'white', color: 'black' }}>
        Serie A 20/21
      </option>
    </select>
  </div>
</div>

    <div className='container'>
      {loading ? (
        <h2 className='text-center text-dark'><Spinner /></h2>
      ) : (
        <div className='table-responsive mx-auto' style={{ maxWidth: '750px' }}>
           <table className="table transparent-table" style={{ backgroundColor: 'transparent' }}>
            <thead>
              <tr>
              <th className='text-light'>{t('position')}</th>
<th className='text-light'>{t('team')}</th>
<th className='text-light'>{t('played')}</th>
<th className='text-light'>{t('won')}</th>
<th className='text-light'>{t('drawn')}</th>
<th className='text-light'>{t('lost')}</th>
<th className='text-light'>{t('points_standings')}</th>
<th scope="col" className="d-none d-lg-table-cell" style={{ width: '15%', color: 'white' }}>{t('last_5_games')}</th>

              </tr>
            </thead>
            <tbody>
              {standings.map((team, index) => (
                <tr key={index}>
                  <th scope="row" className={index < 4 ? 'text-primary' : index >= standings.length - 3 ? 'text-danger' : index === 4 ? 'text-warning' : index === 5 ? 'text-warning' : index === 6 ? 'text-success' : ''} style={{ color: 'white' }}>
                    {index + 1}
                  </th>
                  
                  <td>
                  {getTeamKit(team.team) && (
                      <img
                        src={getTeamKit(team.team)}
                        alt={`${team.team} Kit`}
                        style={{ width: '25px', marginRight: '5px' }}
                      />
                    )}
                    <span onClick={() => openModal(team.team)} style={{ cursor: 'pointer', color: 'white' }}>
                    {team.team === 'Torino' ? (
    <Link  style={{ textDecoration: 'none', color: 'white' }}>
      Torino
    </Link>
  ) : team.team === 'Napoli' ? (
    <Link  style={{ textDecoration: 'none', color: 'white' }}>Napoli</Link>
  ) : team.team === 'Udinese' ? (
    <Link  style={{ textDecoration: 'none', color: 'white' }}>Udinese</Link>
  ) : team.team === 'Juventus' ? (
    <Link  style={{ textDecoration: 'none', color: 'white' }}>Juventus</Link>
  ) : team.team === 'Empoli' ? (
    <Link  style={{ textDecoration: 'none', color: 'white' }}>Empoli</Link>
  ) : team.team === 'Inter' ? (
    <Link  style={{ textDecoration: 'none', color: 'white' }}>Inter</Link>
  ) : team.team === 'AC Milan' ? (
    <Link  style={{ textDecoration: 'none', color: 'white' }}>AC Milan</Link>
  ) : team.team === 'Lazio' ? (
    <Link  style={{ textDecoration: 'none', color: 'white' }}>Lazio</Link>
  ) : team.team === 'AS Roma' ? (
    <Link  style={{ textDecoration: 'none', color: 'white' }}>AS Roma</Link>
  ) : team.team === 'Verona' ? (
    <Link  style={{ textDecoration: 'none', color: 'white' }}>Verona</Link>
  ) : team.team === 'Fiorentina' ? (
    <Link  style={{ textDecoration: 'none', color: 'white' }}>Fiorentina</Link>
  ) : team.team === 'Atalanta' ? (
    <Link  style={{ textDecoration: 'none', color: 'white' }}>Atalanta</Link>
  ) : team.team === 'Bologna' ? (
    <Link  style={{ textDecoration: 'none', color: 'white' }}>Bologna</Link>
  ) : team.team === 'Parma' ? (
    <Link  style={{ textDecoration: 'none', color: 'white' }}>Parma</Link>
  ) : team.team === 'Como' ? (
    <Link  style={{ textDecoration: 'none', color: 'white' }}>Como</Link>
  ) : team.team === 'Genoa' ? (
    <Link to="/genoa" style={{ textDecoration: 'none', color: 'white' }}>Genoa</Link>
  ) : team.team === 'Lecce' ? (
    <Link  style={{ textDecoration: 'none', color: 'white' }}>Lecce</Link>
  ) : team.team === 'Venezia' ? (
    <Link style={{ textDecoration: 'none', color: 'white' }}>Venezia</Link>
  ) : team.team === 'Monza' ? (
    <Link  style={{ textDecoration: 'none', color: 'white' }}>Monza</Link>
  ) : team.team === 'Cagliari' ? (
    <Link style={{ textDecoration: 'none', color: 'white' }}>Cagliari</Link>
  ) : (
    team.team
  )}

                    </span>
                  </td>
                  <td className='text-light'>{team.matchesPlayed}</td>
                  <td className='text-light'>{team.wins}</td>
                  <td className='text-light'>{team.draws}</td>
                  <td className='text-light'>{team.losses}</td>
                  <td className='text-light'>{team.points}</td>
                  <td className="d-none d-lg-table-cell">
                    <div className="d-flex flex-row">
                      {team.lastFive.map((result, idx) => (
                        <span
                          key={idx}
                          className={`badge ${result === 'W' ? 'bg-success' : result === 'D' ? 'bg-secondary' : 'bg-danger'}`}
                          style={{
                            width: '23px',
                            height: '23px',
                            display: 'inline-flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '0.9rem',
                            marginRight: '2px'
                          }}
                        >
                          {result}
                        </span>
                      ))}
                    </div>
                  </td>
                </tr>
              ))}

            </tbody>
          </table>
        </div>
      )}
    </div>

    {/* Legends for qualifications */}
    <div className="container mt-3">
      <div className="d-flex flex-column align-items-center">
        <div className="d-flex align-items-center mb-2" style={{ width: '100%', maxWidth: '400px' }}>
          <div className="badge text-primary me-3" style={{ width: '20px', textAlign: 'center' }}>|</div>
          <div className="text-light text-start flex-grow-1">{t('uefa_champions_league_qualification')}</div>
        </div>
        <div className="d-flex align-items-center mb-2" style={{ width: '100%', maxWidth: '400px' }}>
          <div className="badge text-warning me-3" style={{ width: '20px', textAlign: 'center' }}>|</div>
          <div className="text-light text-start flex-grow-1">{t('europa_league_qualification')}</div>
        </div>
        <div className="d-flex align-items-center mb-2" style={{ width: '100%', maxWidth: '400px' }}>
          <div className="badge text-success me-3" style={{ width: '20px', textAlign: 'center' }}>|</div>
          <div className="text-light text-start flex-grow-1">{t('europa_conference_league_qualification')}</div>
        </div>
        <div className="d-flex align-items-center" style={{ width: '100%', maxWidth: '400px' }}>
          <div className="badge text-danger me-3" style={{ width: '20px', textAlign: 'center' }}>|</div>
          <div className="text-light text-start flex-grow-1">{t('relegation')}</div>
        </div>
      </div>
    </div>

          {/* Modal for displaying team details */}
          {isModalOpen && (
        <div
          className="modal show d-block"
          tabIndex="-1"
          role="dialog"
          style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title ">{selectedTeam}</h5>
                <button type="button" className="btn-close" onClick={closeModal}></button>
              </div>
              {/* Display Team Kit Image */}
              <div className="modal-body">
  <div className="text-center mb-3">
    {teamKits[selectedTeam] && (
      <img
        src={teamKits[selectedTeam]}
        alt={`${selectedTeam} Kit`}
        style={{ maxWidth: '100px', maxHeight: '100px', marginBottom: '15px' }}
      />
    )}
  </div>
  
  {/* Add cards for Total Wins, Home Wins, and Away Wins */}

  <div className="row text-center mb-3">
  <div className="col-12 col-sm-4 mb-3 mb-sm-0">
      <div className="card border-bg-warning text-light" style={{ color: 'green' }}>
        <div className="card-body">
          <h6 className="card-title"style={{ color: 'green' }}>{t('total_wins')} </h6>
          <p className="card-text"style={{ color: 'green' }}>{teamStats ? teamStats.wins : 0}</p>
        </div>
      </div>
    </div>
    <div className="col-12 col-sm-4 mb-3 mb-sm-0">
      <div className="card border-bg-warning text-light" style={{ color: '#f49f05' }}>
        <div className="card-body">
          <h6 className="card-title"style={{ color: '#f49f05' }}>{t('total_draws')}</h6>
          <p className="card-text"style={{ color: '#f49f05' }}>{teamStats ? teamStats.draws : 0}</p>
        </div>
      </div>
    </div>
    <div className="col-12 col-sm-4 mb-3 mb-sm-0">
      <div className="card border-bg-warning text-light" style={{ color: 'red' }}>
        <div className="card-body">
          <h6 className="card-title"style={{ color: 'red' }}>{t('total_losses')}  </h6>
          <p className="card-text"style={{ color: 'red' }}>{teamStats ? teamStats.losses : 0}</p>
        </div>
      </div>
    </div>
  </div>

  {/* <div className="row text-center mb-3">
    <div className="col-12 col-sm-6 mb-3 mb-sm-0">
      <div className="card border-bg-warning text-light" style={{ color: 'blue' }}>
        <div className="card-body">
          <h5 className="card-title"style={{ color: 'blue' }}>{t('home_wins')}</h5>
          <p className="card-text" style={{ color: 'blue' }}>
            {teamStats ? `${teamStats.homeWins}/${teamStats.homeGamesPlayed}` : '0/0'}
          </p>
        </div>
      </div>
    </div>
    <div className="col-12 col-sm-6 mb-3 mb-sm-0">
      <div className="card border-bg-warning ">
        <div className="card-body">
          <h5 className="card-title" style={{ color: 'purple' }}>{t('away_wins')}</h5>
          <p className="card-text" style={{ color: 'purple' }}>
            {teamStats ? `${teamStats.awayWins}/${teamStats.awayGamesPlayed}` : '0/0'}
          </p>
        </div>
      </div>
    </div>
  </div> */}

  <div className="mb-3">
  <h6>{t('last_5_games')}</h6>
  <div className="d-flex justify-content-center">
    {formArray.map((result, index) => (
      <span 
        key={index}
        className="badge mx-1"
        style={{ 
          fontSize: '1.2rem', 
          padding: '0.5rem 1rem', 
          color: 'white', // Ensure contrast
          backgroundColor: getColorForMatch(result) // Dynamically set color
        }}
      >
        {result}
      </span>
    ))}
  </div>
</div>
  {/* Existing cards for Draws and Losses */}
</div>
              <div className="modal-footer">
                {/* <button
                  type="button"
                  className="btn btn-secondary text-light"
                  onClick={() => {
                    closeModal(); // Close the modal
                    if (selectedTeam) {
                      const teamSlug = selectedTeam.toLowerCase().replace(/\s+/g, '');
                      navigate(`/${teamSlug}`);
                    } else {
                      console.error("Selected team is undefined");
                    }
                  }}
                >
                  {t('view_full_stats')}
                </button> */}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* End of modal  */}

     {/* Recent Fixtures Section */}
 <div className='container mt-4'>
  <h3 className='text-center text-light mb-3' style={{ fontFamily: 'Lato, sans-serif' }}>
    {t('recent_fixtures')}
  </h3>

  <div 
    className='position-relative'
    onMouseEnter={() => setIsPaused(true)}
    onMouseLeave={() => setIsPaused(false)}
  >
    <button 
              className='btn btn-outline- text-light position-absolute start-0 top-50 translate-middle-y z-1'
              onClick={() => scroll('left')}
              style={{ 
                opacity: '0.7',
                display: currentIndex === 0 ? 'none' : 'block'
              }}
            >
              <ChevronLeft size={24} />
            </button>

            <button 
              className='btn btn-outline- text-light position-absolute end-0 top-50 translate-middle-y z-1'
              onClick={() => scroll('right')}
              style={{ 
                opacity: '0.7',
                display: currentIndex >= Math.ceil(Object.keys(recentFixtures).length / visibleCards) - 1 ? 'none' : 'block'
              }}
            >
              <ChevronRight size={24} />
            </button>

            <div 
              ref={scrollContainerRef}
              className='d-flex overflow-hidden px-0'
              style={{
                gap: '1rem',
                scrollBehavior: 'smooth',
              }}
            >
   {Object.entries(recentFixtures).map(([team, fixture], index) => {
                if (!fixture) return null;

                const homeTeam = fixture.teams.home.name;
                const awayTeam = fixture.teams.away.name;
                const homeScore = fixture.goals.home;
                const awayScore = fixture.goals.away;
                const date = new Date(fixture.fixture.date).toLocaleDateString();

            return (
              <div
              key={index}
              className='flex-shrink-0'
              style={{
                width: cardWidth ? `${cardWidth}px` : `${100/visibleCards}%`,
                transition: 'transform 0.3s ease-in-out',
              }}
            >
                <div className='card bg-transparent border-light text-light h-100'>
                  <div className='card-body'>
                    {/* Row 1: Home Kit, Score, Score, Away Kit */}
                    <div className='row align-items-center text-center'>
                      <div className='col-3'>
                        <img
                          src={getTeamKit(homeTeam)}
                          alt={`${homeTeam} Kit`}
                          style={{ width: '40px' }}
                        />
                      </div>
                      <div className='col-3'>
                        <span
                          className={`fs-5 px-2 py-1 rounded ${
                            homeScore > awayScore
                              ? 'bg-success text-light'
                              : homeScore < awayScore
                              ? 'bg-danger text-light'
                              : 'bg-secondary text-light'
                          }`}
                        >
                          {homeScore}
                        </span>
                      </div>
                      <div className='col-3'>
                        <span
                          className={`fs-5 px-2 py-1 rounded ${
                            awayScore > homeScore
                              ? 'bg-success text-light'
                              : awayScore < homeScore
                              ? 'bg-danger text-light'
                              : 'bg-secondary text-light'
                          }`}
                        >
                          {awayScore}
                        </span>
                      </div>
                      <div className='col-3'>
                        <img
                          src={getTeamKit(awayTeam)}
                          alt={`${awayTeam} Kit`}
                          style={{ width: '40px' }}
                        />
                      </div>
                    </div>

                    {/* Row 2: Team Names */}
                    <div className='row text-center mt-2'>
                      <div className='col-3'>{renderTeamInitials(homeTeam)}</div>
                      <div className='col-6'></div>
                      <div className='col-3'>{renderTeamInitials(awayTeam)}</div>
                    </div>

                    {/* Date */}
                    <div className='text-center mt-3 small'>{date}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
    {/* End of recent fixtures */}



    <div className='container mt-4' style={{ paddingBottom: '5px' }}>
      <button className="btn btn-outline-light" onClick={handleGoBack}>Go Back</button>
    </div>
  <Footer />
  </div>
</div>


  );
}

export default SerieAStandings;
