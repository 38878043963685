import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Link, NavLink } from 'react-router-dom';
import AuthDetails from './auth/AuthDetails';
import { FaSearch } from 'react-icons/fa';
import { getDatabase, onValue } from 'firebase/database';
import { useParams } from 'react-router-dom';
import { ref } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import Logo from './Favicon.png';
import WhiteLogo from './LogoWhite4.png';
import { useTranslation } from 'react-i18next';
import Flag from 'react-world-flags'

function Navbar() {
  const [bracketData, setBracketData] = useState({});
  const [bracketIdSuggestions, setBracketIdSuggestions] = useState([]);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLanguage(lng); // Set the language state to the selected language
  };

  const [language, setLanguage] = useState('en'); // Default language is English
  

 

  return (
    <div className=''>
      <nav className='navbar navbar-expand-lg navbar-transparent  border-light'>
      <div className='container-fluid'>
          <Link to='/' className='navbar-brand' href='#' style={{ color: '' }}>
            {/* Interstellar Predictions */}
            <img src={WhiteLogo} alt="" width='120' height="35"/>
            {/* Interstellar Predictions */}

          </Link>
          <button
            className='navbar-toggler text-light border-light'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarSupportedContent'
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggler-icon -light' style={{ color: 'white' }}></span>
          </button>
          <div className='collapse navbar-collapse' id='navbarSupportedContent'>
            <ul className='navbar-nav me-auto mb-2 mb-lg-0'>
              <li className='nav-item dropdown text-light'>
                <a
                  className='nav-link dropdown-toggle text-light navhover text-light'
                  href='#'
                  id='navbarDropdown'
                  role='button'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  {t('Huddles')}
                </a>
                <ul className='dropdown-menu' aria-labelledby='navbarDropdown'>
                  <li className='text-light'>
                    <NavLink to='/dashboard' className='dropdown-item  custom-bg-purple'>
                    {t('Dashboard')}

                    </NavLink>
                  </li>
        <li>
          <NavLink to='/globalleaderboard' className='dropdown-item custom-bg-purple'>
          {t('player_leaderboard')}
          </NavLink>
        </li>
                  <hr className='dropdown-divider' />
             
              
                  <li>
                  <NavLink to='/joinhuddle' className='dropdown-item  custom-bg-purple'>
  {t('join_huddle_link')}
</NavLink>

                  </li>
                  <li>
                    <NavLink to='/createhuddle' className='dropdown-item  custom-bg-purple'>
                    {t('create_huddle_link')}
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink to='/faq' className='dropdown-item '>
                      Rules
                    </NavLink>
                  </li> */}
                </ul>
              </li>
              

              <li className='nav-item dropdown'>
                <a
                  className='nav-link dropdown-toggle text-light navhover'
                  href='#'
                  id='navbarDropdown2'
                  role='button'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                    {t('predictions')}

                </a>
                <ul className='dropdown-menu' aria-labelledby='navbarDropdown2 custom-bg-purple'>
                  <li>
                
                    <NavLink to='/premierleague' className='dropdown-item  custom-bg-purple'>
                    {t('EPL')}

                    </NavLink>
                  </li>
                  <li>
                
                <NavLink to='/championshippicks' className='dropdown-item  custom-bg-purple'>
                {t('Championship')}

                </NavLink>
              </li>
                  <li>
                    <NavLink to='/scottishpicks24' className='dropdown-item custom-bg-purple'>
                    {t('SPFL')}

                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/laligapicks' className='dropdown-item custom-bg-purple'>
                    {t('LA_LIGA')}

                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/uclpicks' className='dropdown-item custom-bg-purple'>
                    {t('Champions_League')}

                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/bundesligapicks' className='dropdown-item custom-bg-purple'>
                    {t('Bundesliga')}
 
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/serieapicks' className='dropdown-item custom-bg-purple'>
                    {t('Serie_A')} 
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/sasoccerpicks' className='dropdown-item custom-bg-purple'>
                    {t('Premier_Soccer_League')}
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li className='nav-item dropdown'>
                <a
                  className='nav-link dropdown-toggle text-light navhover'
                  href='#'
                  id='navbarDropdown2'
                  role='button'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                    {t('fixtures')}

                </a>
                <ul className='dropdown-menu' aria-labelledby='navbarDropdown2 custom-bg-purple'>
                  
                  <li>
                    <NavLink to='/fixture' className='dropdown-item  custom-bg-purple'>
                    {t('EPL')}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/championshipfixtures' className='dropdown-item  custom-bg-purple'>
                    {t('Championship')}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/scottishfixtures24' className='dropdown-item custom-bg-purple'>
                    {t('SPFL')}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/laligafixtures' className='dropdown-item custom-bg-purple'>
                    {t('LA_LIGA')}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/uclfixture' className='dropdown-item custom-bg-purple'>
                    {t('Champions_League')}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/bundesligafixtures' className='dropdown-item custom-bg-purple'>
                    {t('Bundesliga')}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/serieafixtures' className='dropdown-item custom-bg-purple'>
                    {t('Serie_A')} 
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/sasoccerfixtures' className='dropdown-item custom-bg-purple'>
                    {t('Premier_Soccer_League')}
                    </NavLink>
                  </li>
              
                </ul>
              </li>


              <li className='nav-item dropdown'>
      <a
        className='nav-link dropdown-toggle text-light navhover'
        href='#'
        id='navbarDropdown2'
        role='button'
        data-bs-toggle='dropdown'
        aria-expanded='false'
      >
          {t('standings')}

      </a>
      <ul className='dropdown-menu' aria-labelledby='navbarDropdown2'>
     
        <li>
          <NavLink to='/standings' className='dropdown-item custom-bg-purple'>
          {t('EPL')}
          </NavLink>
        </li>
        {/* Uncomment and add class if needed */}
        <li>
          <NavLink to='/championshipstandings' className='dropdown-item custom-bg-purple'>
          {t('Championship')}
          </NavLink>
        </li>
        <li>
          <NavLink to='/scottishstandingsoldapi' className='dropdown-item custom-bg-purple'>
          {t('SPFL')}
          </NavLink>
        </li>
        <li>
          <NavLink to='/laligastandings' className='dropdown-item custom-bg-purple'>
          {t('LA_LIGA')}
          </NavLink>
        </li>
        <li>
          <NavLink to='/uclstandings' className='dropdown-item custom-bg-purple'>
          {t('Champions_League')}
          </NavLink>
          <li>
          <NavLink to='/bundesligastandings' className='dropdown-item custom-bg-purple'>
          {t('Bundesliga')}
          </NavLink>
        </li>
        <li>
          <NavLink to='/serieastandings' className='dropdown-item custom-bg-purple'>
          {t('Serie_A')} 
          </NavLink>
        </li>
        <li>
                    <NavLink to='/sasoccerstandings' className='dropdown-item custom-bg-purple'>
                    {t('Premier_Soccer_League')}

                    </NavLink>
                  </li>
        </li>
        
      </ul>
    </li>
              <li className='nav-item text-light navhover'>
                <NavLink to='/news' className='nav-link text-light navhover' href='about'>
                {t('news')}

                </NavLink>
              </li>
              <li className='nav-item text-light'>
                <NavLink to='/social' className='nav-link text-light navhover' href='news'>
                {t('social')}

                </NavLink>
              </li>

            </ul>
            <AuthDetails />       
          <ul></ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
