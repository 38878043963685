import 	Blackburn from '../Team-Kits-Images/Blackburn.png'
import Derby from '../Team-Kits-Images/Derby.png'
import 	Preston from '../Team-Kits-Images/Preston.png'
import SheffieldUnited from '../Team-Kits-Images/SheffieldUnited.png'
import Cardiff from '../Team-Kits-Images/Cardiff.png'
import Sunderland from '../Team-Kits-Images/Sunderland.png'
import 	Hull from '../Team-Kits-Images/Hull.png'
import 	Bristol from '../Team-Kits-Images/Bristol.png'
import 	Portsmouth from '../Team-Kits-Images/Portsmouth.png'
import Middlesbrough	 from '../Team-Kits-Images/Middlesbrough.png'
import Swansea	 from '../Team-Kits-Images/Swansea.png'
import Millwall	 from '../Team-Kits-Images/Millwall.png'
import Watford		 from '../Team-Kits-Images/Watford.png'
import Oxford	 from '../Team-Kits-Images/Oxford.png'
import Norwich	 from '../Team-Kits-Images/Norwich.png'
import QPR	 from '../Team-Kits-Images/QPR.png'
import WestBrom	 from '../Team-Kits-Images/WestBrom.png'
import Stoke	 from '../Team-Kits-Images/Stoke.png'
import Coventry	 from '../Team-Kits-Images/Coventry.png'
import Wednesday	 from '../Team-Kits-Images/Wednesday.png'
import Plymouth	 from '../Team-Kits-Images/Plymouth.png'
import Luton	 from '../Team-Kits-Images/LutonTown.png'
import Burnley	 from '../Team-Kits-Images/Burnley.png'
import Leeds	 from '../Team-Kits-Images/Leeds.png'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useRef,useMemo } from 'react';
import { get, ref, getDatabase, set } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import Navbar from '../Navbar';
import Footer from '../Footer';
 import { Link } from 'react-router-dom'

 function ChampionshipFixtures() {
  const [fixtures, setFixtures] = useState([]);
  const [inputsData, setInputsData] = useState({});
  const auth = getAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const user = auth.currentUser;
  const userId = user ? user.uid : null;
  const intervalRefs = useRef({});
  const [filteredFixtures, setFilteredFixtures] = useState([]);
  const [confetti, setConfetti] = useState(false);
  const [totalPoints, setTotalPoints] = useState(0);
  const { t } = useTranslation();
  const [fixturePoints, setFixturePoints] = useState({});
  const [displayName, setDisplayName] = useState('');
  const [selectedMatchday, setSelectedMatchday] = useState('Round');
  const [startIndex, setStartIndex] = useState(0);
  const containerRef = useRef(null);
  const [buttonWidth, setButtonWidth] = useState(0);
  const [currentRound, setCurrentRound] = useState(8); 
  const [buttonsToShow, setButtonsToShow] = useState(0);
  const dropdownMenuRef = useRef(null);
  const dropdownButtonRef = useRef(null);

  const [dateRanges] = useState([
    { start: '2024-07-03', end: '2024-08-13' },  // Matchweek 1
    { start: '2024-08-13', end: '2024-08-19' },  // Matchweek 2
    { start: '2024-08-19', end: '2024-08-26' },  // Matchweek 3
    { start: '2024-08-26', end: '2024-09-02' },  // Matchweek 4
    { start: '2024-09-02', end: '2024-09-16' },  // Matchweek 5
    { start: '2024-09-16', end: '2024-09-23' },  // Matchweek 6
    { start: '2024-09-23', end: '2024-09-30' },  // Matchweek 7
    { start: '2024-09-30', end: '2024-10-03' },  // Matchweek 8
    { start: '2024-10-03', end: '2024-10-07' },  // Matchweek 9
    { start: '2024-10-07', end: '2024-10-21' },  // Matchweek 10
    { start: '2024-10-21', end: '2024-10-24' },  // Matchweek 11
    { start: '2024-10-24', end: '2024-10-28' },  // Matchweek 12
    { start: '2024-10-28', end: '2024-11-04' },  // Matchweek 13
    { start: '2024-11-04', end: '2024-11-08' },  // Matchweek 14
    { start: '2024-11-08', end: '2024-11-11' },  // Matchweek 15
    { start: '2024-11-11', end: '2024-11-25' },  // Matchweek 16
    { start: '2024-11-25', end: '2024-11-28' },  // Matchweek 17
    { start: '2024-11-28', end: '2024-12-02' },  // Matchweek 18
    { start: '2024-12-02', end: '2024-12-09' },  // Matchweek 19
    { start: '2024-12-09', end: '2024-12-12' },  // Matchweek 20
    { start: '2024-12-12', end: '2024-12-16' },  // Matchweek 21
    { start: '2024-12-16', end: '2024-12-23' },  // Matchweek 22
    { start: '2024-12-23', end: '2024-12-27' },  // Matchweek 23
    { start: '2024-12-27', end: '2024-12-30' },  // Matchweek 24
    { start: '2024-12-30', end: '2025-01-02' },  // Matchweek 25 2025 
    { start: '2025-01-02', end: '2025-01-07' },  // Matchweek 26
    { start: '2025-01-07', end: '2025-01-19' },  // Matchweek 27
    { start: '2025-01-19', end: '2025-01-23' },  // Matchweek 28
    { start: '2025-01-23', end: '2025-01-26' },  // Matchweek 29
    { start: '2025-01-26', end: '2025-02-02' },  // Matchweek 30
    { start: '2025-02-02', end: '2025-02-09' },  // Matchweek 31
    { start: '2025-02-09', end: '2025-02-13' },  // Matchweek 32
    { start: '2025-02-13', end: '2025-02-16' },  // Matchweek 33
    { start: '2025-02-16', end: '2025-02-23' },  // Matchweek 34
    { start: '2025-02-23', end: '2025-03-02' },  // Matchweek 35
    { start: '2025-03-02', end: '2025-03-09' },  // Matchweek 36
    { start: '2025-03-09', end: '2025-03-13' },  // Matchweek 37
    { start: '2025-03-13', end: '2025-03-16' },  // Matchweek 38
    { start: '2025-03-16', end: '2025-03-30' },  // Matchweek 39
    { start: '2025-03-30', end: '2025-04-06' },  // Matchweek 40
    { start: '2025-04-06', end: '2025-04-10' },  // Matchweek 41
    { start: '2025-04-10', end: '2025-04-13' },  // Matchweek 42
    { start: '2025-04-13', end: '2025-04-19' },  // Matchweek 43
    { start: '2025-04-19', end: '2025-04-22' },  // Matchweek 44
    { start: '2025-04-22', end: '2025-04-27' },  // Matchweek 45
    { start: '2025-04-27', end: '2025-05-23' }   // Matchweek 46
]);


useEffect(() => {
  const currentDate = new Date();
  let currentMatchday = 1;
  for (let i = 0; i < dateRanges.length; i++) {
    const startDate = new Date(dateRanges[i].start);
    const endDate = new Date(dateRanges[i].end);
    if (currentDate >= startDate && currentDate <= endDate) {
      currentMatchday = i + 1;
      break;
    }
  }
  setCurrentPage(currentMatchday);
  setSelectedMatchday(`Round ${currentMatchday}`);
}, [dateRanges]);

  useEffect(() => {
    const filteredFixtures = fixtures.filter(fixture => {
      const fixtureDate = new Date(fixture.fixture.date);
      const startDate = new Date(dateRanges[currentPage - 1].start);
      const endDate = new Date(dateRanges[currentPage - 1].end);
      return fixtureDate >= startDate && fixtureDate <= endDate;
    });

    const sortedFixtures = filteredFixtures.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));

    setFilteredFixtures(sortedFixtures);
  }, [currentPage, fixtures, dateRanges]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setSelectedMatchday(`Round ${page}`);
  };
  
  useEffect(() => {
    const fetchFixtures = async () => {
      try {
        const fixturesResponse = await fetch("https://iga-sport.azurewebsites.net/api/league/40/fixtures/season/2024", {
          headers: {
            'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
          }
        });
        const fixturesData = await fixturesResponse.json();
        setFixtures(fixturesData);
      } catch (error) {
        console.error("Error fetching fixtures:", error);
      }
    };

    fetchFixtures();
  }, []);

  const handleMatchdaySelect = (index) => {
    const Round = `Round ${index + 1}`;
    setSelectedMatchday(Round);
    handlePageChange(index + 1);
  };
  
  useEffect(() => {
    if (confetti) {
      const timeout = setTimeout(() => {
        setConfetti(false);
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, [confetti]);
 
  const convertToFormattedDateTime = (dateString) => {
    const options = { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', hour12: false };
    const date = new Date(dateString);
    return date.toLocaleString('en-US', options);
  };
  
  const teamInitials = {
    'Blackburn': 'BLB',
    'Bristol City': 'BRC',
    'Cardiff': 'CAR',
    'Coventry': 'COV',
    'Hull City': 'HUL',
    'Middlesbrough': 'MID',
    'Millwall': 'MIL',
    'Norwich': 'NOR',
    'Plymouth': 'PLY',
    'Portsmouth': 'POR',
    'Preston': 'PRE',
    'QPR': 'QPR',
    'Sheffield Wednesday': 'SHW',
    'Stoke': 'STO',
    'Sunderland': 'SUN',
    'Swansea': 'SWA',
    'Watford': 'WAT',
    'West Brom': 'WBA',
    'Leeds': 'LEE',
    'Derby': 'DER',
    'Sheffield Utd': 'SHU',
    'Oxford United': 'OXF',
    'Luton': 'LUT',
    'Burnley': 'BUR'
};

  
  

  const renderTeamInitials = (teamName) => {
    return teamInitials[teamName] || teamName;
  };

  const teamLogos = {
    'Blackburn': Blackburn,
    'Bristol City': Bristol,
    'Cardiff': Cardiff,
    'Coventry': Coventry,
    'Hull City': Hull,
    'Middlesbrough': Middlesbrough,
    'Millwall': Millwall,
    'Norwich': Norwich,
    'Plymouth': Plymouth,
    'Portsmouth': Portsmouth,
    'Preston': Preston,
    'QPR': QPR,
    'Sheffield Wednesday': Wednesday,
    'Stoke': Stoke,
    'Sunderland': Sunderland,
    'Swansea': Swansea,
    'Watford': Watford,
    'West Brom': WestBrom,
    'Leeds': Leeds,
    'Derby': Derby,
    'Sheffield Utd': SheffieldUnited,
    'Oxford United': Oxford,
    'Stoke City': Stoke,
    'Luton': Luton,
    'Burnley': Burnley,




  };
  const renderTeamLogo = (teamName) => {
  const logo = teamLogos[teamName];
  if (logo) {
    return <img src={logo} alt={teamName} style={{ width: '75px', height: 'auto' }} />;
  }
  return <span style={{ fontStyle: 'normal' }}>{teamName}</span>;
  };

  const stadiums = {
    'Blackburn': `${t('stadium')}: Ewood Park`,
    'Bristol City': `${t('stadium')}: Ashton Gate`,
    'Cardiff': `${t('stadium')}: Cardiff City Stadium`,
    'Coventry': `${t('stadium')}: Coventry Building Society Arena`,
    'Hull City': `${t('stadium')}: MKM Stadium`,
    'Middlesbrough': `${t('stadium')}: Riverside Stadium`,
    'Millwall': `${t('stadium')}: The Den`,
    'Norwich': `${t('stadium')}: Carrow Road`,
    'Plymouth': `${t('stadium')}: Home Park`,
    'Portsmouth': `${t('stadium')}: Fratton Park`,
    'Preston': `${t('stadium')}: Deepdale`,
    'QPR': `${t('stadium')}: Loftus Road`,
    'Sheffield Wednesday': `${t('stadium')}: Hillsborough`,
    'Stoke City': `${t('stadium')}: Bet365 Stadium`,
    'Sunderland': `${t('stadium')}: Stadium of Light`,
    'Swansea': `${t('stadium')}: Swansea.com Stadium`,
    'Watford': `${t('stadium')}: Vicarage Road`,
    'West Brom': `${t('stadium')}: The Hawthorns`,
    'Leeds': `${t('stadium')}: Elland Road`,
    'Derby': `${t('stadium')}: Pride Park`,
    'Sheffield Utd': `${t('stadium')}: Bramall Lane`,
    'Oxford United': `${t('stadium')}: Kassam Stadium`,
    'Luton': `${t('stadium')}: Kenilworth Road`,
    'Burnley': `${t('stadium')}: Turf Moor`,
  };
  
  


  const [selectedMatchdayIndex, setSelectedMatchdayIndex] = useState(0);

  const roundsContainerRef = useRef(null);

  // Handle Previous Button Click
  // Handle Direct Matchday Selection
  const handleMatchdaySelectNew = (index) => {
    setSelectedMatchdayIndex(index);
  };
  
  useEffect(() => {
    const calculateButtonsToShow = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.clientWidth;
        const calculatedButtonsToShow = Math.floor(containerWidth / 125); // Adjust this value based on your design
        setButtonsToShow(Math.min(8, calculatedButtonsToShow));
        calculateStartIndex(currentPage, Math.min(8, calculatedButtonsToShow));
      }
    };

    calculateButtonsToShow();

    // Recalculate on window resize
    window.addEventListener('resize', calculateButtonsToShow);
    return () => {
      window.removeEventListener('resize', calculateButtonsToShow);
    };
  }, [currentPage]);

  const calculateStartIndex = (currentPage, buttonsToShow) => {
    let minIndex = Math.max(0, currentPage - Math.floor(buttonsToShow / 2));
    minIndex = Math.min(minIndex, dateRanges.length - buttonsToShow);
    setStartIndex(minIndex);
  };

  const handleMatchdaySelectSmooth = (index) => {
    setSelectedMatchdayIndex(index);
    handleMatchdaySelect(index);
  };

  const handleLeftArrowClick = () => {
    if (roundsContainerRef.current && startIndex > 0) {
      roundsContainerRef.current.scrollBy({
        left: -((100 / buttonsToShow) + 10), // Move left by the button width + margin
        behavior: 'smooth',
      });
      setStartIndex(startIndex - 1);
    }
  };

  const handleRightArrowClick = () => {
    if (roundsContainerRef.current && startIndex + buttonsToShow < dateRanges.length) {
      roundsContainerRef.current.scrollBy({
        left: (100 / buttonsToShow) + 10, // Move right by the button width + margin
        behavior: 'smooth',
      });
      setStartIndex(startIndex + 1);
    }
  };

  useEffect(() => {
    const currentDate = new Date();
    let currentMatchday = 1;
    for (let i = 0; i < dateRanges.length; i++) {
      const startDate = new Date(dateRanges[i].start);
      const endDate = new Date(dateRanges[i].end);
      if (currentDate >= startDate && currentDate <= endDate) {
        currentMatchday = i + 1;
        break;
      }
    }
    setCurrentPage(currentMatchday);
    setSelectedMatchday(`Round ${currentMatchday}`);
  }, [dateRanges]);

  // Add new effect to handle dropdown button click
  useEffect(() => {
    const handleDropdownOpen = () => {
      if (dropdownMenuRef.current) {
        // Wait for the dropdown to be visible
        setTimeout(() => {
          const currentRoundIndex = parseInt(selectedMatchday.split(' ')[1]) - 1;
          const listItems = dropdownMenuRef.current.querySelectorAll('.list-group-item');
          if (listItems[currentRoundIndex]) {
            listItems[currentRoundIndex].scrollIntoView({
              block: 'center',
              behavior: 'auto'
            });
          }
        }, 0);
      }
    };

    const dropdownButton = dropdownButtonRef.current;
    if (dropdownButton) {
      dropdownButton.addEventListener('click', handleDropdownOpen);
      return () => dropdownButton.removeEventListener('click', handleDropdownOpen);
    }
  }, [selectedMatchday]);

  return (
    <div>
    <div className='App-headerV8 '>
    <Navbar />
      <h2 className='text-light text-center padtop'style={{ fontFamily: 'Lato, sans-serif' }}>Championship {t('fixtures')}
      </h2>
      <div className="text-light text-center" style={{ paddingTop: '10px', paddingBottom: '10px', gap: '5px' }}>
  {/* Mobile Dropdown */}
  <div className="dropdown d-inline-block d-md-none">
            <button
              className="btn btn-purple dropdown-toggle"
              type="button"
              id="groupStageDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="true"
              ref={dropdownButtonRef}
            >
              {selectedMatchday}
            </button>
            <div
              className="dropdown-menu matchweek-dropdown"
              aria-labelledby="groupStageDropdown"
              ref={dropdownMenuRef}
              style={{ maxHeight: '300px', overflowY: 'auto' }}
            >
              <ul className="list-group">
                {dateRanges.map((range, index) => (
                  <li key={index} className="list-group-item">
                    <button
                      className={`dropdown-item ${selectedMatchday === `Round ${index + 1}` ? 'btn-purple text-light' : ''}`}
                      onClick={() => handleMatchdaySelect(index)}
                      style={{ width: '100%' }}
                    >
                      {t('round')} {index + 1}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>

  {/* Horizontal Scroll for Large Screens */}
  <div className="container">
    <div className="d-none d-md-flex align-items-center justify-content-center" style={{ width: '100%', marginLeft: '0rem' }} ref={containerRef}>
      <button className="btn btn-outline-light" onClick={handleLeftArrowClick} disabled={startIndex === 0} style={{ width: '5%', marginRight: '1rem' }}>
        &lt;
      </button>

      <div
        className="rounds-container"
        ref={roundsContainerRef}
        style={{
          display: 'flex',
          overflowX: 'auto',
          whiteSpace: 'nowrap',
          width: '70%', // Set to allow space for buttons
          marginLeft: '1rem',
          scrollBehavior: 'smooth',
        }}
      >
        {dateRanges.slice(startIndex, startIndex + buttonsToShow).map((range, index) => (
          <button
            key={startIndex + index}
            className={`btn me-2 ${selectedMatchday === `Round ${startIndex + index + 1}` ? 'btn-purple' : 'btn-outline-light'}`}
            style={{ width: `calc(${(100 / buttonsToShow)}% - 9px)` }}
            onClick={() => handleMatchdaySelect(startIndex + index)}
          >
            {t('round')} {startIndex + index + 1}
          </button>
        ))}
      </div>

      <button className="btn btn-outline-light" onClick={handleRightArrowClick} disabled={startIndex + buttonsToShow >= dateRanges.length} style={{ width: '5%', marginLeft: '1rem' }}>
        &gt;
      </button>
    </div>
  </div>
</div>

      <div className=' '>
        <div className='row text-center justify-content-center mx-auto bg-transparent text-light'>        
          <div className=' col-lg-6  text-center justify-content-center bg-transparent text-light'>
            {filteredFixtures.map(fixture => {
              // Get the predicted scores from user input
              const predictedHomeGoals = inputsData[fixture.fixture.id]?.home;
              const predictedAwayGoals = inputsData[fixture.fixture.id]?.away;
              const actualHomeGoals = fixture.goals.home;
              const actualAwayGoals = fixture.goals.away;
              let points = 0;
              // Check if the user made predictions and the match is finished
              if (predictedHomeGoals !== undefined && predictedAwayGoals !== undefined && fixture.fixture.status.short === 'FT') {
                if (predictedHomeGoals === actualHomeGoals && predictedAwayGoals === actualAwayGoals) {
                  points = 3; // Exact score
                } else if (
                  (predictedHomeGoals > predictedAwayGoals && actualHomeGoals > actualAwayGoals) ||
                  (predictedHomeGoals < predictedAwayGoals && actualHomeGoals < actualAwayGoals) ||
                  (predictedHomeGoals === predictedAwayGoals && actualHomeGoals === actualAwayGoals)
                ) {
                  points = 1; // Correct outcome but wrong score
                }
              }
              // Store points in the inputsData state
              if (!inputsData[fixture.fixture.id]) {
                inputsData[fixture.fixture.id] = {};
              }
              inputsData[fixture.fixture.id].points = points;
  
              return (
                <div key={fixture.fixture.id} className='my-3 card border-1 bg-transparent text-light border-light'>
                <div className='card-body text-center mb-1'>
                  <h5 className='card-title mb-3'>{fixture.teams.home.name} vs {fixture.teams.away.name}</h5>
                  
                  {/* Conditional rendering for the stadium name */}
                  {stadiums[fixture.teams.home.name] && (
      <h6 className='card-title mb-3'>{stadiums[fixture.teams.home.name]}</h6>
    )}
              
                  <div className="row align-items-center">
                    <div className="col text-center d-flex flex-column align-items-center">
                      {renderTeamLogo(fixture.teams.home.name)}
                      <h5>{renderTeamInitials(fixture.teams.home.name)}</h5>
                    </div>
                                  {/* Input */}
                    <>
<div className="col padtop1 ">
<input
  className="form-control text-center fs-3 text-light"
  style={{ backgroundColor: 'transparent', borderColor: '#9370DB	', borderWidth: '2px' }}
  value={fixture.fixture.status.short === 'FT' ? fixture.score.fulltime.home : 'H'}
  disabled
/>
</div>
<div className="col padtop1">
<input
  className="form-control text-center fs-3 text-light"
  style={{ backgroundColor: 'transparent', borderColor: '#9370DB	', borderWidth: '2px' }}
  value={fixture.fixture.status.short === 'FT' ? fixture.score.fulltime.away : 'A'}
  disabled
/>
</div>
</>
              {/* Input */}
                    <div className="col text-center d-flex flex-column align-items-center">
                      {renderTeamLogo(fixture.teams.away.name)}
                      <h5>{renderTeamInitials(fixture.teams.away.name)}</h5>
                    </div>
                  </div>
                  {fixture.fixture.status.short !== 'NS' && fixture.fixture.status.short !== 'FT' && (
  <div className="text-success">
    Live - {fixture.fixture.status.elapsed}'
  </div>
)}
                </div>
                <div className='text-dark col-6 col-lg-3 mx-auto text-center text-light' 
 style={{ 
   backgroundColor: '#9370DB', 
   borderRadius: '5px', 
   borderBottomLeftRadius: '0px',  // Set bottom-left corner radius to 0
   borderBottomRightRadius: '0px'  // Set bottom-right corner radius to 0
 }}
>
<p className="text-center mb-2 btn-sm text-bold" 
 style={{ 
   color: '#fff', 
   paddingTop: '4px',  
   borderTopLeftRadius: '1px' 
 }}
>
{t('kick_off')} {convertToFormattedDateTime(fixture.fixture.date)}
</p>
</div>
                {fixture.fixture.status.short === 'NS' && (
<Link
    to="/championshippicks"
    className="btn btn-success"
    style={{
        position: 'absolute',
        bottom: '0',           // Flush with the bottom edge
        right: '0',            // Flush with the right edge
        padding: '8px 12px',  // Adjust padding for button size
        backgroundColor: 'green',
        color: 'white',
        textDecoration: 'none',
        fontSize: '0.9rem',    // Adjust the font size as needed
        fontWeight: 'bold',
        borderRadius: '0',     // Ensure no border-radius on other corners
        borderTopLeftRadius: '10px',  // 10px border radius only on top-left corner
        borderBottomRightRadius: '5px',  // 10px border radius only on top-left corner
        margin: '0',           // Ensure no extra margins
        border: '3px',        // No borders
        width: 'auto',         // Adjust width as needed
        height: 'auto'         // Adjust height as needed
    }}
>
{t('predict')}

</Link>
)}
              </div>
              );
            })}
          </div>         
        </div>
      </div>

    <Footer />
    </div>
  </div>
  );
}

export default ChampionshipFixtures;
