import React, { useEffect, useState } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import 	Blackburn from '../Team-Kits-Images/Blackburn.png'
import Derby from '../Team-Kits-Images/Derby.png'
import 	Preston from '../Team-Kits-Images/Preston.png'
import SheffieldUnited from '../Team-Kits-Images/SheffieldUnited.png'
import Cardiff from '../Team-Kits-Images/Cardiff.png'
import Sunderland from '../Team-Kits-Images/Sunderland.png'
import 	Hull from '../Team-Kits-Images/Hull.png'
import 	Bristol from '../Team-Kits-Images/Bristol.png'
import 	Portsmouth from '../Team-Kits-Images/Portsmouth.png'
import Middlesbrough	 from '../Team-Kits-Images/Middlesbrough.png'
import Swansea	 from '../Team-Kits-Images/Swansea.png'
import Millwall	 from '../Team-Kits-Images/Millwall.png'
import Watford		 from '../Team-Kits-Images/Watford.png'
import Oxford	 from '../Team-Kits-Images/Oxford.png'
import Norwich	 from '../Team-Kits-Images/Norwich.png'
import QPR	 from '../Team-Kits-Images/QPR.png'
import WestBrom	 from '../Team-Kits-Images/WestBrom.png'
import Stoke	 from '../Team-Kits-Images/Stoke.png'
import Coventry	 from '../Team-Kits-Images/Coventry.png'
import Wednesday	 from '../Team-Kits-Images/Wednesday.png'
import Plymouth	 from '../Team-Kits-Images/Plymouth.png'
import Luton	 from '../Team-Kits-Images/LutonTown.png'
import Burnley	 from '../Team-Kits-Images/Burnley.png'
import Leeds	 from '../Team-Kits-Images/Leeds.png'
import ArsenalLogo from '../Team-Kits-Images/ArsenalLogo.png'
import { useTranslation } from 'react-i18next';
import { ChevronLeft, ChevronRight } from 'lucide-react';

function ChampionshipStandings() {
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [season, setSeason] = useState('2024'); // Default season
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [recentFixtures, setRecentFixtures] = useState({});
  const scrollContainerRef = useRef(null);
  const [cardWidth, setCardWidth] = useState(0);
  const [visibleCards, setVisibleCards] = useState(3);
  const [isPaused, setIsPaused] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const scrollWidth = cardWidth + 16;

  const fetchStandings = async (season) => {
    setLoading(true);
    const response = await fetch(`https://iga-sport.azurewebsites.net/api/league/40/fixtures/season/${season}`, {
      headers: {
        'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
      }
    });

    const data = await response.json();
    const updatedStandings = calculateStandings(data);
    const latestFixtures = getRecentFixtures(data);
    setRecentFixtures(latestFixtures);
    setStandings(updatedStandings);
    setLoading(false);
  };

  useEffect(() => {
    fetchStandings(season);
  }, [season]);

  const calculateStandings = (fixtures) => {
    const standingsMap = {};
  
    // Initialize standingsMap with teams and 0 points
    fixtures.forEach((fixture) => {
      standingsMap[fixture.teams.home.name] = {
        points: 0, wins: 0, draws: 0, losses: 0, matchesPlayed: 0, lastFive: []
      };
      standingsMap[fixture.teams.away.name] = {
        points: 0, wins: 0, draws: 0, losses: 0, matchesPlayed: 0, lastFive: []
      };
    });
  
    // Calculate points and results for each played fixture
    fixtures.forEach((fixture) => {
      const status = fixture.fixture.status.short;
      if (status !== "FT") {
        return; // Skip fixtures that have not been played yet
      }
  
      const homeTeam = fixture.teams.home.name;
      const awayTeam = fixture.teams.away.name;
      const homeGoals = fixture.goals.home;
      const awayGoals = fixture.goals.away;
  
      let homeResult = "";
      let awayResult = "";
  
      if (homeGoals > awayGoals) {
        homeResult = "W";
        awayResult = "L";
        standingsMap[homeTeam].wins += 1;
        standingsMap[awayTeam].losses += 1;
      } else if (homeGoals < awayGoals) {
        homeResult = "L";
        awayResult = "W";
        standingsMap[homeTeam].losses += 1;
        standingsMap[awayTeam].wins += 1;
      } else {
        homeResult = "D";
        awayResult = "D";
        standingsMap[homeTeam].draws += 1;
        standingsMap[awayTeam].draws += 1;
      }
  
      standingsMap[homeTeam].points += homeResult === "W" ? 3 : homeResult === "D" ? 1 : 0;
      standingsMap[awayTeam].points += awayResult === "W" ? 3 : awayResult === "D" ? 1 : 0;
      standingsMap[homeTeam].matchesPlayed += 1;
      standingsMap[awayTeam].matchesPlayed += 1;
  
      // Update lastFive results (most recent on the right)
      standingsMap[homeTeam].lastFive = [...standingsMap[homeTeam].lastFive, homeResult].slice(-5);
      standingsMap[awayTeam].lastFive = [...standingsMap[awayTeam].lastFive, awayResult].slice(-5);
    });
  
    // Convert standings map to array
    return Object.entries(standingsMap)
      .map(([team, stats]) => ({ team, ...stats }))
      .sort((a, b) => b.points - a.points); // Sort by points in descending order
  };
  
  

  const handleGoBack = () => {
    navigate(-1); // Navigate back one step in the history stack
  };


  const getTeamKit = (team) => {
    switch (team) {
      case 'Blackburn':
        return Blackburn;
      case 'Derby':
        return Derby;
      case 'Preston':
        return Preston;
      case 'Sheffield Utd':
        return SheffieldUnited;
      case 'Cardiff':
        return Cardiff;
      case 'Sunderland':
        return Sunderland;
      case 'Hull City':
        return Hull;
      case 'Bristol City':
        return Bristol;
      case 'Portsmouth':
        return Portsmouth;
      case 'Middlesbrough':
        return Middlesbrough;
      case 'Swansea':
        return Swansea;
      case 'Millwall':
        return Millwall;
      case 'Watford':
        return Watford;
      case 'Oxford United':
        return Oxford;
      case 'Norwich':
        return Norwich;
      case 'QPR':
        return QPR;
      case 'West Brom':
        return WestBrom;
      case 'Stoke City':
        return Stoke;
      case 'Coventry':
        return Coventry;
      case 'Sheffield Wednesday':
        return Wednesday;
      case 'Plymouth':
        return Plymouth;
      case 'Luton':
        return Luton;
      case 'Burnley':
        return Burnley;
      case 'Leeds':
        return Leeds;
      default:
        return null;
    }
};

const teamInitials = {
  'Blackburn': 'BLB',
  'Bristol City': 'BRC',
  'Cardiff': 'CAR',
  'Coventry': 'COV',
  'Hull City': 'HUL',
  'Middlesbrough': 'MID',
  'Millwall': 'MIL',
  'Norwich': 'NOR',
  'Plymouth': 'PLY',
  'Portsmouth': 'POR',
  'Preston': 'PRE',
  'QPR': 'QPR',
  'Sheffield Wednesday': 'SHW',
  'Stoke': 'STO',
  'Sunderland': 'SUN',
  'Swansea': 'SWA',
  'Watford': 'WAT',
  'West Brom': 'WBA',
  'Leeds': 'LEE',
  'Derby': 'DER',
  'Sheffield Utd': 'SHU',
  'Oxford United': 'OXF',
  'Luton': 'LUT',
  'Burnley': 'BUR'
};


const renderTeamInitials = (teamName) => {
  return teamInitials[teamName] || teamName;
};

useEffect(() => {
  const updateLayout = () => {
    if (!scrollContainerRef.current) return;
  
    if (window.innerWidth >= 1200) {
      setVisibleCards(3);
    } else if (window.innerWidth >= 768) {
      setVisibleCards(2);
    } else {
      setVisibleCards(1);
    }
  
    const containerWidth = scrollContainerRef.current.offsetWidth - 32; // Adjust for padding
    const gap = 16;
    const newCardWidth = Math.floor((containerWidth - (gap * (visibleCards - 1))) / visibleCards);
    setCardWidth(newCardWidth);
  };

  updateLayout();
  window.addEventListener('resize', updateLayout);
  return () => window.removeEventListener('resize', updateLayout);
}, [visibleCards]);

// Auto-scroll functionality
useEffect(() => {
  if (!scrollContainerRef.current || isPaused) return;

  const totalFixtures = Object.keys(recentFixtures).length;
  const scrollInterval = setInterval(() => {
    setCurrentIndex((prevIndex) => {
      const nextIndex = (prevIndex + 1) % totalFixtures;
      const scrollAmount = (cardWidth + 16) * nextIndex;
      
      scrollContainerRef.current?.scrollTo({
        left: scrollAmount,
        behavior: 'smooth'
      });
      
      return nextIndex;
    });
  }, 3000); // Scroll every 3 seconds

  return () => clearInterval(scrollInterval);
}, [cardWidth, isPaused, recentFixtures]);



const getRecentFixtures = (fixtures) => {
const teams = {};
const displayedFixtures = new Set(); // Track which fixtures we've already displayed

// Sort fixtures by date in descending order
const sortedFixtures = [...fixtures].sort((a, b) => 
  new Date(b.fixture.date) - new Date(a.fixture.date)
);

// Get the most recent fixture for each team
sortedFixtures.forEach(fixture => {
  if (fixture.fixture.status.short === "FT") {
    const homeTeam = fixture.teams.home.name;
    const awayTeam = fixture.teams.away.name;
    const fixtureId = fixture.fixture.id;

    // Only process this fixture if we haven't displayed it yet
    if (!displayedFixtures.has(fixtureId)) {
      // If neither team has a fixture displayed yet, use this one
      if (!teams[homeTeam] && !teams[awayTeam]) {
        teams[homeTeam] = fixture;
        teams[awayTeam] = fixture;
        displayedFixtures.add(fixtureId);
      }
      // If only one team needs a fixture, assign it
      else if (!teams[homeTeam]) {
        teams[homeTeam] = fixture;
        displayedFixtures.add(fixtureId);
      }
      else if (!teams[awayTeam]) {
        teams[awayTeam] = fixture;
        displayedFixtures.add(fixtureId);
      }
    }
  }
});

// Filter out duplicate fixtures when returning
const uniqueFixtures = {};
const processedFixtures = new Set();

Object.entries(teams).forEach(([team, fixture]) => {
  if (!processedFixtures.has(fixture.fixture.id)) {
    uniqueFixtures[team] = fixture;
    processedFixtures.add(fixture.fixture.id);
  }
});

return uniqueFixtures;
};

useEffect(() => {
const updateLayout = () => {
  if (!scrollContainerRef.current) return;

  // Update visible cards based on screen width
  if (window.innerWidth >= 1200) {
    setVisibleCards(3);
  } else if (window.innerWidth >= 768) {
    setVisibleCards(2);
  } else {
    setVisibleCards(1);
  }

  // Calculate card width based on container and visible cards
  const containerWidth = scrollContainerRef.current.offsetWidth;
  const gap = 16; // 1rem gap
  const totalGaps = visibleCards - 1;
  const availableWidth = containerWidth - (gap * totalGaps);
  const newCardWidth = Math.floor(availableWidth / visibleCards);
  setCardWidth(newCardWidth);
};

updateLayout();
window.addEventListener('resize', updateLayout);
return () => window.removeEventListener('resize', updateLayout);
}, [visibleCards]);

// Modify scroll function to handle boundaries
const scroll = (direction) => {
const container = scrollContainerRef.current;
if (!container) return;

const totalFixtures = Object.keys(recentFixtures).length;
const maxIndex = Math.ceil(totalFixtures / visibleCards) - 1;

let newIndex;
if (direction === 'left') {
  newIndex = Math.max(0, currentIndex - 1);
} else {
  newIndex = Math.min(maxIndex, currentIndex + 1);
}

setCurrentIndex(newIndex);

const scrollAmount = (cardWidth + 16) * (newIndex * visibleCards);
container.scrollTo({
  left: scrollAmount,
  behavior: 'smooth'
});
};



  return (
<div className=''>
  <div className='App-headerV8'>
    <Navbar />
    <h2 className='text-center text-light padtop mb-1' style={{ fontFamily: 'Lato, sans-serif' }}>Championship {t('standings')}</h2>
    <div className='container text-center mb-2'>
      <i className='text-center text-light' style={{ fontFamily: 'Lato, sans-serif' }}>*{t('click_on_team_name')} </i>
    </div>

    <div className='d-flex justify-content-center'>
  <div className='col-10 col-md-6 col-lg-4'>
    <select
      className='form-select mb-3 text-center'
      style={{
        backgroundColor: 'purple',
        color: 'white',
        textAlign: 'center', // Center the text
        textAlignLast: 'center', // Center the selected option
      }}
      value={season}
      onChange={(e) => setSeason(e.target.value)}
    >
      <option className='text-center' value="2024" style={{ backgroundColor: 'white', color: 'black' }}>
        Championship 24/25
      </option>
      <option className='text-center' value="2023" style={{ backgroundColor: 'white', color: 'black' }}>
        Championship 23/24
      </option>
      <option className='text-center' value="2022" style={{ backgroundColor: 'white', color: 'black' }}>
        Championship 22/23
      </option>
      <option className='text-center' value="2021" style={{ backgroundColor: 'white', color: 'black' }}>
        Championship 21/22
      </option>
      <option className='text-center' value="2020" style={{ backgroundColor: 'white', color: 'black' }}>
        Championship 20/21
      </option>
    </select>
  </div>
</div>

    <div className='container'>
      {loading ? (
        <h2 className='text-center text-dark'><Spinner /></h2>
      ) : (
        <div className='table-responsive mx-auto' style={{ maxWidth: '750px' }}>
          <table
            className="table transparent-table"
            style={{
              borderRadius: '15px',
              overflow: 'hidden',
            }}
          >
            <thead>
              <tr>
              <th scope="col" style={{ width: '5%', color: 'white' }}>{t('position')}</th>
                <th scope="col" className="team-column text-light">{t('team')}</th>
                <th scope="col" style={{ width: '5%', color: 'white' }}>{t('played')}</th>
                <th scope="col" style={{ width: '5%', color: 'white' }}>{t('won')}</th>
                <th scope="col" style={{ width: '5%', color: 'white' }}>{t('drawn')}</th>
                <th scope="col" style={{ width: '5%', color: 'white' }}>{t('lost')}</th>
                <th scope="col" style={{ width: '5%', color: 'white' }}>{t('points_standings')}</th>
                <th scope="col" className="d-none d-lg-table-cell" style={{ width: '15%', color: 'white' }}>{t('last_5_games')}</th>
              </tr>
            </thead>
            <tbody>
              {standings.map((team, index) => (
                <tr key={index}>
                                     <th scope="row" className={index < 2 ? 'text-primary' : index >= standings.length - 3 ? 'text-danger' : index == 2 ? 'text-warning' :  index == 3 ? 'text-warning' : index == 4 ? 'text-warning' : index == 5 ? 'text-warning' :  ''} style={{ backgroundColor: '', color: 'white' }}>{index + 1}</th>

                  <td style={{ color: 'white' }}>
                    {getTeamKit(team.team) && (
                      <img
                        src={getTeamKit(team.team)}
                        alt={`${team.team} Kit`}
                        style={{ width: '25px', marginRight: '5px' }}
                      />
                    )}
                     {team.team === 'Manchester United' ? (
                        <Link to="/manunited" style={{ textDecoration: 'none', color: 'white' }}>
                          Man Utd
                        </Link>
                      ) : team.team === 'Blackburn' ? (
                        <Link to="/Blackburn" style={{ textDecoration: 'none', color: 'white' }}>Blackburn</Link>
                      ) : team.team === 'Bristol City' ? (
                        <Link to="/bristol" style={{ textDecoration: 'none', color: 'white' }}>Bristol</Link>
                      ) : team.team === 'Burnley' ? (
                        <Link to="/Burnley" style={{ textDecoration: 'none', color: 'white' }}>Burnley</Link>
                      ) : team.team === 'Cardiff' ? (
                        <Link to="/cardiff" style={{ textDecoration: 'none', color: 'white' }}>Cardiff</Link>
                      ) : team.team === 'Coventry' ? (
                        <Link to="/coventry" style={{ textDecoration: 'none', color: 'white' }}>Coventry</Link>
                      ) : team.team === 'Derby' ? (
                        <Link to="/Derby" style={{ textDecoration: 'none', color: 'white' }}>Derby </Link>
                      ) : team.team === 'Hull City' ? (
                        <Link to="/hullcity" style={{ textDecoration: 'none', color: 'white' }}>Hull</Link>
                      ) : team.team === 'Leeds' ? (
                        <Link to="/leeds" style={{ textDecoration: 'none', color: 'white' }}>Leeds</Link>
                      ) : team.team === 'Luton' ? (
                        <Link to="/luton" style={{ textDecoration: 'none', color: 'white' }}>Luton</Link>
                      ) : team.team === 'Middlesbrough' ? (
                        <Link to="/middlesbrough" style={{ textDecoration: 'none', color: 'white' }}>Middlesb...</Link>
                      ) : team.team === 'Millwall' ? (
                        <Link to="/millwall" style={{ textDecoration: 'none', color: 'white' }}>Millwall</Link>
                      ) : team.team === 'Norwich' ? (
                        <Link to="/Norwich" style={{ textDecoration: 'none', color: 'white' }}>Norwich</Link>
                      ) : team.team === 'Oxford United' ? (
                        <Link to="/oxfordunited " style={{ textDecoration: 'none', color: 'white' }}>Oxford </Link>
                      ) : team.team === 'Plymouth' ? (
                        <Link to="/Plymouth" style={{ textDecoration: 'none', color: 'white' }}>Plymouth</Link>
                      ) : team.team === 'Portsmouth' ? (
                        <Link to="/Portsmouth" style={{ textDecoration: 'none', color: 'white' }}>Portsmouth</Link>
                      ) : team.team === 'Preston' ? (
                        <Link to="/preston" style={{ textDecoration: 'none', color: 'white' }}>Preston</Link>
                      ) : team.team === 'QPR' ? (
                        <Link to="/QPR" style={{ textDecoration: 'none', color: 'white' }}>QPR</Link>
                      ) : team.team === 'Sunderland' ? (
                        <Link to="/Sunderland" style={{ textDecoration: 'none', color: 'white' }}>Sunderland</Link>
                      ) : team.team === 'Sheffield Wednesday' ? (
                        <Link to="/sheffieldwednesday" style={{ textDecoration: 'none', color: 'white' }}>Sheffield Wed</Link>
                      ) : team.team === 'Stoke City' ? (
                        <Link to="/Stokecity" style={{ textDecoration: 'none', color: 'white' }}>Stoke</Link>
                      ) : team.team === 'Swansea' ? (
                        <Link to="/Swansea" style={{ textDecoration: 'none', color: 'white' }}>Swansea</Link>
                      ) : team.team === 'Watford' ? (
                        <Link to="/Watford" style={{ textDecoration: 'none', color: 'white' }}>Watford</Link>
                      ) :  team.team === 'West Brom' ? (
                        <Link to="/WestBrom" style={{ textDecoration: 'none', color: 'white' }}>West Brom</Link>
                      ) :  team.team === 'Sheffield Utd' ? (
                        <Link to="/SheffieldUtd" style={{ textDecoration: 'none', color: 'white' }}>Sheffield Utd</Link>
                     ) : (
                        team.team
                      )}
                  </td>
                  <td style={{ color: 'white' }}>{team.matchesPlayed}</td>
                  <td style={{ color: 'white' }}>{team.wins}</td>
                  <td style={{ color: 'white' }}>{team.draws}</td>
                  <td style={{ color: 'white' }}>{team.losses}</td>
                  <td style={{ color: 'white' }}>{team.points}</td>
                  <td className="d-none d-lg-table-cell">
                    <div className="d-flex flex-row">
                      {team.lastFive.map((result, idx) => (
                        <span
                          key={idx}
                          className={`badge ${result === 'W' ? 'bg-success' : result === 'D' ? 'bg-secondary' : 'bg-danger'}`}
                          style={{
                            width: '23px',
                            height: '23px',
                            display: 'inline-flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '0.9rem',
                            marginRight: '2px'
                          }}
                        >
                          {result}
                        </span>
                      ))}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>

    {/* Legends for qualifications */}
    <div className="container d-flex justify-content-center align-items-center">
  <div className="d-flex flex-column align-items-center">
    <div className="d-flex align-items-center mb-2" style={{ width: '100%', maxWidth: '400px' }}>
      <div className="badge text-primary me-3" style={{ width: '20px', textAlign: 'center' }}>|</div>
      <div className="text-light text-start flex-grow-1">{t('promotion')}</div>
    </div>
    <div className="d-flex align-items-center mb-2" style={{ width: '100%', maxWidth: '400px' }}>
      <div className="badge text-warning me-3" style={{ width: '20px', textAlign: 'center' }}>|</div>
      <div className="text-light text-start flex-grow-1">{t('play_off')}</div>
    </div>
    <div className="d-flex align-items-center" style={{ width: '100%', maxWidth: '400px' }}>
      <div className="badge text-danger me-3" style={{ width: '20px', textAlign: 'center' }}>|</div>
      <div className="text-light text-start flex-grow-1">{t('relegation')}</div>
    </div>
  </div>
</div>

 {/* Recent Fixtures Section */}
 <div className='container mt-4'>
  <h3 className='text-center text-light mb-3' style={{ fontFamily: 'Lato, sans-serif' }}>
    {t('recent_fixtures')}
  </h3>

  <div 
    className='position-relative'
    onMouseEnter={() => setIsPaused(true)}
    onMouseLeave={() => setIsPaused(false)}
  >
    <button 
              className='btn btn-outline- text-light position-absolute start-0 top-50 translate-middle-y z-1'
              onClick={() => scroll('left')}
              style={{ 
                opacity: '0.7',
                display: currentIndex === 0 ? 'none' : 'block'
              }}
            >
              <ChevronLeft size={24} />
            </button>

            <button 
              className='btn btn-outline- text-light position-absolute end-0 top-50 translate-middle-y z-1'
              onClick={() => scroll('right')}
              style={{ 
                opacity: '0.7',
                display: currentIndex >= Math.ceil(Object.keys(recentFixtures).length / visibleCards) - 1 ? 'none' : 'block'
              }}
            >
              <ChevronRight size={24} />
            </button>

            <div 
              ref={scrollContainerRef}
              className='d-flex overflow-hidden px-0'
              style={{
                gap: '1rem',
                scrollBehavior: 'smooth',
              }}
            >
   {Object.entries(recentFixtures).map(([team, fixture], index) => {
                if (!fixture) return null;

                const homeTeam = fixture.teams.home.name;
                const awayTeam = fixture.teams.away.name;
                const homeScore = fixture.goals.home;
                const awayScore = fixture.goals.away;
                const date = new Date(fixture.fixture.date).toLocaleDateString();

            return (
              <div
              key={index}
              className='flex-shrink-0'
              style={{
                width: cardWidth ? `${cardWidth}px` : `${100/visibleCards}%`,
                transition: 'transform 0.3s ease-in-out',
              }}
            >
                <div className='card bg-transparent border-light text-light h-100'>
                  <div className='card-body'>
                    {/* Row 1: Home Kit, Score, Score, Away Kit */}
                    <div className='row align-items-center text-center'>
                      <div className='col-3'>
                        <img
                          src={getTeamKit(homeTeam)}
                          alt={`${homeTeam} Kit`}
                          style={{ width: '40px' }}
                        />
                      </div>
                      <div className='col-3'>
                        <span
                          className={`fs-5 px-2 py-1 rounded ${
                            homeScore > awayScore
                              ? 'bg-success text-light'
                              : homeScore < awayScore
                              ? 'bg-danger text-light'
                              : 'bg-secondary text-light'
                          }`}
                        >
                          {homeScore}
                        </span>
                      </div>
                      <div className='col-3'>
                        <span
                          className={`fs-5 px-2 py-1 rounded ${
                            awayScore > homeScore
                              ? 'bg-success text-light'
                              : awayScore < homeScore
                              ? 'bg-danger text-light'
                              : 'bg-secondary text-light'
                          }`}
                        >
                          {awayScore}
                        </span>
                      </div>
                      <div className='col-3'>
                        <img
                          src={getTeamKit(awayTeam)}
                          alt={`${awayTeam} Kit`}
                          style={{ width: '40px' }}
                        />
                      </div>
                    </div>

                    {/* Row 2: Team Names */}
                    <div className='row text-center mt-2'>
                      <div className='col-3'>{renderTeamInitials(homeTeam)}</div>
                      <div className='col-6'></div>
                      <div className='col-3'>{renderTeamInitials(awayTeam)}</div>
                    </div>

                    {/* Date */}
                    <div className='text-center mt-3 small'>{date}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
    {/* End of recent fixtures */}


    <div className='container mt-4' style={{ paddingBottom: '5px' }}>
      <button className="btn btn-outline-light" onClick={handleGoBack}>Go Back</button>
    </div>
  <Footer />
  </div>
</div>


  );
}

export default ChampionshipStandings;
