import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useLocation } from 'react-router-dom';

function Sidebar() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation(); // Get the current location

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      {/* Hamburger Menu for Small Screens */}
      <button
        className="btn btn-outline-primary d-md-none"
        onClick={toggleSidebar}
        aria-expanded={isOpen}
        aria-controls="sidebar"
      >
        ☰
      </button>

      {/* Sidebar */}
      <nav
        id="sidebar"
        className={`d-flex flex-column bg-light vh-100 position-fixed top-0 start-0 ${
          isOpen || window.innerWidth >= 768 ? 'translate-none' : 'start-100'
        }`}
        style={{
          width: '250px',
          zIndex: 20,
          transition: 'transform 0.3s ease',
        }}
      >
        <div className="d-flex justify-content-between align-items-center px-3 py-2 border-bottom">
          <span className="fs-5 fw-semibold">Admin Panel</span>
          <button
            className="btn btn-sm btn-outline-secondary d-md-none"
            onClick={toggleSidebar}
          >
            ×
          </button>
        </div>

        <ul className="nav flex-column">
          <li className="nav-item">
          <Link 
            to="/admin/dashboard" 
            className={`nav-link ${location.pathname === '/admin/dashboard' ? 'active' : 'link-dark'}`} 

            aria-current="page"
          >
            Dashboard
          </Link>
          </li>
          <li className="nav-item">
          <Link 
            to="/admin/users" 
            className={`nav-link ${location.pathname === '/admin/users' ? 'active' : 'link-dark'}`}
          >
            Users
          </Link>
          </li>
          <li className="nav-item">
          <Link 
            to="/createarticle" 
            className={`nav-link ${location.pathname === '/createarticle' ? 'active' : 'link-dark'}`}
          >
            Create Article
          </Link>
          </li>
          <li>
          <Link 
            to="/admin/settings" 
            className={`nav-link ${location.pathname === '/admin/settings' ? 'active' : 'link-dark'}`}
          >
            Settings
          </Link>
        </li>
 
            <li>
          <Link 
            to="/" 
            className="nav-link link-dark"
          >
            Exit
          </Link>
        </li>
        
               </ul>
      </nav>

      {/* Backdrop for Small Screens */}
      {isOpen && window.innerWidth < 768 && (
        <div
          className="position-fixed top-0 start-0 w-100 h-100"
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 10,
          }}
          onClick={toggleSidebar}
        ></div>
      )}
    </div>
  );
}

export default Sidebar;
