import Arsenal from '../Team-Kits-Images/Arsenal.png'
import AstonVilla from '../Team-Kits-Images/AstonVilla.png'
import Bournemouth from '../Team-Kits-Images/Bournemouth.png'
import Brentford from '../Team-Kits-Images/Brentford.png'
import Brighton from '../Team-Kits-Images/Brighton.png'
import Burnley from '../Team-Kits-Images/Burnley.png'
import Chelsea from '../Team-Kits-Images/Chelsea.png'
import CrystalPalace from '../Team-Kits-Images/CrystalPalace.png'
import Everton from '../Team-Kits-Images/Everton.png'
import Fulham from '../Team-Kits-Images/Fulham.png'
import Ipswich from '../Team-Kits-Images/Ipswitch.png'
import Leicester from '../Team-Kits-Images/Leicester.png'
import Liverpool from '../Team-Kits-Images/Liverpool.png'
import Luton from '../Team-Kits-Images/LutonTown.png'
import ManCity from '../Team-Kits-Images/Man.City.png'
import ManUnited from '../Team-Kits-Images/Man.United.png'
import Newcastle from '../Team-Kits-Images/Newcastle.png'
import NottinghamForest from '../Team-Kits-Images/NottinghamForest.png'
import SheffieldUnited from '../Team-Kits-Images/SheffieldUnited.png'
import Southampton from '../Team-Kits-Images/Southampton.png'
import Tottenham from '../Team-Kits-Images/Tottenham.png'
import WestHam from '../Team-Kits-Images/WestHam.png'
import Wolves from '../Team-Kits-Images/Wolves.png'
import ArsenalLogo from '../Team-Kits-Images/ArsenalLogo.png'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useRef,useMemo } from 'react';
import { get, ref, getDatabase, set } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import Navbar from '../Navbar';
import Footer from '../Footer';
import Confetti from 'react-confetti';
import EPLStandingsPicks from './EPLStandingsPicks'
 
function PremierLeague() {
  const [fixtures, setFixtures] = useState([]);
  const [inputsData, setInputsData] = useState({});
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);
  const auth = getAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const user = auth.currentUser;
  const userId = user ? user.uid : null;
  const intervalRefs = useRef({});
  const [filteredFixtures, setFilteredFixtures] = useState([]);
  const [confetti, setConfetti] = useState(false);
  const [totalPoints, setTotalPoints] = useState(0);
  const { t } = useTranslation();
  const [fixturePoints, setFixturePoints] = useState({});
  const [displayName, setDisplayName] = useState('');
  const [selectedMatchday, setSelectedMatchday] = useState('Round');
  const [startIndex, setStartIndex] = useState(0);
  const containerRef = useRef(null);
  const [buttonWidth, setButtonWidth] = useState(0);
  const [currentRound, setCurrentRound] = useState(8); 
  const [buttonsToShow, setButtonsToShow] = useState(0);
  const dropdownMenuRef = useRef(null);
  const dropdownButtonRef = useRef(null);

  const fetchTotalPoints = async (displayName) => {
    try {
      const db = getDatabase();
      const totalPointsRef = ref(db, `EPL2024TotalPoints/${displayName}`);
      const totalPointsSnapshot = await get(totalPointsRef);
      const totalPointsData = totalPointsSnapshot.val() || { totalPoints: 0 };
      setTotalPoints(totalPointsData.totalPoints);
    } catch (error) {
      console.error("Error fetching total points:", error);
    }
  };

  const fetchPicks = async (displayName) => {
    try {
      const db = getDatabase();
      const picksRef = ref(db, `EPL2024Picks/${displayName}`);
      const totalPointsRef = ref(db, `EPL2024TotalPoints/${displayName}`);

      const [picksSnapshot, totalPointsSnapshot] = await Promise.all([
        get(picksRef),
        get(totalPointsRef)
      ]);

      const picksData = picksSnapshot.val() || {};
      const userPicks = picksData || {};
      setInputsData(userPicks);
      setDisplayName(displayName);

      const totalPointsData = totalPointsSnapshot.val() || { totalPoints: 0 };
      setTotalPoints(totalPointsData.totalPoints);

      const pointsData = {};
      Object.keys(userPicks).forEach(fixtureId => {
        pointsData[fixtureId] = userPicks[fixtureId].points;
      });
      setFixturePoints(pointsData);

      // Start point incrementing intervals for the fetched picks
      Object.keys(userPicks).forEach(fixtureId => {
        if (!intervalRefs.current[fixtureId]) {
          startPointsIncrement(fixtureId);
        }
      });
    } catch (error) {
      console.error("Error fetching picks:", error);
    }
  };

  const [dateRanges] = useState([
    { start: '2024-06-11', end: '2024-08-20' },  // Matchweek 1
    { start: '2024-08-20', end: '2024-08-26' },  // Matchweek 2
    { start: '2024-08-26', end: '2024-09-02' },  // Matchweek 3
    { start: '2024-09-02', end: '2024-09-16' },  // Matchweek 4
    { start: '2024-09-16', end: '2024-09-23' },  // Matchweek 5
    { start: '2024-09-23', end: '2024-09-31' },  // Matchweek 6
    { start: '2024-09-31', end: '2024-10-07' },  // Matchweek 7
    { start: '2024-10-07', end: '2024-10-22' },  // Matchweek 8
    { start: '2024-10-22', end: '2024-10-28' },  // Matchweek 9
    { start: '2024-10-28', end: '2024-11-05' },  // Matchweek 10
    { start: '2024-11-05', end: '2024-11-11' },  // Matchweek 11
    { start: '2024-11-11', end: '2024-11-26' },  // Matchweek 12
    { start: '2024-11-26', end: '2024-12-01' },  // Matchweek 13
    { start: '2024-12-01', end: '2024-12-05' },  // Matchweek 14
    { start: '2024-12-05', end: '2024-12-10' },  // Matchweek 15
    { start: '2024-12-10', end: '2024-12-17' },  // Matchweek 16
    { start: '2024-12-17', end: '2024-12-23' },  // Matchweek 17
    { start: '2024-12-23', end: '2024-12-29' },  // Matchweek 18
    { start: '2024-12-29', end: '2024-12-30' },  // Matchweek 19
    { start: '2025-01-01', end: '2025-01-07' },  // Matchweek 20
    { start: '2025-01-07', end: '2025-01-16' },  // Matchweek 21
    { start: '2025-01-16', end: '2025-01-21' },  // Matchweek 22
    { start: '2025-01-21', end: '2025-01-26' },  // Matchweek 23
    { start: '2025-01-26', end: '2025-02-04' },  // Matchweek 24
    { start: '2025-02-04', end: '2025-02-17' },  // Matchweek 25
    { start: '2025-02-17', end: '2025-02-23' },  // Matchweek 26
    { start: '2025-02-23', end: '2025-02-27' },  // Matchweek 27
    { start: '2025-02-27', end: '2025-03-09' },  // Matchweek 28
    { start: '2025-03-09', end: '2025-03-16' },  // Matchweek 29
    { start: '2025-03-16', end: '2025-04-03' },  // Matchweek 30
    { start: '2025-04-03', end: '2025-04-06' },  // Matchweek 31
    { start: '2025-04-06', end: '2025-04-13' },  // Matchweek 32
    { start: '2025-04-13', end: '2025-04-20' },  // Matchweek 33
    { start: '2025-04-20', end: '2025-04-27' },  // Matchweek 34
    { start: '2025-04-27', end: '2025-05-04' },  // Matchweek 35
    { start: '2025-05-04', end: '2025-05-11' },  // Matchweek 36
    { start: '2025-05-11', end: '2025-05-19' },  // Matchweek 37
    { start: '2025-05-19', end: '2025-07-20' },  // Matchweek 38
        // Add more matchweeks as needed
    ]);

useEffect(() => {
  const currentDate = new Date();
  let currentMatchday = 1;
  for (let i = 0; i < dateRanges.length; i++) {
    const startDate = new Date(dateRanges[i].start);
    const endDate = new Date(dateRanges[i].end);
    if (currentDate >= startDate && currentDate <= endDate) {
      currentMatchday = i + 1;
      break;
    }
  }
  setCurrentPage(currentMatchday);
  setSelectedMatchday(`Round ${currentMatchday}`);
}, [dateRanges]);


  useEffect(() => {
    const filteredFixtures = fixtures.filter(fixture => {
      const fixtureDate = new Date(fixture.fixture.date);
      const startDate = new Date(dateRanges[currentPage - 1].start);
      const endDate = new Date(dateRanges[currentPage - 1].end);
      return fixtureDate >= startDate && fixtureDate <= endDate;
    });

    const sortedFixtures = filteredFixtures.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));

    setFilteredFixtures(sortedFixtures);
  }, [currentPage, fixtures, dateRanges]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setSelectedMatchday(`${t('round')} ${page}`);
  };
  
  useEffect(() => {
    if (user) {
      const displayName = user.displayName || 'Anonymous';
      fetchPicks(displayName);
      fetchTotalPoints(displayName);
    }
  }, [user]);

  useEffect(() => {
    const fetchFixtures = async () => {
      try {
        const fixturesResponse = await fetch("https://iga-sport.azurewebsites.net/api/league/39/fixtures/season/2024", {
          headers: {
            'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
          }
        });
        const fixturesData = await fixturesResponse.json();
        setFixtures(fixturesData);
      } catch (error) {
        console.error("Error fetching fixtures:", error);
      }
    };

    fetchFixtures();
  }, []);

  


useEffect(() => {
  const intervalId = setInterval(() => {
    fixtures.forEach(fixture => {
      awardPointsForAllUsers(fixture.fixture.id);
    });
  }, 60000);

  return () => clearInterval(intervalId);
}, [fixtures, inputsData, displayName]);


const awardPointsForAllUsers = async (fixtureId) => {
  try {
    const db = getDatabase();
    const fixtureRef = ref(db, `fixtures/${fixtureId}`);
    const fixtureSnapshot = await get(fixtureRef);
    const fixtureData = fixtureSnapshot.val();

    console.log("Fixture Data:", fixtureData); // Debug: Log fixture data

    if (fixtureData && fixtureData.fixture.status.short === "FT") {
      const homeGoals = fixtureData.score.fulltime.home;
      const awayGoals = fixtureData.score.fulltime.away;

      const picksRef = ref(db, `EPL2024Picks`);
      const picksSnapshot = await get(picksRef);
      const allUserPicks = picksSnapshot.val() || {};

      Object.keys(allUserPicks).forEach(async (userId) => {
        const userPicks = allUserPicks[userId];
        const userPick = userPicks[fixtureId];

        if (userPick && !userPick.pointsAwarded) {
          const { home: predictedHomeGoals, away: predictedAwayGoals } = userPick;

          let points = 0;

          if (predictedHomeGoals == homeGoals && predictedAwayGoals == awayGoals) {
            points = 3;
          } else if (
            (predictedHomeGoals > predictedAwayGoals && homeGoals > awayGoals) ||
            (predictedHomeGoals < predictedAwayGoals && homeGoals < awayGoals) ||
            (predictedHomeGoals == predictedAwayGoals && homeGoals == awayGoals)
          ) {
            points = 1;
          }

          console.log(`Awarding ${points} points to user ${userId} for fixture ${fixtureId}`); // Debug: Log points awarded

          userPicks[fixtureId] = {
            ...userPick,
         
          };

          const userTotalPointsRef = ref(db, `EPL2024TotalPoints/${userId}`);
          const userTotalPointsSnapshot = await get(userTotalPointsRef);
          const userTotalPoints = userTotalPointsSnapshot.val() || { totalPoints: 0 };

          await set(userTotalPointsRef, {
            totalPoints: userTotalPoints.totalPoints + points,
          });

          await set(ref(db, `EPL2024Picks/${userId}`), userPicks);
        }
      });
    }
  } catch (error) {
    console.error("Error awarding points:", error);
  }
};



const startPointsIncrement = (fixtureId) => {
  intervalRefs.current[fixtureId] = setInterval(() => {
    awardPointsForAllUsers(fixtureId);
  }, 60000); // Check every 60 seconds
};



// Ensure that this function is called every 60 seconds to check for completed fixtures
useEffect(() => {
  const intervalId = setInterval(() => {
    fixtures.forEach(fixture => {
      awardPointsForAllUsers(fixture.fixture.id);
    });
  }, 60000);

  return () => clearInterval(intervalId);
}, [fixtures, inputsData, displayName]);

const handleInputChange = (fixtureId, team, value) => {
    setInputsData(prevInputsData => ({
      ...prevInputsData,
      [fixtureId]: {
        ...prevInputsData[fixtureId],
        [team]: value,
  
      }
    }));
  };

  const handleSubmit = async () => {
    if (userId) {
      const db = getDatabase();
      const picksRef = ref(db, `EPL2024Picks/${displayName}`);
      const totalPointsRef = ref(db, `EPL2024TotalPoints/${displayName}`);
  
      let userDisplayName = user.displayName || 'Anonymous';
  
      // Extract user ID within parentheses
      const match = userDisplayName.match(/\(([^)]+)\)/);
      if (match && match[1]) {
        userDisplayName = match[1];
      }
  
      try {
        // Fetch existing picks from the backend
        const picksSnapshot = await get(picksRef);
        const existingPicksData = picksSnapshot.val() || {};
  
        // Debug: Log the current inputsData before filtering
        console.log("inputsData before filtering:", inputsData);
  
        // Filter inputsData to only include fixtures where a valid prediction is made
        const filteredInputsData = Object.keys(inputsData).reduce((acc, fixtureId) => {
          const fixturePrediction = inputsData[fixtureId];
  
          // Check if home or away prediction is made (0 is valid, undefined is not)
          if (fixturePrediction.home !== undefined || fixturePrediction.away !== undefined) {
            acc[fixtureId] = {
              home: fixturePrediction.home !== undefined ? fixturePrediction.home : "",
              away: fixturePrediction.away !== undefined ? fixturePrediction.away : ""
            };
          }
          return acc;
        }, {});
  
        // Debug: Log the filtered inputsData after filtering
        console.log("filteredInputsData:", filteredInputsData);
  
        // Check if any predictions are available for submission
        if (Object.keys(filteredInputsData).length === 0) {
          console.log("No predictions made, nothing to submit.");
          return;  // Exit early if there are no valid predictions
        }
  
        // Merge new filtered picks with existing picks
        const mergedPicks = {
          ...existingPicksData,
          ...filteredInputsData
        };
  
        // Debug: Log the merged data being sent to the backend
        console.log("Merged picks data being submitted:", mergedPicks);
  
        // Submit the merged picks to the backend
        await set(picksRef, mergedPicks);
  
        // Check if totalPoints entry exists, if not, initialize it
        const totalPointsSnapshot = await get(totalPointsRef);
        if (!totalPointsSnapshot.exists()) {
          await set(totalPointsRef, { totalPoints: 0 });
        }
  
        setSent(true);
        setTimeout(() => setSent(false), 15000);
        setConfetti(true);
        setTimeout(() => setConfetti(false), 5000);
  
        // Start points incrementing for all fixtures with a prediction
        Object.keys(filteredInputsData).forEach(fixtureId => {
          startPointsIncrement(fixtureId);
        });
      } catch (error) {
        console.error('Error submitting picks:', error);
        setError(true);
        setTimeout(() => setError(false), 5000);
      }
    }
  };
  
  
  

  const handleMatchdaySelect = (index) => {
    const Round = `${t('round')} ${index + 1}`;
    setSelectedMatchday(`${t('round')} ${index + 1}`);
    
    handlePageChange(index + 1);
  };
  
  useEffect(() => {
    if (confetti) {
      const timeout = setTimeout(() => {
        setConfetti(false);
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, [confetti]);
 
  const convertToFormattedDateTime = (dateString) => {
    const options = { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', hour12: false };
    const date = new Date(dateString);
    return date.toLocaleString('en-US', options);
  };
  
  const teamLogos = {
    'Arsenal': Arsenal,
    'Aston Villa': AstonVilla,
    'Bournemouth': Bournemouth,
    'Brentford': Brentford,
    'Brighton': Brighton,
    'Burnley': Burnley,
    'Chelsea': Chelsea,
    'Crystal Palace': CrystalPalace,
    'Everton': Everton,
    'Fulham': Fulham,
    'Liverpool': Liverpool,
    'Luton': Luton,
    'Manchester City': ManCity,
    'Manchester United': ManUnited,
    'Newcastle': Newcastle,
    'Nottingham Forest': NottinghamForest,
    'Sheffield Utd': SheffieldUnited,
    'Tottenham': Tottenham,
    'West Ham': WestHam,
    'Wolves': Wolves,
    'Ipswich': Ipswich,
    'Leicester': Leicester,
    'Southampton': Southampton
  };
  
  const teamInitials = {
    'Arsenal': 'ARS',
    'Aston Villa': 'AVL',
    'Bournemouth': 'BOU',
    'Brentford': 'BRE',
    'Brighton': 'BHA',
    'Burnley': 'BUR',
    'Chelsea': 'CHE',
    'Crystal Palace': 'CRY',
    'Everton': 'EVE',
    'Fulham': 'FUL',
    'Liverpool': 'LIV',
    'Luton': 'LUT',
    'Manchester City': 'MCI',
    'Manchester United': 'MUN',
    'Newcastle': 'NEW',
    'Nottingham Forest': 'NFO',
    'Sheffield Utd': 'SHU',
    'Tottenham': 'TOT',
    'West Ham': 'WHU',
    'Wolves': 'WOL',
    'Ipswich': 'IPS',
    'Leicester': 'LEI',
    'Southampton': 'SOU'

   
  };

  const stadiums = {
    "Arsenal": `${t('stadium')}: Emirates Stadium`,
    "Aston Villa":  `${t('stadium')}: Villa Park`,
    "Bournemouth":  `${t('stadium')}: Vitality Stadium`,
    "Brentford":  `${t('stadium')}: Gtech Community Stadium`,
    "Brighton":  `${t('stadium')}: Amex Stadium`,
    "Burnley":  `${t('stadium')}: Turf Moor`,
    "Chelsea":  `${t('stadium')}: Stamford Bridge`,
    "Crystal Palace":  `${t('stadium')}: Selhurst Park`,
    "Everton":  `${t('stadium')}: Goodison Park`,
    "Fulham":  `${t('stadium')}: Craven Cottage`,
    "Liverpool":  `${t('stadium')}: Anfield`,
    "Luton":  `${t('stadium')}: Kenilworth Road`,
    "Manchester City":  `${t('stadium')}: Etihad Stadium`,
    "Manchester United":  `${t('stadium')}: Old Trafford`,
    "Newcastle":  `${t('stadium')}: St. James' Park`,
    "Nottingham Forest":  `${t('stadium')}: City Ground`,
    "Sheffield United":  `${t('stadium')}: Bramall Lane`,
    "Tottenham":  `${t('stadium')}: Tottenham Hotspur Stadium`,
    "West Ham":  `${t('stadium')}: London Stadium`,
    "Wolves":  `${t('stadium')}: Molineux Stadium`,
    "Ipswich":  `${t('stadium')}: Portman Road`,
    "Leicester":  `${t('stadium')}: King Power Stadium`,
    "Southampton":  `${t('stadium')}: St. Mary's Stadium`,
  };
  
  
  const renderTeamLogo = (teamName) => {
    const logo = teamLogos[teamName];
    if (logo) {
      return <img src={logo} alt={teamName} style={{ width: '75px', height: 'auto' }} />;
    }
    return <span style={{ fontStyle: 'normal' }}>{teamName}</span>;
  };
  
  const renderTeamInitials = (teamName) => {
    return teamInitials[teamName] || teamName;
  };

  const [selectedMatchdayIndex, setSelectedMatchdayIndex] = useState(0);

  const roundsContainerRef = useRef(null);

  // Handle Previous Button Click
  // Handle Direct Matchday Selection
  const handleMatchdaySelectNew = (index) => {
    setSelectedMatchdayIndex(index);
  };
  
  useEffect(() => {
    const calculateButtonsToShow = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.clientWidth;
        const calculatedButtonWidth = 100; // Adjust width as needed
        const calculatedButtonsToShow = Math.floor(containerWidth / (calculatedButtonWidth + 8));
        setButtonWidth(calculatedButtonWidth);
        setButtonsToShow(calculatedButtonsToShow);
        
        // Dynamically set startIndex around the current round
        calculateStartIndex(currentPage, calculatedButtonsToShow); 
      }
    };
  
    calculateButtonsToShow();
  
    // Recalculate on window resize
    window.addEventListener('resize', calculateButtonsToShow);
    return () => {
      window.removeEventListener('resize', calculateButtonsToShow);
    };
  }, [currentPage, containerRef.current]);
  
  // Adjust calculateStartIndex to center the rounds based on current page
  const calculateStartIndex = (currentPage, buttonsToShow) => {
    let minIndex;
  
    // Center start index around current round within available buttonsToShow
    if (currentPage - Math.floor(buttonsToShow / 2) >= 0) {
      minIndex = currentPage - Math.floor(buttonsToShow / 2);
    } else {
      minIndex = 0; // fallback for early rounds
    }
    
    // Ensure we don’t exceed the available rounds
    minIndex = Math.min(minIndex, dateRanges.length - buttonsToShow);
  
    setStartIndex(minIndex);
  };
  


  const handleLeftArrowClick = () => {
    if (roundsContainerRef.current && startIndex > 0) {
      roundsContainerRef.current.scrollBy({
        left: -buttonWidth, // Scroll by one button width
        behavior: 'smooth', // Make the scroll smooth
      });
      setStartIndex(startIndex - 1); // Decrement startIndex by 1
    }
  };
  
  // Function to handle right arrow click (move by 1 round)
  const handleRightArrowClick = () => {
    if (roundsContainerRef.current && startIndex + 1 < dateRanges.length) {
      roundsContainerRef.current.scrollBy({
        left: buttonWidth, // Scroll by one button width
        behavior: 'smooth', // Make the scroll smooth
      });
      setStartIndex(startIndex + 1); // Increment startIndex by 
    }
  };
  
  useEffect(() => {
    const currentDate = new Date();
    let currentMatchday = 1;
    for (let i = 0; i < dateRanges.length; i++) {
      const startDate = new Date(dateRanges[i].start);
      const endDate = new Date(dateRanges[i].end);
      if (currentDate >= startDate && currentDate <= endDate) {
        currentMatchday = i + 1;
        break;
      }
    }
    setCurrentPage(currentMatchday);
    setSelectedMatchday(`Round ${currentMatchday}`);
  }, [dateRanges]);

  // Add new effect to handle dropdown button click
  useEffect(() => {
    const handleDropdownOpen = () => {
      if (dropdownMenuRef.current) {
        // Wait for the dropdown to be visible
        setTimeout(() => {
          const currentRoundIndex = parseInt(selectedMatchday.split(' ')[1]) - 1;
          const listItems = dropdownMenuRef.current.querySelectorAll('.list-group-item');
          if (listItems[currentRoundIndex]) {
            listItems[currentRoundIndex].scrollIntoView({
              block: 'center',
              behavior: 'auto'
            });
          }
        }, 0);
      }
    };

    const dropdownButton = dropdownButtonRef.current;
    if (dropdownButton) {
      dropdownButton.addEventListener('click', handleDropdownOpen);
      return () => dropdownButton.removeEventListener('click', handleDropdownOpen);
    }
  }, [selectedMatchday]);

  
  return (
    <div>
    <div className='App-headerV'>
      <h2 className='text-light text-center padtop'style={{ fontFamily: 'Lato, sans-serif'}}>EPL {t('picks')}</h2>
      <div className='text-light text-center' style={{ paddingTop: '10px', paddingBottom: '10px', gap: '5px' }}>
      <div className="dropdown ">
            <button
              className="btn btn-purple dropdown-toggle"
              type="button"
              id="groupStageDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="true"
              ref={dropdownButtonRef}
            >
              {selectedMatchday}
            </button>
            <div
              className="dropdown-menu matchweek-dropdown"
              aria-labelledby="groupStageDropdown"
              ref={dropdownMenuRef}
              style={{ maxHeight: '300px', overflowY: 'auto' }}
            >
              <ul className="list-group">
                {dateRanges.map((range, index) => (
                  <li key={index} className="list-group-item">
                    <button
                      className={`dropdown-item ${selectedMatchday === `Round ${index + 1}` ? 'btn-purple text-light' : ''}`}
                      onClick={() => handleMatchdaySelect(index)}
                      style={{ width: '100%' }}
                    >
                      {t('round')} {index + 1}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
      </div>
 
      <div className='container'>
        <div className='row'>
          {/* Standings Card for large screens */}

          {/* Main content area */}
          <div className=' col-12'>
            {filteredFixtures.map(fixture => {
              // Get the predicted scores from user input
              const predictedHomeGoals = inputsData[fixture.fixture.id]?.home;
              const predictedAwayGoals = inputsData[fixture.fixture.id]?.away;
              const actualHomeGoals = fixture.goals.home;
              const actualAwayGoals = fixture.goals.away;
  
              let points = 0;
  
              // Check if the user made predictions and the match is finished
              if (predictedHomeGoals !== undefined && predictedAwayGoals !== undefined && fixture.fixture.status.short === 'FT') {
                if (predictedHomeGoals === actualHomeGoals && predictedAwayGoals === actualAwayGoals) {
                  points = 3; // Exact score
                } else if (
                  (predictedHomeGoals > predictedAwayGoals && actualHomeGoals > actualAwayGoals) ||
                  (predictedHomeGoals < predictedAwayGoals && actualHomeGoals < actualAwayGoals) ||
                  (predictedHomeGoals === predictedAwayGoals && actualHomeGoals === actualAwayGoals)
                ) {
                  points = 1; // Correct outcome but wrong score
                }
              }
  
              // Store points in the inputsData state
              if (!inputsData[fixture.fixture.id]) {
                inputsData[fixture.fixture.id] = {};
              }
              inputsData[fixture.fixture.id].points = points;
  
              return (
                <div key={fixture.fixture.id} className='my-3 card border-0 bg-transparent text-light border-light'>
                <div className='card-body text-center mb-1'>
                  <h5 className='card-title mb-3'>{fixture.teams.home.name} vs {fixture.teams.away.name}</h5>
                  
                  {/* Conditional rendering for the stadium name */}
                  {stadiums[fixture.teams.home.name] && (
      <h6 className='card-title mb-3'>{stadiums[fixture.teams.home.name]}</h6>
    )}
              
                  <div className="row align-items-center">
                    <div className="col-2 text-center d-flex flex-column align-items-center">
                      {renderTeamLogo(fixture.teams.home.name)}
                      <h5>{renderTeamInitials(fixture.teams.home.name)}</h5>
                    </div>
                    <div className="col text-center padtopinput border-secondary">
  <div className='form-group text-center' style={{ borderColor: '#9370DB' }}>
    <select
      value={
        fixture.fixture.status.short !== 'NS' && fixture.fixture.status.short !== 'TBD'
          ? (inputsData[fixture.fixture.id]?.home ?? '')
          : (inputsData[fixture.fixture.id]?.home ?? '')
      }
      onChange={(e) => {
        const value = parseInt(e.target.value, 10);
        if (!isNaN(value) && value >= 0 && value <= 10) {
          handleInputChange(fixture.fixture.id, 'home', value);
        } else {
          handleInputChange(fixture.fixture.id, 'home', '');
        }
      }}
      className={`form-control text-center ${fixture.fixture.status.short !== 'NS' && fixture.fixture.status.short !== 'TBD' ? 'bg-secondary text-light' : ''}`}
      disabled={fixture.fixture.status.short !== 'NS' && fixture.fixture.status.short !== 'TBD'}
      style={{
        textAlign: 'center',
        textAlignLast: 'center',
        borderColor: '#9370DB'
      }}
    >
      {fixture.fixture.status.short !== 'NS' && fixture.fixture.status.short !== 'TBD' ? (
        <option value={inputsData[fixture.fixture.id]?.home}>
          {inputsData[fixture.fixture.id]?.home !== '' && inputsData[fixture.fixture.id]?.home !== undefined
            ? inputsData[fixture.fixture.id]?.home
            : window.innerWidth <= 768
            ? t('pick')
            : `No ${t('pick')}`}
        </option>
      ) : (
        <>
          <option value="">{t('pick')}</option>
          {[...Array(11).keys()].map(num => (
            <option key={num} value={num}>{num}</option>
          ))}
        </>
      )}
    </select>
  </div>
</div>
<div className="col text-center padtopinput border-secondary">
  <div className='form-group text-center' style={{ borderColor: '#9370DB' }}>
    <select
      value={
        fixture.fixture.status.short !== 'NS' && fixture.fixture.status.short !== 'TBD'
          ? (inputsData[fixture.fixture.id]?.away ?? '')
          : (inputsData[fixture.fixture.id]?.away ?? '')
      }
      onChange={(e) => {
        const value = parseInt(e.target.value, 10);
        if (!isNaN(value) && value >= 0 && value <= 10) {
          handleInputChange(fixture.fixture.id, 'away', value);
        } else {
          handleInputChange(fixture.fixture.id, 'away', '');
        }
      }}
      className={`form-control text-center ${fixture.fixture.status.short !== 'NS' && fixture.fixture.status.short !== 'TBD' ? 'bg-secondary text-light' : ''}`}
      disabled={fixture.fixture.status.short !== 'NS' && fixture.fixture.status.short !== 'TBD'}
      style={{
        textAlign: 'center',
        textAlignLast: 'center',
        borderColor: '#9370DB'
      }}
    >
      {fixture.fixture.status.short !== 'NS' && fixture.fixture.status.short !== 'TBD' ? (
        <option value={inputsData[fixture.fixture.id]?.away}>
          {inputsData[fixture.fixture.id]?.away !== '' && inputsData[fixture.fixture.id]?.away !== undefined
            ? inputsData[fixture.fixture.id]?.away
            : window.innerWidth <= 768
            ? t('pick')
            : `No ${t('pick')}`}
        </option>
      ) : (
        <>
          <option value="">{t('pick')}</option>
          {[...Array(11).keys()].map(num => (
            <option key={num} value={num}>{num}</option>
          ))}
        </>
      )}
    </select>
  </div>
</div>
                    <div className="col-2 text-center d-flex flex-column align-items-center">
                      {renderTeamLogo(fixture.teams.away.name)}
                      <h5>{renderTeamInitials(fixture.teams.away.name)}</h5>
                    </div>
                  </div>
                  <div>{t('result')}: {fixture.fixture.status.short === 'NS' ? 'TBA' : `${fixture.goals.home} - ${fixture.goals.away}`}</div>
              
                  {/* Display the points scored for this fixture */}
                  {fixture.fixture.status.short === 'FT' && (
                    <p className={points === 1 ? 'text-warning' : points === 3 ? 'text-success' : ''}>
{t('you_scored')} <b>{points}</b> {points === 1 ? t('point') : t('points')} {t('for_this_game')}
</p>
                  )}
                </div>
                <div className='text-dark col-6 col-lg-3 mx-auto text-center text-light' 
 style={{ 
   backgroundColor: '#9370DB', 
   borderRadius: '5px', 
   borderBottomLeftRadius: '5px',  // Set bottom-left corner radius to 0
   borderBottomRightRadius: '5px'  // Set bottom-right corner radius to 0
 }}
>
<p className="text-center mb-2 btn-sm text-bold" 
 style={{ 
   color: '#fff', 
   paddingTop: '4px',  
   borderTopLeftRadius: '1px' 
 }}
>
{t('kick_off')} {convertToFormattedDateTime(fixture.fixture.date)}
</p>
</div>
<hr className="my-3" style={{ height: '4px', backgroundColor: '#9370DB', border: 'none' }} />

              </div>
              
              
              );
            })}
  
            <div className='text-center' style={{ paddingBottom: '10px' }}>
              <button onClick={handleSubmit} className='btn btn-purple text-center'>{t('submit_picks')}</button>
              {confetti && <Confetti />}
            </div>
  
            <div className='container text-center padtop' style={{ paddingBottom: '10px' }}>
              <p className='text-light'>{t('total_points')} <b>{totalPoints}</b></p>
            </div>
  
            {sent && (
              <>
                <div className='alert alert-success Nav-HeaderV8 text-center'>
                {t('your_picks_sent')}
                </div>
                {confetti && <Confetti gravity={-5.5} />}
              </>
            )}
            {error && <p className='text-danger'>{t('error_submitting_picks')}</p>}
          </div>
  

  

        </div>
      </div>
    </div>
  </div>
  
  
  );
}

export default PremierLeague;
