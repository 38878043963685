import React, { useState, useEffect } from 'react';
import { ref, getDatabase, onValue } from 'firebase/database';
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import Invitations from '../Invitations';
import { BiFootball } from "react-icons/bi";
import { useTranslation } from 'react-i18next';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../firebase';
function Dashboard() {
    const [userUid, setUserUid] = useState('');
    const [joinedBrackets, setJoinedBrackets] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [brackets, setBrackets] = useState([]);
    const [userEmail, setUserEmail] = useState('');
    const { t } = useTranslation();
    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [showModal1, setShowModal1] = useState(false);

    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    const handleOpenModal2 = () => setShowModal2(true);
    const handleCloseModal2 = () => setShowModal2(false);
    const handleOpenModal1 = () => setShowModal1(true);
    const handleCloseModal1 = () => setShowModal1(false);
    useEffect(() => {
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
            setUserUid(user.uid);
        }

        const fetchJoinedBrackets = async () => {
            try {
                const database = getDatabase();
                const bracketsRef = ref(database, 'CreateBracket');
                onValue(bracketsRef, (snapshot) => {
                    const data = snapshot.val();
                    if (data) {
                        const joinedBracketsArray = Object.entries(data).filter(([_, bracket]) =>
                            bracket.participants && bracket.participants[userUid]
                        );
                        setJoinedBrackets(joinedBracketsArray);
                    }
                });
            } catch (error) {
                console.error('Error fetching joined brackets:', error);
            }
        };

        fetchJoinedBrackets();
    }, [userUid]);

    useEffect(() => {
        // Load the last viewed huddle from local storage
        const lastViewedHuddle = localStorage.getItem('lastViewedHuddle');
        if (lastViewedHuddle) {
            setSelectedCategory(lastViewedHuddle);
        }
    }, []);

    const handleCategoryToggle = (category) => {
        setSelectedCategory(category);
        // Save the last viewed huddle to local storage
        localStorage.setItem('lastViewedHuddle', category);
    };

    const filteredBrackets = joinedBrackets.filter(([_, bracket]) => bracket.tournament === selectedCategory);


    useEffect(() => {
        const fetchBrackets = async () => {
          try {
            const database = getDatabase();
            const bracketsRef = ref(database, 'CreateArticle');
            onValue(bracketsRef, (snapshot) => {
              const data = snapshot.val();
              if (data) {
                const bracketsArray = Object.entries(data);
                // Sort the bracketsArray by timestamp in descending order
                bracketsArray.sort((a, b) => new Date(b[1].timestamp) - new Date(a[1].timestamp));
                // Take only the first 3 articles
                setBrackets(bracketsArray.slice(0, 3));
              }
            });
          } catch (error) {
            console.error('Error fetching brackets:', error);
          }
        };
      
        fetchBrackets();
      
        // Listen for authentication state changes
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
            setUserEmail(user.email);
          } else {
            setUserEmail('');
          }
        });
      
        return () => unsubscribe();
      }, []);

    return (
        <div className=''>
            <div className='App-headerV8'>
            <Navbar />
            <div className="container padtop" style={{ minHeight: '100vh' }}>
            <div className="row justify-content-md-center">
                        <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
                            <h2 className="mb-2 display-5 text-center text-light" style={{ fontFamily: 'Lato, sans-serif' }}>{t('dashboard')}</h2>
                        </div>
                    </div>
                    <div className="row justify-content-md-center">
                        <i className='text-center mb- text-light' style={{ fontFamily: 'Lato, sans-serif', fontWeight: '300' }}>{t('select_huddle_view')}                        </i>

                        <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6">
                        <hr className="w-50 mx-auto mb-4 mb-xl-9 border-light-subtle" />

                            <h3 className="mb-4 display-8 text-center" style={{ color: 'black' }}>
                                <div className="btn-group " style={{backgroundColor: 'transparent'}}>
                                    <button type="button" className="btn btn-outline-light text-light"     style={{ backgroundColor: 'transparent', }}                                    ><BiFootball /></button>
                                    <button type="button" className="btn btn-outline-light dropdown-toggle text-light"     style={{ backgroundColor: 'transparent', }} data-bs-toggle="dropdown" aria-expanded="false">
                                    {t('Huddles')}
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li><button className="dropdown-item"  onClick={() => handleCategoryToggle('English Premier League')}>EPL 24/25</button></li>
                                        <li><button className="dropdown-item" onClick={() => handleCategoryToggle('Championship')}>Championship 24/25</button></li>
                                        <li><button className="dropdown-item" onClick={() => handleCategoryToggle('Scottish Premiership')}>SPFL 24/25</button></li>
                                        <li><button className="dropdown-item" onClick={() => handleCategoryToggle('La Liga')}>La Liga 24/25</button></li>
                                        <li><button className="dropdown-item" onClick={() => handleCategoryToggle('UCL')}>Champions League 24/25</button></li>
                                        <li><button className="dropdown-item" onClick={() => handleCategoryToggle('Bundesliga')}>Bundesliga 24/25</button></li>
                                        <li><button className="dropdown-item" onClick={() => handleCategoryToggle('Serie A')}>Serie A 24/25</button></li>
                                        <li><button className="dropdown-item" onClick={() => handleCategoryToggle('SASoccer')}>Premier Soccer League 24/25</button></li>
                                    </ul>
                                </div>
                            </h3>
                        </div>
                    </div>
                    <section className="py-3 py-md-6 ">
                        <div className="container">
                            {selectedCategory && filteredBrackets.length > 0 ? (
                                filteredBrackets.map(([bracketId, bracket]) => (
                                    <Link
                                        to={`/bracket/${bracket.id}/${bracket.nameOfBracket}/participants`}
                                        className="list-group-item list-group-item-action"
                                        key={bracketId}
                                        style={{ marginBottom: '5px' }}
                                    >
                                        <div className="row justify-content-center" style={{ marginBottom: '10px' }}>
                                            <div className="col-12 col-sm-10 col-md-8 col-lg-7 col-xl-6 col-xxl-5">
                                                <div className="card widget-card border-light shadow-sm bg-transparent">
                                                    <div className="card-body p-4">
                                                        <h5 className="card-title widget-card-title mb-4 text-light">{bracket.nameOfBracket}</h5>
                                                        <div className="row gy-4">
                                                            <div className="col-12">
                                                                <div className="row align-items-center">
                                                                    <div className="col-8">
                                                                        <div className="d-flex align-items-center">
                                                                            <div>
                                                                                <div className="fs-5 bsb-w-50 bsb-h-50 bg-primary-subtle text-primary rounded-2 d-flex align-items-center justify-content-center me-3">
                                                                                    <img src={bracket.image} style={{ width: '40px', height: '40px', objectFit: 'cover' }} />
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <h6 className="m-0 text-light">{bracket.tournament}</h6>
                                                                                <p className="text-secondary m-0 fs-7 text-light">{bracket.purpose}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-4">
                                                                        <h6 className="text-end text-light">{bracket.participantsCount} / {bracket.maxAmountOfPlayers}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                ))
                            ) : selectedCategory ? (
                                <div>
                                <p className="text-center text-light">{t('no_huddles_message')}</p>
                               <p className='text-center text-light'><Link to='/joinhuddle'>{t('join_huddle_cta')}</Link> </p> 
                                </div>
                            ) : null}
                        </div>
                    </section>
                    <Invitations />
{/* sections start */}
<section class="bg-transparent py-5 py-xl-8">
  <div class="container overflow-hidden">
    <div class="row gy-4 gy-xl-0">
      <div class="col-12 col-sm-6 col-xl-4">
        <div class="card border-0 border-bottom border-light shadow-sm bg-transparent text-light">
          <div class="card-body text-center p-4 p-xxl-5">
            <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="currentColor" class="bi bi-chat-text text-primary mb-4" viewBox="0 0 16 16">
              <path d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z" />
              <path d="M4 5.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8zm0 2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5z" />
            </svg>
            <h4 class="mb-4">  {t('create_huddle_link')}</h4>
            <p class="mb-4 text-light">{t('start_huddle_invite_friends')}</p>
            <div className="mb-3 mt-auto">
                      <Link to="/createhuddle" >
                        <span className="btn btn-outline- text-light snake-border-btn-primary">
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          <b>{t('create_huddle_link')}</b>
                        </span>
                      </Link>
                      <br />
                     
                    </div>
                    <Link
        to={'#'}
        onClick={handleOpenModal1}
        className="fw-bold text-decoration-none link-primary hover-underline"
      >
        {t('learnmore')}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-arrow-right-short"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
          />
        </svg>
      </Link>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-xl-4">
        <div class="card border-0 border-bottom border-light shadow-sm bg-transparent text-light">
          <div class="card-body text-center p-4 p-xxl-5">
            <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="currentColor" class="bi bi-gem text-warning mb-4" viewBox="0 0 16 16">
            <path d="M2.5.5A.5.5 0 0 1 3 0h10a.5.5 0 0 1 .5.5q0 .807-.034 1.536a3 3 0 1 1-1.133 5.89c-.79 1.865-1.878 2.777-2.833 3.011v2.173l1.425.356c.194.048.377.135.537.255L13.3 15.1a.5.5 0 0 1-.3.9H3a.5.5 0 0 1-.3-.9l1.838-1.379c.16-.12.343-.207.537-.255L6.5 13.11v-2.173c-.955-.234-2.043-1.146-2.833-3.012a3 3 0 1 1-1.132-5.89A33 33 0 0 1 2.5.5m.099 2.54a2 2 0 0 0 .72 3.935c-.333-1.05-.588-2.346-.72-3.935m10.083 3.935a2 2 0 0 0 .72-3.935c-.133 1.59-.388 2.885-.72 3.935M3.504 1q.01.775.056 1.469c.13 2.028.457 3.546.87 4.667C5.294 9.48 6.484 10 7 10a.5.5 0 0 1 .5.5v2.61a1 1 0 0 1-.757.97l-1.426.356a.5.5 0 0 0-.179.085L4.5 15h7l-.638-.479a.5.5 0 0 0-.18-.085l-1.425-.356a1 1 0 0 1-.757-.97V10.5A.5.5 0 0 1 9 10c.516 0 1.706-.52 2.57-2.864.413-1.12.74-2.64.87-4.667q.045-.694.056-1.469z"/>
            </svg>
            <h4 class="mb-4">{t('join_huddle_link')}</h4>
            <p class="mb-4 text-light">{t('jump_into_fun_challenge_players')}</p>
            <div className="mb-3 mt-auto">
                      <Link to="/joinhuddle" >
                        <span className="btn btn-outline- text-light snake-border-btn-gold">
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          <b>{t('join_huddle_link')}</b>
                        </span>
                      </Link>
                      <br />
                     
                    </div>
                    <Link
        to={'#'}
        onClick={handleOpenModal2}
        className="fw-bold text-decoration-none link-primary hover-underline"
      >
        {t('learnmore')}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-arrow-right-short"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
          />
        </svg>
      </Link>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-6 col-xl-4">
        <div class="card border-0 border-bottom border-light shadow-sm bg-transparent text-light">
          <div class="card-body text-center p-4 p-xxl-5">
            <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" fill="currentColor" class="bi bi-trophy text-info mb-4" viewBox="0 0 16 16">
            <path d="M3.1.7a.5.5 0 0 1 .4-.2h9a.5.5 0 0 1 .4.2l2.976 3.974c.149.185.156.45.01.644L8.4 15.3a.5.5 0 0 1-.8 0L.1 5.3a.5.5 0 0 1 0-.6zm11.386 3.785-1.806-2.41-.776 2.413zm-3.633.004.961-2.989H4.186l.963 2.995zM5.47 5.495 8 13.366l2.532-7.876zm-1.371-.999-.78-2.422-1.818 2.425zM1.499 5.5l5.113 6.817-2.192-6.82zm7.889 6.817 5.123-6.83-2.928.002z"/>
            </svg>
            <h4 class="mb-4"> {t('player_leaderboard')}</h4>
            <p class="mb-4 text-light">{t('see_how_you_rank')}</p>
            <div className="mb-3 mt-auto">
                      <Link to="/globalleaderboard" >
                        <span className="btn btn-outline- text-light snake-border-btn-info">
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          <b>{t('player_leaderboard')}</b>
                        </span>
                      </Link>
                      <br />
                     
                    </div>
                    <Link
        to={'#'}
        onClick={handleOpenModal}
        className="fw-bold text-decoration-none link-primary hover-underline"
      >
        {t('learnmore')}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-arrow-right-short"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
          />
        </svg>
      </Link>

          </div>
        </div>
      </div>
 
    </div>
  </div>
</section>

{/* create huddle modal start */}
{showModal1 && (
        <div className="modal" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header text-center">
                <h5 className="modal-title text-center"> {t('create_huddle')}  </h5>
                <button type="button" className="btn-close" onClick={handleCloseModal1}></button>
              </div>
              <div className="modal-body">
                <p>
                {t('create_huddle_steps')} 
                </p>
              
                <p>{t('create_huddle_step_1')}</p>
  <p>{t('create_huddle_step_2')}</p>
  <p>{t('create_huddle_step_3')}</p>
  <p>{t('create_huddle_step_4')}</p>
                  <br />
                <p>
                {t('ready_to_play')} 
                </p>
               
              </div>
              <div className="modal-footer">
              </div>
            </div>
          </div>
        </div>
      )}
{/* create huddle modal end */}

{/* Join huddle modal start */}
{showModal2 && (
        <div className="modal" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header text-center">
                <h5 className="modal-title text-center"> {t('join_a_huddle')} </h5>
                <button type="button" className="btn-close" onClick={handleCloseModal2}></button>
              </div>
              <div className="modal-body">
                <p>
               {t('join_huddle_steps')} 
                </p>
              
                  <p>{t('join_step_1')}</p>
                  <p>{t('join_step_2')}</p>
                  <p> {t('join_step_3')}</p>
                  <br />
                <p>
                {t('search_filter_description')}
                </p>
               
              </div>
              <div className="modal-footer">
              </div>
            </div>
          </div>
        </div>
      )}
{/* Join huddle modal end */}

{/* Leaderboard modal start */}
{showModal && (
        <div className="modal" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header text-center">
                <h5 className="modal-title text-center">{t('scoring_workFAQ')}  </h5>
                <button type="button" className="btn-close" onClick={handleCloseModal}></button>
              </div>
              <div className="modal-body">
                <p>
                {t('compete_with_users')}  
                </p>
                <p>
                {t('prediction_accuracy')} 
                </p>
                  <p>{t('three_pointsFAQ')}</p>
                  <p>{t('one_pointFAQ')}</p>
                  <p> {t('zero_pointsFAQ')}</p>
                  <br />
                <p>
                {t('prediction_check')} 
                </p>
                <p>
                {t('points_display')}
                </p>
              </div>
              <div className="modal-footer">
              </div>
            </div>
          </div>
        </div>
      )}
{/* Leaderboard modal end */}
                </div>
            <Footer className='' style={{ color: 'white' }} />
            </div>
        </div>
    );
}

export default Dashboard;
