import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import Sidebar from './Sidebar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getDatabase, ref, get, update, remove } from 'firebase/database';
import { CiFilter } from "react-icons/ci";

function Users() {
  const navigate = useNavigate();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [users, setUsers] = useState([]);
  const [displayedUsers, setDisplayedUsers] = useState([]);
  const [showAllUsers, setShowAllUsers] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [sortOrder, setSortOrder] = useState('oldest');

  const navigateToUserDetails = (userId) => {
    navigate(`/user-details/${userId}`); // Replace with the desired path
  };
  
  useEffect(() => {
    if (searchQuery.trim()) {
      const matchingUsers = users.filter(user => {
        const searchLower = searchQuery.toLowerCase();
        return (
          user.username.toLowerCase().includes(searchLower) || 
          user.email.toLowerCase().includes(searchLower)
        );
      });
      setSuggestions(matchingUsers);
    } else {
      setSuggestions([]);
    }
  }, [searchQuery, users]);

  const highlightMatch = (text, query) => {
    const regex = new RegExp(`(${query})`, 'gi');
    return text.replace(regex, (match) => `<span class="text-primary fw-bold">${match}</span>`);
  };

  const sortUsers = (usersArray) => {
    return usersArray.sort((a, b) => {
      if (sortOrder === 'newest') {
        return new Date(b.timestamp) - new Date(a.timestamp); // Newest first
      } else {
        return new Date(a.timestamp) - new Date(b.timestamp); // Oldest first
      }
    });
  };

  useEffect(() => {
    const sortedUsers = sortUsers(users);
    setDisplayedUsers(showAllUsers ? sortedUsers : sortedUsers.slice(0, 10));
  }, [sortOrder, users, showAllUsers]);
  
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        const allowedEmails = ['denis@kingjtech.com', 'denis@atomtech.live', 'support@predictify.app', 'jeremy@fall.com.mt'];
        if (allowedEmails.includes(user.email)) {
          setIsAuthorized(true);
          fetchUsers();
        } else {
          navigate('/');
        }
      } else {
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const fetchUsers = async () => {
    try {
      const db = getDatabase();
      const usersRef = ref(db, 'users');
      const snapshot = await get(usersRef);
      if (snapshot.exists()) {
        const usersData = snapshot.val();
        const usersArray = Object.keys(usersData).map((id) => ({
          id,
          ...usersData[id],
        })).filter((user) => user.username && user.username.trim() !== '');
  
        const sortedUsers = sortUsers(usersArray); // Apply sorting
        setUsers(sortedUsers);
        setDisplayedUsers(sortedUsers.slice(0, 10));
      } else {
        console.log("No data available");
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  
  const loadMoreUsers = () => {
    setShowAllUsers(true);
    if (!searchQuery.trim()) {
      setDisplayedUsers(users);
    }
  };
  
  const toggleRowExpansion = (userId) => {
    setExpandedRows((prevExpandedRows) =>
      prevExpandedRows.includes(userId)
        ? prevExpandedRows.filter(id => id !== userId)
        : [...prevExpandedRows, userId]
    );
  };

  const sanitizeEmail = (email) => email.replace(/\./g, '_');

  if (!isAuthorized) {
    return null;
  } 

  return (
    <div className="d-flex vh-100">
    {/* Sidebar */}
    <Sidebar />

    {/* Main Content */}
    <div className="flex-grow-1 bg-light">
      <div className="container mt-4">
        <h2 className="text-center mb-3">User List</h2>
        <hr />

        {/* Search Input */}
        <div className="position-relative">
          <input
            type="text"
            className="form-control mb-3"
            placeholder="Search by username or email"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />

          {suggestions.length > 0 && (
            <ul className="list-group position-absolute w-100" style={{ zIndex: 1 }}>
              {suggestions.map((user) => (
                <li
                  key={user.id}
                  className="list-group-item list-group-item-action"
                  onClick={() => {
                    setSearchQuery('');
                    setSuggestions([]);
                    navigateToUserDetails(user.id);
                  }}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: highlightMatch(user.username, searchQuery),
                    }}
                  ></div>
                  <small
                    dangerouslySetInnerHTML={{
                      __html: highlightMatch(user.email, searchQuery),
                    }}
                  ></small>
                </li>
              ))}
            </ul>
          )}
        </div>

        {/* Sort Options */}
        <div className="d-flex align-items-center justify-content-end mt-2 mb-2">
          <select
            className="form-select w-auto"
            value={sortOrder}
            onChange={(e) => setSortOrder(e.target.value)}
          >
            <option value="newest">Newest</option>
            <option value="oldest">Oldest</option>
          </select>
        </div>

        {/* User Table */}
        <table className="table table-bordered">
          <thead>
            <tr
              style={{
                cursor: 'pointer',
                backgroundColor: '#f8f9fa',
                textAlign: 'center',
              }}
            >
              <th scope="col" style={{ width: '5%' }} className="text-center">
                #
              </th>
              <th scope="col" style={{ width: '15%' }} className="text-center">
                Username
              </th>
              <th scope="col" style={{ width: '15%' }} className="text-center">
                Email
              </th>
              <th scope="col" style={{ width: '15%' }} className="text-center">
                Profile
              </th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr
                key={user.id}
                style={{
                  backgroundColor: '#ffffff',
                  borderBottom: '1px solid #dee2e6',
                  cursor: 'pointer',
                }}
              >
                <th
                  scope="row"
                  style={{ width: '5%' }}
                  className="text-center align-middle"
                >
                  {index + 1}
                </th>
                <td style={{ width: '15%' }} className="text-center align-middle">
                  {user.username}
                </td>
                <td style={{ width: '15%' }} className="text-center align-middle">
                  {user.email}
                </td>
                <td
                  style={{ width: '15%' }}
                  className="text-center align-middle"
                  onClick={() => navigateToUserDetails(user.id)}
                >
                  <button className="btn btn-primary btn-sm">View Profile</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
  );
}

export default Users;
