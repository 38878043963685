import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import Image123 from './LogoWhite4.png';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t, i18n } = useTranslation();

  const pageSettings = {
    faq: {
      visible: true,
      accessible: true,
    },
  };

  const storedLanguage = localStorage.getItem('language') || 'en';
  const [currentLanguage, setCurrentLanguage] = useState(storedLanguage);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // Change language using i18next
    localStorage.setItem('language', lng); // Save the selected language to localStorage
    setCurrentLanguage(lng); // Update the state
  };

  return (
    <div className='Footer bg-transparent'>
      <footer className="footer">
        <div className="py-3 py-md-5 py-xl-8 border-light-subtle" style={{ backgroundColor: 'transparent' }}>
          <div className="container overflow-hidden">
            <div className="row gy-3 gy-md-5 gy-xl-0 align-items-sm-center">
              <div className="col-xs-12 col-sm-6 col-xl-3 order-0 order-xl-0">
                <div className="footer-logo-wrapper text-center text-sm-start">
                  <Link to='/' className="nav-link link-secondary px-2 px-md-3">
                    <img src={Image123} alt=" Logo" width="175" height="57" />
                  </Link>
                </div>
              </div>

              <div className="col-xs-12 col-xl-6 order-2 order-xl-1">
                <ul className="nav justify-content-center">
                  {pageSettings.faq.visible && (
                    <li>
                      <Link to='/faq' className="nav-link link-light px-2 px-md-3">{t('faq')}</Link>
                    </li>
                  )}
                  <li className="nav-item">
                    <Link to='/contact' className="nav-link link-light px-2 px-md-3">{t('contactFooter')}</Link>
                  </li>
                  <li className="nav-item">
                    <Link to='/terms' className="nav-link link-light px-2 px-md-3">{t('terms')}</Link>
                  </li>
                  <li className="nav-item">
                    <Link to='/privacy' className="nav-link link-light px-2 px-md-3">{t('privacy')}</Link>
                  </li>
                </ul>
              </div>

              <div className="col-xs-12 col-sm-6 col-xl-3 order-1 order-xl-2">
                <ul className="nav justify-content-center justify-content-sm-end">
                  <li className="nav-item">
                    <a className="nav-link link-light px-3" href="https://www.facebook.com/people/Predictify/61560479297637/">
                      {/* Facebook Icon */}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link link-light px-3" href="https://www.instagram.com/TeamPredictify">
                      {/* Instagram Icon */}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer-copyright-wrapper text-center text-light">
              Predictify ver 2.1 built by ATOM Technology Ltd
            </div>
            <div className="credits text-light text-center mt-2 fs-7">
              All feedback is welcome and encouraged – <Link to='/contact' style={{ textDecoration: 'none' }}>Please contact us here</Link>
            </div>
            <div className="language-selector text-center mt-3">
  <div className="dropdown">
    <button className="btn btn-outline-light dropdown-toggle" type="button" id="languageDropdown" data-bs-toggle="dropdown" aria-expanded="false">
      {currentLanguage === 'en' ? 'English' : currentLanguage === 'vi' ? 'Tiếng Việt' : 'Deutsch'}
    </button>
    <ul className="dropdown-menu" aria-labelledby="languageDropdown">
      <li>
        <button onClick={() => changeLanguage('en')} className="dropdown-item">English</button>
      </li>
      <li>
        <button onClick={() => changeLanguage('vi')} className="dropdown-item">Tiếng Việt</button>
      </li>
      <li>
        <button onClick={() => changeLanguage('de')} className="dropdown-item">Deutsch</button>
      </li>
    </ul>
  </div>
</div>

          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
