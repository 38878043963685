import Barcelona from '../Team-Kits-Images/Barcelona.png'
import Milan from '../Team-Kits-Images/Milan.png'
import Arsenal from '../Team-Kits-Images/Arsenal.png'
import Monaco from '../Team-Kits-Images/Monaco.png'
import AstonVilla from '../Team-Kits-Images/AstonVilla.png'
import Atalanta from '../Team-Kits-Images/Atalanta.png'
import Athleti from '../Team-Kits-Images/Athleti.png'
import Leverkusen from '../Team-Kits-Images/Leverkusen.png'
import BayernMunich from '../Team-Kits-Images/BayernMunich.png'
import Benfica from '../Team-Kits-Images/Benfica.png'
import Bologna from '../Team-Kits-Images/Bologna.png'
import Dortmund from '../Team-Kits-Images/Dortmund.png'
import Brest from '../Team-Kits-Images/Brest.png'
import Celtic from '../Team-Kits-Images/Celtic.png'
import Brugge from '../Team-Kits-Images/Brugge.png'
import Zagreb from '../Team-Kits-Images/Zagreb.png'
import Feyenoord from '../Team-Kits-Images/Feyenoord.png'
import Girona from '../Team-Kits-Images/Girona.png'
import Inter from '../Team-Kits-Images/Inter.png'
import Juventus from '../Team-Kits-Images/Juventus.png'
import Lille from '../Team-Kits-Images/Lille.png'
import Liverpool from '../Team-Kits-Images/Liverpool.png'
import ManCity from '../Team-Kits-Images/Man.City.png'
import PSG from '../Team-Kits-Images/PSG.png'
import PSV from '../Team-Kits-Images/PSV.png'
import Leipzig from '../Team-Kits-Images/Leipzig.png'
import Madrid from '../Team-Kits-Images/Madrid.png'
import Belgrade from '../Team-Kits-Images/Belgrade.png'
import Salzburg from '../Team-Kits-Images/Salzburg.png'
import Shakhtar from '../Team-Kits-Images/Shakhtar.png'
import Bratislava from '../Team-Kits-Images/Bratislava.png'
import Praha from '../Team-Kits-Images/Prague.png'
import Sporting from '../Team-Kits-Images/Sporting.png'
import Graz from '../Team-Kits-Images/Graz.png'
import Stuttgart from '../Team-Kits-Images/Stuttgart.png'
import YoungBoys from '../Team-Kits-Images/YoungBoys.png'

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useRef,useMemo } from 'react';
import { get, ref, getDatabase, set } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import Navbar from '../Navbar';
import Footer from '../Footer';
 import { Link } from 'react-router-dom'

 function UCLFixtures() {
  const [fixtures, setFixtures] = useState([]);
  const [inputsData, setInputsData] = useState({});
  const auth = getAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const user = auth.currentUser;
  const userId = user ? user.uid : null;
  const intervalRefs = useRef({});
  const [filteredFixtures, setFilteredFixtures] = useState([]);
  const [confetti, setConfetti] = useState(false);
  const [totalPoints, setTotalPoints] = useState(0);
  const { t } = useTranslation();
  const [fixturePoints, setFixturePoints] = useState({});
  const [displayName, setDisplayName] = useState('');
  const [selectedMatchday, setSelectedMatchday] = useState('Round');
  const [startIndex, setStartIndex] = useState(0);
  const containerRef = useRef(null);
  const [buttonWidth, setButtonWidth] = useState(0);
  const [currentRound, setCurrentRound] = useState(8); 
  const [buttonsToShow, setButtonsToShow] = useState(0);
  const dropdownMenuRef = useRef(null);
  const dropdownButtonRef = useRef(null);

  const [dateRanges] = useState([
    { start: '2024-09-15', end: '2024-09-20' },  // Matchweek 1
    { start: '2024-09-20', end: '2024-10-03' },  // Matchweek 2
    { start: '2024-10-03', end: '2024-10-24' },  // Matchweek 3
    { start: '2024-10-24', end: '2024-11-07' },  // Matchweek 4
    { start: '2024-11-07', end: '2024-11-28' },  // Matchweek 5
    { start: '2024-11-28', end: '2024-12-12' },  // Matchweek 6
    { start: '2024-12-12', end: '2025-01-23' },  // Matchweek 7
    { start: '2025-01-23', end: '2025-01-30' }   // Matchweek 8
]);

useEffect(() => {
  const currentDate = new Date();
  let currentMatchday = 1;
  for (let i = 0; i < dateRanges.length; i++) {
    const startDate = new Date(dateRanges[i].start);
    const endDate = new Date(dateRanges[i].end);
    if (currentDate >= startDate && currentDate <= endDate) {
      currentMatchday = i + 1;
      break;
    }
  }
  setCurrentPage(currentMatchday);
  setSelectedMatchday(`Round ${currentMatchday}`);
}, [dateRanges]);

  useEffect(() => {
    const filteredFixtures = fixtures.filter(fixture => {
      const fixtureDate = new Date(fixture.fixture.date);
      const startDate = new Date(dateRanges[currentPage - 1].start);
      const endDate = new Date(dateRanges[currentPage - 1].end);
      return fixtureDate >= startDate && fixtureDate <= endDate;
    });

    const sortedFixtures = filteredFixtures.sort((a, b) => new Date(a.fixture.date) - new Date(b.fixture.date));

    setFilteredFixtures(sortedFixtures);
  }, [currentPage, fixtures, dateRanges]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setSelectedMatchday(`Round ${page}`);
  };
  
  useEffect(() => {
    const fetchFixtures = async () => {
      try {
        const fixturesResponse = await fetch("https://iga-sport.azurewebsites.net/api/league/2/fixtures/season/2024", {
          headers: {
            'x-functions-key': 'HELbBhdzKZIXUX8-9T6Sm7ZuCnRdQx-eo-r17IQsam17AzFuCBzqZg=='
          }
        });
        const fixturesData = await fixturesResponse.json();
        setFixtures(fixturesData);
      } catch (error) {
        console.error("Error fetching fixtures:", error);
      }
    };

    fetchFixtures();
  }, []);

  const handleMatchdaySelect = (index) => {
    const Round = `Round ${index + 1}`;
    setSelectedMatchday(Round);
    handlePageChange(index + 1);
  };
  
  useEffect(() => {
    if (confetti) {
      const timeout = setTimeout(() => {
        setConfetti(false);
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, [confetti]);
 
  const convertToFormattedDateTime = (dateString) => {
    const options = { day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', hour12: false };
    const date = new Date(dateString);
    return date.toLocaleString('en-US', options);
  };
  const teamLogos = {
    'Bayern München': BayernMunich,
    'Aston Villa': AstonVilla,
    'Liverpool': Liverpool,
    'Juventus': Juventus,
    'Atletico Madrid': Athleti,
    'Real Madrid': Madrid,
    'Sporting CP': Sporting,
    'Arsenal': Arsenal,
    'Atalanta': Atalanta,
    'Barcelona': Barcelona,
    'Bayer Leverkusen': Leverkusen,
    'Benfica': Benfica,
    'Bologna': Bologna,
    'Borussia Dortmund': Dortmund,
    'Stade Brestois 29': Brest,
    'Celtic': Celtic,
    'Club Brugge KV': Brugge,
    'Feyenoord': Feyenoord,
    'Girona': Girona,
    'Inter': Inter,
    'Manchester City': ManCity,
    'Monaco': Monaco,
    'Paris Saint Germain': PSG,
    'RB Leipzig': Leipzig,
    'FK Crvena Zvezda': Belgrade,
    'Red Bull Salzburg': Salzburg,
    'Shakhtar Donetsk': Shakhtar,
    'Slovan Bratislava': Bratislava,
    'Sparta Praha': Praha,
    'Sturm Graz': Graz,
    'PSV Eindhoven': PSV,
    'VfB Stuttgart': Stuttgart,
    'AC Milan': Milan,
    'Lille': Lille,
    'BSC Young Boys': YoungBoys,
    'Dinamo Zagreb': Zagreb,



   
  };
  
  const teamInitials = {
    'Bayern München': 'BAY',
    'Aston Villa': 'AVFC',
    'Liverpool': 'LFC',
    'Juventus': 'JUV',
    'Atletico Madrid': 'ATM',
    'Real Madrid': 'RM',
    'Sporting CP': 'SCP',
    'Arsenal': 'AFC',
    'Atalanta': 'ATL',
    'Barcelona': 'BAR',
    'Bayer Leverkusen': 'B04',
    'Benfica': 'SLB',
    'Bologna': 'BFC',
    'Borussia Dortmund': 'BVB',
    'Stade Brestois 29': 'SB29',
    'Celtic': 'CEL',
    'Club Brugge KV': 'BRU',
    'Feyenoord': 'FEY',
    'Girona': 'GIR',
    'Inter': 'INT',
    'Manchester City': 'MCFC',
    'Monaco': 'ASM',
    'Paris Saint Germain': 'PSG',
    'RB Leipzig': 'RBL',
    'FK Crvena Zvezda': 'CRV',
    'Red Bull Salzburg': 'RBS',
    'Shakhtar Donetsk': 'FCD',
    'Slovan Bratislava': 'SLB',
    'Sparta Praha': 'SPA',
    'Sturm Graz': 'STU',
    'PSV Eindhoven': 'PSV',
    'VfB Stuttgart': 'VFB',
    'AC Milan': 'ACM',
    'Lille': 'LOSC',
    'BSC Young Boys': 'YB',
    'Dinamo Zagreb': 'DIN',


   
  };
  const renderTeamLogo = (teamName) => {
    const logo = teamLogos[teamName];
    if (logo) {
      return <img src={logo} alt={teamName} style={{ width: '75px', height: 'auto' }} />;
    }
    return <span style={{ fontStyle: 'normal' }}>{teamName}</span>;
  };
  
  const renderTeamInitials = (teamName) => {
    return teamInitials[teamName] || teamName;
  };

  const stadiums = {
    'Bayern München': `${t('stadium')}: Allianz Arena`,
    'Aston Villa': `${t('stadium')}: Villa Park`,
    'Liverpool': `${t('stadium')}: Anfield`,
    'Juventus': `${t('stadium')}: Allianz Stadium`,
    'Atletico Madrid': `${t('stadium')}: Estadio Metropolitano`,
    'Real Madrid': `${t('stadium')}: Santiago Bernabéu`,
    'Sporting CP': `${t('stadium')}: Estádio José Alvalade`,
    'Arsenal': `${t('stadium')}: Emirates Stadium`,
    'Atalanta': `${t('stadium')}: Gewiss Stadium`,
    'Barcelona': `${t('stadium')}: Camp Nou`,
    'Bayer Leverkusen': `${t('stadium')}: BayArena`,
    'Benfica': `${t('stadium')}: Estádio da Luz`,
    'Bologna': `${t('stadium')}: Stadio Renato Dall\'Ara`,
    'Borussia Dortmund': `${t('stadium')}: Signal Iduna Park`,
    'Stade Brestois 29': `${t('stadium')}: Stade Francis-Le Blé`,
    'Celtic': `${t('stadium')}: Celtic Park`,
    'Club Brugge KV': `${t('stadium')}: Jan Breydel Stadium`,
    'Feyenoord': `${t('stadium')}: De Kuip`,
    'Girona': `${t('stadium')}: Estadi Montilivi`,
    'Inter': `${t('stadium')}: San Siro`,
    'Manchester City': `${t('stadium')}: Etihad Stadium`,
    'Monaco': `${t('stadium')}: Stade Louis II`,
    'Paris Saint Germain': `${t('stadium')}: Parc des Princes`,
    'RB Leipzig': `${t('stadium')}: Red Bull Arena`,
    'FK Crvena Zvezda': `${t('stadium')}: Rajko Mitić Stadium`,
    'Red Bull Salzburg': `${t('stadium')}: Red Bull Arena`,
    'Shakhtar Donetsk': `${t('stadium')}: Oblasny SportKomplex Metalist`,
    'Slovan Bratislava': `${t('stadium')}: Tehelné pole`,
    'Sparta Praha': `${t('stadium')}: Generali Česká pojišťovna Arena`,
    'Sturm Graz': `${t('stadium')}: Merkur Arena`,
    'PSV Eindhoven': `${t('stadium')}: Philips Stadion`,
    'VfB Stuttgart': `${t('stadium')}: Mercedes-Benz Arena`,
    'AC Milan': `${t('stadium')}: San Siro`,
    'Lille': `${t('stadium')}: Stade Pierre-Mauroy`,
    'BSC Young Boys': `${t('stadium')}: Stadion Wankdorf`,
    'Dinamo Zagreb': `${t('stadium')}: Stadion Maksimir`,
  };
  
  


  const [selectedMatchdayIndex, setSelectedMatchdayIndex] = useState(0);

  const roundsContainerRef = useRef(null);

 // Handle Previous Button Click
  // Handle Direct Matchday Selection
  const handleMatchdaySelectNew = (index) => {
    setSelectedMatchdayIndex(index);
  };
  
  useEffect(() => {
    const calculateButtonsToShow = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.clientWidth;
        const calculatedButtonsToShow = Math.floor(containerWidth / 125); // Adjust this value based on your design
        setButtonsToShow(Math.min(8, calculatedButtonsToShow));
        calculateStartIndex(currentPage, Math.min(8, calculatedButtonsToShow));
      }
    };

    calculateButtonsToShow();

    // Recalculate on window resize
    window.addEventListener('resize', calculateButtonsToShow);
    return () => {
      window.removeEventListener('resize', calculateButtonsToShow);
    };
  }, [currentPage]);

  const calculateStartIndex = (currentPage, buttonsToShow) => {
    let minIndex = Math.max(0, currentPage - Math.floor(buttonsToShow / 2));
    minIndex = Math.min(minIndex, dateRanges.length - buttonsToShow);
    setStartIndex(minIndex);
  };

  const handleMatchdaySelectSmooth = (index) => {
    setSelectedMatchdayIndex(index);
    handleMatchdaySelect(index);
  };

  const handleLeftArrowClick = () => {
    if (roundsContainerRef.current && startIndex > 0) {
      roundsContainerRef.current.scrollBy({
        left: -((100 / buttonsToShow) + 10), // Move left by the button width + margin
        behavior: 'smooth',
      });
      setStartIndex(startIndex - 1);
    }
  };

  const handleRightArrowClick = () => {
    if (roundsContainerRef.current && startIndex + buttonsToShow < dateRanges.length) {
      roundsContainerRef.current.scrollBy({
        left: (100 / buttonsToShow) + 10, // Move right by the button width + margin
        behavior: 'smooth',
      });
      setStartIndex(startIndex + 1);
    }
  };

  useEffect(() => {
    const currentDate = new Date();
    let currentMatchday = 1;
    for (let i = 0; i < dateRanges.length; i++) {
      const startDate = new Date(dateRanges[i].start);
      const endDate = new Date(dateRanges[i].end);
      if (currentDate >= startDate && currentDate <= endDate) {
        currentMatchday = i + 1;
        break;
      }
    }
    setCurrentPage(currentMatchday);
    setSelectedMatchday(`Round ${currentMatchday}`);
  }, [dateRanges]);

  // Add new effect to handle dropdown button click
  useEffect(() => {
    const handleDropdownOpen = () => {
      if (dropdownMenuRef.current) {
        // Wait for the dropdown to be visible
        setTimeout(() => {
          const currentRoundIndex = parseInt(selectedMatchday.split(' ')[1]) - 1;
          const listItems = dropdownMenuRef.current.querySelectorAll('.list-group-item');
          if (listItems[currentRoundIndex]) {
            listItems[currentRoundIndex].scrollIntoView({
              block: 'center',
              behavior: 'auto'
            });
          }
        }, 0);
      }
    };

    const dropdownButton = dropdownButtonRef.current;
    if (dropdownButton) {
      dropdownButton.addEventListener('click', handleDropdownOpen);
      return () => dropdownButton.removeEventListener('click', handleDropdownOpen);
    }
  }, [selectedMatchday]);

  return (
    <div>
    <div className='App-headerV8 '>
    <Navbar />
      <h2 className='text-light text-center padtop'style={{ fontFamily: 'Lato, sans-serif' }}>UCL {t('fixtures')}
      </h2>
      <div className="text-light text-center" style={{ paddingTop: '10px', paddingBottom: '10px', gap: '5px' }}>
  {/* Mobile Dropdown */}
  <div className="dropdown d-inline-block d-md-none">
            <button
              className="btn btn-purple dropdown-toggle"
              type="button"
              id="groupStageDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="true"
              ref={dropdownButtonRef}
            >
              {selectedMatchday}
            </button>
            <div
              className="dropdown-menu matchweek-dropdown"
              aria-labelledby="groupStageDropdown"
              ref={dropdownMenuRef}
              style={{ maxHeight: '300px', overflowY: 'auto' }}
            >
              <ul className="list-group">
                {dateRanges.map((range, index) => (
                  <li key={index} className="list-group-item">
                    <button
                      className={`dropdown-item ${selectedMatchday === `Round ${index + 1}` ? 'btn-purple text-light' : ''}`}
                      onClick={() => handleMatchdaySelect(index)}
                      style={{ width: '100%' }}
                    >
                      {t('round')} {index + 1}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>

  {/* Horizontal Scroll for Large Screens */}
  <div className="container">
    <div className="d-none d-md-flex align-items-center justify-content-center" style={{ width: '100%', marginLeft: '0rem' }} ref={containerRef}>
      <button className="btn btn-outline-light" onClick={handleLeftArrowClick} disabled={startIndex === 0} style={{ width: '5%', marginRight: '1rem' }}>
        &lt;
      </button>

      <div
        className="rounds-container"
        ref={roundsContainerRef}
        style={{
          display: 'flex',
          overflowX: 'auto',
          whiteSpace: 'nowrap',
          width: '70%', // Set to allow space for buttons
          marginLeft: '1rem',
          scrollBehavior: 'smooth',
        }}
      >
        {dateRanges.slice(startIndex, startIndex + buttonsToShow).map((range, index) => (
          <button
            key={startIndex + index}
            className={`btn me-2 ${selectedMatchday === `Round ${startIndex + index + 1}` ? 'btn-purple' : 'btn-outline-light'}`}
            style={{ width: `calc(${(100 / buttonsToShow)}% - 9px)` }}
            onClick={() => handleMatchdaySelect(startIndex + index)}
          >
            {t('round')} {startIndex + index + 1}
          </button>
        ))}
      </div>

      <button className="btn btn-outline-light" onClick={handleRightArrowClick} disabled={startIndex + buttonsToShow >= dateRanges.length} style={{ width: '5%', marginLeft: '1rem' }}>
        &gt;
      </button>
    </div>
  </div>
</div>

      <div className=' '>
        <div className='row text-center justify-content-center mx-auto bg-transparent text-light'>        
          <div className=' col-lg-6  text-center justify-content-center bg-transparent text-light'>
            {filteredFixtures.map(fixture => {
              // Get the predicted scores from user input
              const predictedHomeGoals = inputsData[fixture.fixture.id]?.home;
              const predictedAwayGoals = inputsData[fixture.fixture.id]?.away;
              const actualHomeGoals = fixture.goals.home;
              const actualAwayGoals = fixture.goals.away;
              let points = 0;
              // Check if the user made predictions and the match is finished
              if (predictedHomeGoals !== undefined && predictedAwayGoals !== undefined && fixture.fixture.status.short === 'FT') {
                if (predictedHomeGoals === actualHomeGoals && predictedAwayGoals === actualAwayGoals) {
                  points = 3; // Exact score
                } else if (
                  (predictedHomeGoals > predictedAwayGoals && actualHomeGoals > actualAwayGoals) ||
                  (predictedHomeGoals < predictedAwayGoals && actualHomeGoals < actualAwayGoals) ||
                  (predictedHomeGoals === predictedAwayGoals && actualHomeGoals === actualAwayGoals)
                ) {
                  points = 1; // Correct outcome but wrong score
                }
              }
              // Store points in the inputsData state
              if (!inputsData[fixture.fixture.id]) {
                inputsData[fixture.fixture.id] = {};
              }
              inputsData[fixture.fixture.id].points = points;
  
              return (
                <div key={fixture.fixture.id} className='my-3 card border-1 bg-transparent text-light border-light'>
                <div className='card-body text-center mb-1'>
                  <h5 className='card-title mb-3'>{fixture.teams.home.name} vs {fixture.teams.away.name}</h5>
                  
                  {/* Conditional rendering for the stadium name */}
                  {stadiums[fixture.teams.home.name] && (
      <h6 className='card-title mb-3'>{stadiums[fixture.teams.home.name]}</h6>
    )}
              
                  <div className="row align-items-center">
                    <div className="col text-center d-flex flex-column align-items-center">
                      {renderTeamLogo(fixture.teams.home.name)}
                      <h5>{renderTeamInitials(fixture.teams.home.name)}</h5>
                    </div>
                                  {/* Input */}
                    <>
<div className="col padtop1 ">
<input
  className="form-control text-center fs-3 text-light"
  style={{ backgroundColor: 'transparent', borderColor: '#9370DB	', borderWidth: '2px' }}
  value={fixture.fixture.status.short === 'FT' ? fixture.score.fulltime.home : 'H'}
  disabled
/>
</div>
<div className="col padtop1">
<input
  className="form-control text-center fs-3 text-light"
  style={{ backgroundColor: 'transparent', borderColor: '#9370DB	', borderWidth: '2px' }}
  value={fixture.fixture.status.short === 'FT' ? fixture.score.fulltime.away : 'A'}
  disabled
/>
</div>
</>
              {/* Input */}
                    <div className="col text-center d-flex flex-column align-items-center">
                      {renderTeamLogo(fixture.teams.away.name)}
                      <h5>{renderTeamInitials(fixture.teams.away.name)}</h5>
                    </div>
                  </div>
                  {fixture.fixture.status.short !== 'NS' && fixture.fixture.status.short !== 'FT' && (
  <div className="text-success">
    Live - {fixture.fixture.status.elapsed}'
  </div>
)}
                </div>
                <div className='text-dark col-6 col-lg-3 mx-auto text-center text-light' 
 style={{ 
   backgroundColor: '#9370DB', 
   borderRadius: '5px', 
   borderBottomLeftRadius: '0px',  // Set bottom-left corner radius to 0
   borderBottomRightRadius: '0px'  // Set bottom-right corner radius to 0
 }}
>
<p className="text-center mb-2 btn-sm text-bold" 
 style={{ 
   color: '#fff', 
   paddingTop: '4px',  
   borderTopLeftRadius: '1px' 
 }}
>
{t('kick_off')} {convertToFormattedDateTime(fixture.fixture.date)}
</p>
</div>
                {fixture.fixture.status.short === 'NS' && (
<Link
    to="/uclpick"
    className="btn btn-success"
    style={{
        position: 'absolute',
        bottom: '0',           // Flush with the bottom edge
        right: '0',            // Flush with the right edge
        padding: '8px 12px',  // Adjust padding for button size
        backgroundColor: 'green',
        color: 'white',
        textDecoration: 'none',
        fontSize: '0.9rem',    // Adjust the font size as needed
        fontWeight: 'bold',
        borderRadius: '0',     // Ensure no border-radius on other corners
        borderTopLeftRadius: '10px',  // 10px border radius only on top-left corner
        borderBottomRightRadius: '5px',  // 10px border radius only on top-left corner
        margin: '0',           // Ensure no extra margins
        border: '3px',        // No borders
        width: 'auto',         // Adjust width as needed
        height: 'auto'         // Adjust height as needed
    }}
>
{t('predict')}

</Link>
)}
              </div>
              );
            })}
          </div>         
        </div>
      </div>

    <Footer />
    </div>
  </div>
  );
}

export default UCLFixtures;
