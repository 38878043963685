import React from 'react';

const ModernLoginButton = ({ loading, t }) => {
  return (
    <button
      className="relative overflow-hidden rounded-lg text-white font-semibold py-3 px-6 w-full 
        bg-gradient-to-r from-purple-600 via-pink-500 to-red-500 
        shadow-lg transition-all duration-300 ease-in-out
        disabled:opacity-50 disabled:cursor-not-allowed
        hover:from-purple-700 hover:via-pink-600 hover:to-red-600
        active:scale-95 hover:scale-105"
      type="submit"
      disabled={loading}
    >
      {loading ? (
        <div className="flex items-center justify-center">
          <svg 
            className="animate-spin h-5 w-5 mr-3" 
            viewBox="0 0 24 24"
          >
            <circle 
              className="opacity-25" 
              cx="12" 
              cy="12" 
              r="10" 
              stroke="currentColor" 
              strokeWidth="4"
            ></circle>
            <path 
              className="opacity-75" 
              fill="currentColor" 
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          Logging in...
        </div>
      ) : (
<button>Login</button>
    )}
    </button>
  );
};

export default ModernLoginButton;